import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { saveAs } from 'file-saver';
import { keyBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

import { trackEvent } from 'jf/analytics/Analytics';
import { NumericScore, Study, StudyClient, StudyScoreClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { CSVTable } from 'jf/components/CSVTable';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExModal } from 'jf/components/DevExModal';
import { DevExSegmented } from 'jf/components/DevExSegmented';
import { JSONBlock } from 'jf/components/JSONBlock';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useExportableStudyScores } from './useExportableStudyScores';
import { useExportableStudyVerbatims } from './useExportableStudyVerbatims';

enum FileType {
  CSV = 'CSV',
  JSON = 'JSON',
}

enum DataType {
  SCORES = 'SCORES',
  VERBATIMS = 'VERBATIMS',
}

const DATA_TYPE_OPTIONS = [
  { value: DataType.SCORES, label: 'Scores' },
  { value: DataType.VERBATIMS, label: 'Comments' },
];

const SCORE_TYPE_OPTIONS = [
  { value: NumericScore.type.TOPIC, label: 'Topic' },
  { value: NumericScore.type.PROMPT, label: 'Prompt' },
];

const SEGMENTED_OPTIONS = [
  { value: 'overall', label: 'Overall' },
  { value: 'segmented', label: 'By Segment' },
];

export const ExportStudyModal: React.FC = () => {
  const modal = useJFOverlay(ExportStudyModal);
  const { studyRef: studyRefParam, teamRef } = useParams<{ studyRef: string; teamRef: string }>();

  const [dataType, setDataType] = useState<DataType>(DataType.SCORES);
  const [scoreType, setScoreType] = useState<NumericScore.type>(NumericScore.type.TOPIC);
  const [segmented, setSegmented] = useState(false);

  const [fileType, setFileType] = useState<FileType>(FileType.CSV);

  const theme = useDevExTheme();

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: studyRefParam });
  const { data: scoredTeams } = useClientQuery(StudyScoreClient.getStudyScoredTeams, {
    studyRef: studyRefParam,
  });

  // avoid fetching score data for active study
  const studyRef = study?.status === Study.status.CLOSED ? study.ref : undefined;

  const exportableScores = useExportableStudyScores({ studyRef, teamRef, scoreType, segmented });
  const exportableVerbatims = useExportableStudyVerbatims({ studyRef, teamRef });

  const { csv, json } = dataType === DataType.SCORES ? exportableScores : exportableVerbatims;

  const onCancel = () => modal.close();

  // compile Segment event data
  let eventData: Record<string, any> = {
    surveyRef: studyRef,
    fileType,
    dataType: dataType,
  };
  if (dataType === DataType.SCORES) {
    eventData = { ...eventData, type: scoreType, segmented };
  }

  const onOk = () => {
    // compose file name
    const teamByRef = keyBy(scoredTeams, 'ref');
    const fileNameParts = [
      study!.name,
      teamRef ? `Team ${teamByRef[teamRef]?.value}` : 'Company',
      dataType === DataType.SCORES ? `${segmented ? 'Segmented ' : ''}Scores` : 'Comments',
      DateTime.now().toLocaleString(DateTime.DATE_SHORT).replaceAll('/', '-'),
    ];
    const fileName = fileNameParts.join(' ');

    trackEvent('survey-analyzer:export:download', eventData);

    if (fileType === FileType.CSV && csv) {
      // compile data into a workbook
      const book = XLSX.utils.book_new();
      const sheet = XLSX.utils.aoa_to_sheet(csv);
      XLSX.utils.book_append_sheet(book, sheet);

      // write workbook to a blob
      const buffer = XLSX.write(book, { bookType: 'csv', type: 'buffer' });
      const blob = new Blob([buffer], {
        type: 'text/csv;charset=utf-8',
      });

      // save blob as file
      saveAs(blob, fileName);
    } else if (fileType === FileType.JSON && json) {
      // write json to a blob
      const blob = new Blob([JSON.stringify(json, null, 4)], {
        type: 'application/json',
      });

      // save blob as file
      saveAs(blob, fileName);
    }
  };

  // reset state on close
  useEffect(() => {
    setScoreType(NumericScore.type.TOPIC);
    setSegmented(false);
    setFileType(FileType.CSV);
  }, [modal.isOpened]);

  let copyValue = '';
  if (fileType === FileType.CSV && csv) {
    copyValue = csv.map((row) => row.join('\t')).join('\n');
  } else if (fileType === FileType.JSON && json) {
    copyValue = JSON.stringify(json, null, 4);
  }

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={onCancel}
      onOk={onOk}
      title="Export Survey Data"
      footer={
        <>
          <DevExButton
            copyValue={copyValue}
            onClick={() => trackEvent('survey-analyzer:export:copy-to-clipboard', eventData)}
          >
            Copy to clipboard
          </DevExButton>
          <DevExButton type="primary" onClick={onOk}>
            Download {fileType}
          </DevExButton>
        </>
      }
      width={750}
    >
      <Flex vertical gap={theme.variable.spacing.md}>
        <Flex justify="space-between">
          <Flex gap={theme.variable.spacing.md}>
            <DevExSegmented<DataType>
              options={DATA_TYPE_OPTIONS}
              value={dataType}
              onChange={setDataType}
            />
            {dataType === DataType.SCORES && (
              <>
                <DevExSegmented<NumericScore.type>
                  options={SCORE_TYPE_OPTIONS}
                  value={scoreType}
                  onChange={setScoreType}
                />
                <DevExSegmented
                  options={SEGMENTED_OPTIONS}
                  value={segmented ? 'segmented' : 'overall'}
                  onChange={(value) => setSegmented(value === 'segmented')}
                />
              </>
            )}
          </Flex>
          <DevExSegmented
            options={Object.values(FileType).map((value) => ({ value, label: value }))}
            value={fileType}
            onChange={(value: FileType) => setFileType(value)}
          />
        </Flex>

        {fileType === FileType.CSV ? (
          <CSVTable data={csv?.slice(0, 6) ?? []} />
        ) : (
          <JSONBlock object={json} truncate={1} />
        )}
      </Flex>
    </DevExModal>
  );
};
