import React, { ReactNode, useState } from 'react';
import { Flex, Tooltip } from 'antd';
import { faLightbulbOn, faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { keyBy } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { Prompt, RosterClient, StudyClient, StudyLibraryClient } from 'jf/api';
import { DevExPageTitle } from 'jf/common/DevExPageTitle';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExTextArea } from 'jf/components/DevExTextArea';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import { QuestionCardIntro } from '../study/question/QuestionCardIntro';
import { QuestionCardPrompt } from '../study/question/QuestionCardPrompt';
import { QuestionCardVerbatims, ScoredPrompt } from '../study/question/QuestionCardVerbatims';

import { EditStudyHeader } from './EditStudyHeader';
import { EditStudySidebar } from './EditStudySidebar';
import { useEditStudyState, useStudyEditorSteps } from './EditStudyStateContext';
import { QuestionCardEditableDemographic } from './QuestionCardEditableDemographic';
import { QuestionCardEditablePrompt } from './QuestionCardEditablePrompt';
import { TopicHygiene } from './TopicHygiene';

export const DEFAULT_WELCOME_MESSAGE =
  'This survey will ask about your feelings towards the developer experience at your company.';
export const DEFAULT_TEAM_DEMOGRAPHIC_TEXT = 'What is your primary team?';

const UNEDITIBALE_CANONICAL_QUESTION_TOOLTIP =
  'This is a template question that cannot be edited. If there is not a template question that fits your needs, a custom question can be created.';
const UNEDITIBALE_CUSTOMER_QUESTION_TOOLTIP =
  'This is a custom question that has been used in a prior survey and can no longer be edited. This ensures custom question scores can be compared across surveys.';

export const EditStudyPage: React.FC = () => {
  const { bakunawaEmpTeamConfigurationQ324: empCompanyCanEditTeams } = useFlags();
  const { studyRef } = useParams<{ studyRef: string }>();
  const theme = useDevExTheme();
  const editStudyState = useEditStudyState();
  const queryClient = useQueryClient();

  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);
  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });
  const { data: teams } = useClientQuery(RosterClient.getTeams);

  const [savingTeams, setSavingTeams] = useState(false);

  const { mutateAsync: deleteTeam } = useClientMutation(RosterClient.deleteTeam);
  const { mutateAsync: createTeam } = useClientMutation(RosterClient.createTeam);
  const { mutateAsync: updateTeam } = useClientMutation(RosterClient.updateTeam);

  let promptsBySlug: Record<string, Prompt> = {};
  const topicsBySlug = keyBy(topics, 'slug');

  if (prompts) {
    promptsBySlug = keyBy(prompts, 'slug');
  }

  const stepIndex = editStudyState.stepIndex;

  const unsavedCustomerPrompt = editStudyState.unsavedCustomerPrompt;

  const onSaveIntroMessage = () => {
    if (editStudyState.unsavedWelcomeMessage !== null) {
      editStudyState.update({
        welcomeMessage: editStudyState.unsavedWelcomeMessage,
        unsavedWelcomeMessage: null,
      });
    }
  };

  const onRemovePrompt = (prompt: Prompt) => {
    editStudyState.update({
      promptSlugs: [...editStudyState.promptSlugs.filter((slug) => slug !== prompt.slug)],
      stepIndex: editStudyState.stepIndex - 1,
    });
  };

  const onEditPrompt = (prompt: Prompt) => {
    editStudyState.update({ unsavedCustomerPrompt: prompt });
  };

  const studySteps = useStudyEditorSteps();
  const numberedStepCount = studySteps.filter((step) => step.numbered).length;

  const questionCards: ReactNode[] = [];

  // create QuestionCard elements based on studySteps
  if (study && editStudyState.loaded) {
    for (let i = 0; i < studySteps.length; i++) {
      const step = studySteps[i];

      switch (step.type) {
        case 'INTRO':
          if (editStudyState.unsavedWelcomeMessage !== null) {
            // editing INTRO
            questionCards.push(
              <QuestionCardIntro
                title={<strong>{editStudyState.name}</strong>}
                message={
                  <DevExTextArea
                    value={editStudyState.unsavedWelcomeMessage}
                    placeholder={DEFAULT_WELCOME_MESSAGE}
                    onChange={(value) => editStudyState.update({ unsavedWelcomeMessage: value })}
                    style={{ fontSize: theme.variable.fontSize.lg }}
                  />
                }
                questionCount={numberedStepCount}
                actions={
                  <>
                    <DevExButton
                      onClick={() => editStudyState.update({ unsavedWelcomeMessage: null })}
                    >
                      Cancel
                    </DevExButton>
                    <DevExButton
                      type="primary"
                      disabled={!editStudyState.unsavedWelcomeMessage}
                      onClick={onSaveIntroMessage}
                    >
                      Save
                    </DevExButton>
                  </>
                }
              />
            );
          } else {
            // not editing INTRO
            questionCards.push(
              <Flex vertical align="end" gap={theme.variable.spacing.md}>
                <DevExButton
                  type="outline"
                  icon={<FontAwesomeIcon icon={faPen} />}
                  onClick={() =>
                    editStudyState.update({
                      unsavedWelcomeMessage: editStudyState.welcomeMessage,
                    })
                  }
                >
                  Edit
                </DevExButton>
                <QuestionCardIntro
                  title={<strong>{editStudyState.name}</strong>}
                  message={editStudyState.welcomeMessage || DEFAULT_WELCOME_MESSAGE}
                  questionCount={numberedStepCount}
                />
              </Flex>
            );
          }
          break;
        case 'DEMOGRAPHIC':
          if (step.typeIndex === 0) {
            // only freemium companies can edit teams
            const editing = !!editStudyState.unsavedTeams;

            const canEdit = !window.dx.user?.company.isEmp || empCompanyCanEditTeams;

            questionCards.push(
              <Flex vertical align="end" gap={theme.variable.spacing.md}>
                <Flex gap={theme.variable.spacing.sm}>
                  {canEdit &&
                    (!editing ? (
                      <DevExButton
                        key="edit-teams"
                        type="outline"
                        icon={<FontAwesomeIcon icon={faPen} />}
                        onClick={() => editStudyState.update({ unsavedTeams: [] })}
                      >
                        Edit teams
                      </DevExButton>
                    ) : (
                      <DevExButton
                        key="cancel-edit-teams"
                        onClick={() => editStudyState.update({ unsavedTeams: null })}
                      >
                        Cancel
                      </DevExButton>
                    ))}
                  {editing && (
                    <DevExButton
                      type="primary"
                      onClick={() => {
                        trackEvent('free-inbound:onboarding:configure-teams');
                        setSavingTeams(true);

                        const teamsByRef = keyBy(teams, 'ref');
                        const queries: Promise<any>[] = [];

                        for (const unsavedTeam of editStudyState.unsavedTeams ?? []) {
                          if (unsavedTeam.ref.startsWith('UNSAVED')) {
                            // create team
                            queries.push(
                              createTeam({
                                requestBody: {
                                  name: unsavedTeam.value,
                                  parentRef: unsavedTeam.parentRef,
                                },
                              })
                            );
                          } else {
                            const savedTeam = teamsByRef[unsavedTeam.ref];

                            if (unsavedTeam.isDeleted) {
                              // delete team
                              queries.push(deleteTeam({ teamRef: unsavedTeam.ref }));
                            } else if (unsavedTeam.isHidden !== savedTeam?.isHidden) {
                              // update isHidden
                              queries.push(
                                updateTeam({
                                  teamRef: unsavedTeam.ref,
                                  requestBody: {
                                    isHidden: unsavedTeam.isHidden,
                                  },
                                })
                              );
                            }
                          }
                        }

                        Promise.allSettled(queries)
                          .then(() => {
                            queryClient.refetchQueries(['GET_TEAMS']).then(() => {
                              editStudyState.update({ unsavedTeams: null });
                              setTimeout(() => setSavingTeams(false), 0);
                            });
                          })
                          .catch(() => setSavingTeams(false));
                      }}
                      loading={savingTeams}
                    >
                      Save
                    </DevExButton>
                  )}
                </Flex>

                <QuestionCardEditableDemographic />
              </Flex>
            );
          }

          break;
        case 'PROMPT':
          const promptSlug = editStudyState.promptSlugs[step.typeIndex!];
          const prompt = promptsBySlug[promptSlug];

          if (!prompt) {
            questionCards.push(null);
          } else {
            const editable = prompt.scope === Prompt.scope.CUSTOMER && !prompt.frozen;

            questionCards.push(
              <Flex vertical align="end" gap={theme.variable.spacing.md}>
                <Flex gap={theme.variable.spacing.sm}>
                  <DevExButton
                    key={`remove-${prompt.slug}`}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={() => onRemovePrompt(prompt)}
                  >
                    Remove
                  </DevExButton>
                  <Tooltip
                    title={
                      !editable
                        ? prompt.scope === Prompt.scope.CUSTOMER
                          ? UNEDITIBALE_CUSTOMER_QUESTION_TOOLTIP
                          : UNEDITIBALE_CANONICAL_QUESTION_TOOLTIP
                        : ''
                    }
                  >
                    <div>
                      <DevExButton
                        type="outline"
                        icon={<FontAwesomeIcon icon={faPen} />}
                        onClick={() => onEditPrompt(prompt)}
                        disabled={!editable}
                      >
                        Edit
                      </DevExButton>
                    </div>
                  </Tooltip>
                </Flex>

                <QuestionCardPrompt
                  key={prompt.slug}
                  number={stepIndex - 1}
                  prompt={prompt}
                  tag={topicsBySlug[prompt.topicSlug]?.label}
                  validated
                  disabled
                />
              </Flex>
            );
          }
          break;
        case 'VOTING':
          const scoreValues = [1, 3, 5];
          const scoredPrompts: ScoredPrompt[] = [];
          const initialVerbatims: Record<string, string> = {};

          for (let i = 0; i < editStudyState.promptSlugs.length; i++) {
            const promptSlug = editStudyState.promptSlugs[i];

            const prompt = promptsBySlug[promptSlug];
            const topic = prompt && topicsBySlug[prompt.topicSlug];

            // caution: prompt and topic will be intermittently undefined due to race conditions
            if (prompt && topic) {
              scoredPrompts.push({
                ...prompt,
                score: scoreValues[i % 3],
                topicLabel: topic.label,
                choiceLabels: [],
              });

              if (i === 0) {
                initialVerbatims[topic.slug] = '';
              }
            }
          }

          questionCards.push(
            <QuestionCardVerbatims
              disabled
              number={stepIndex - 1}
              scoredPrompts={scoredPrompts}
              initialVerbatims={initialVerbatims}
            />
          );
          break;
      }
    }
  }

  const questionCard = unsavedCustomerPrompt ? (
    <QuestionCardEditablePrompt />
  ) : (
    questionCards[stepIndex]
  );

  return (
    <Flex vertical style={{ flex: 1 }}>
      <DevExPageTitle>{`Survey ${editStudyState.loaded ? editStudyState.name : ''}`}</DevExPageTitle>
      <EditStudyHeader />

      <Flex style={{ flex: 1 }}>
        <EditStudySidebar />

        <Flex
          align="center"
          justify="center"
          style={{ paddingInline: theme.variable.spacing.xl, flex: 1 }}
        >
          {editStudyState.loaded && (
            <div style={{ maxWidth: 800, flex: 1, paddingBlock: theme.variable.spacing.xl }}>
              {questionCard}
            </div>
          )}
        </Flex>

        {editStudyState.loaded && (
          <TopicHygiene
            icon={<FontAwesomeIcon icon={faLightbulbOn} />}
            title="Insights"
            promptSlugs={editStudyState.promptSlugs}
            collapsible
          />
        )}
      </Flex>
    </Flex>
  );
};
