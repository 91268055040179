import { COLOR_FOUNDATIONS } from '../common/DevExThemeContext';

export const invertDevExThemeColor = (color: string, themeKey: string) => {
  if (color[0] !== '#') {
    return COLOR_FOUNDATIONS.light;
  }
  const [r, g, b, a] = [0, 2, 4, 6].map(
    (i) => (parseInt(color.slice(i + 1, i + 3), 16) || 255) / 255
  );
  let lightness = (r + g + b) / 3;
  if (themeKey === 'dark') {
    lightness = lightness * a; // shift to 0 with no opacity
  } else {
    lightness = lightness + (1 - lightness) * (1 - a); // shift 1 with no opacity
  }
  return lightness > 0.55 ? COLOR_FOUNDATIONS.dark : COLOR_FOUNDATIONS.light;
};
