/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PatchedDevExUserRequest = {
    name?: string;
    /**
     * The job level of this user.
     *
     * * `0` - Founder
     * * `1` - Executive
     * * `2` - Vice President
     * * `3` - Senior Director
     * * `4` - Director
     * * `5` - Senior Manager
     * * `6` - Manager
     * * `7` - Individual Contributor
     * * `8` - Other
     */
    jobLevel?: PatchedDevExUserRequest.jobLevel | null;
};

export namespace PatchedDevExUserRequest {

    /**
     * The job level of this user.
     *
     * * `0` - Founder
     * * `1` - Executive
     * * `2` - Vice President
     * * `3` - Senior Director
     * * `4` - Director
     * * `5` - Senior Manager
     * * `6` - Manager
     * * `7` - Individual Contributor
     * * `8` - Other
     */
    export enum jobLevel {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
        '_5' = 5,
        '_6' = 6,
        '_7' = 7,
        '_8' = 8,
    }


}

