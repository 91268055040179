import React, { useMemo } from 'react';
import { Flex, Tooltip } from 'antd';
import { faCommentLines } from '@fortawesome/pro-regular-svg-icons';
import { faLeftFromLine } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomComponent } from 'antd/es/_util/type';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { countBy, keyBy, sortBy } from 'lodash-es';

import { trackEvent } from 'jf/analytics/Analytics';
import { BenchmarkClient, StudyClient, StudyLibraryClient, TopicVerbatimWithTeamRef } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExTable, DevExTableColumn } from 'jf/components/DevExTable';
import { VERBATIMS_PROMPT_TEXT } from 'jf/pages/study/question/QuestionCardVerbatims';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { BenchmarkPercentile } from '../benchmark/BenchmarkSelect';
import { ScoreBlock } from '../ScoreBlock';
import { TopicDrawer } from '../topicDrawer/TopicDrawer';
import { useStudyScores } from '../useStudyScores';
import { useStudyVerbatims } from '../useStudyVerbatims';

import { BenchmarkCell } from './BenchmarkCell';
import { FreqDistBar } from './FreqDistBar';
import { TrendCell } from './TrendCell';

type TopicDataRecord = {
  topicSlug: string;
  topicLabel: string;
  score: number;
  scoreTrend?: number;
  freqDist: number[];
  promptCount: number;
  priority: number;
  commentCount: number;
  calculatedBenchmark?: number;
};

type TopicScoreTableProps = {
  studyRef: string | undefined;
  teamRef?: string;
  benchmarkPercentile?: BenchmarkPercentile | undefined;
};

const getTopicPriorityBySlug = (topicVerbatims: TopicVerbatimWithTeamRef[]) => {
  const countBySlug = countBy(topicVerbatims, 'topicSlug');

  let currentCount = -1;
  let currentPriority = 0;
  const priorityBySlug = {};

  const countEntries = Object.entries(countBySlug).sort((a, b) => b[1] - a[1]);
  for (const [topicSlug, count] of countEntries) {
    if (count !== currentCount) {
      currentCount = count;
      currentPriority++;
    }

    priorityBySlug[topicSlug] = currentPriority;
  }

  return priorityBySlug;
};

export const TopicScoreTable: React.FC<TopicScoreTableProps> = (props) => {
  const theme = useDevExTheme();
  const topicDrawer = useJFOverlay(TopicDrawer);

  const showBenchmarks = !!window.dx.user?.company.aggregatable;
  const { bakunawaDevexSeriesMvpQ324: seriesEnabled } = useFlags();

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: props.studyRef });

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const { data: topicScores } = useStudyScores({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: ['TOPIC'],
  });

  const { data: cohorts } = useClientQuery(BenchmarkClient.getBenchmarkCohorts, null, {
    enabled: showBenchmarks,
  });
  const cohort = cohorts?.[0]; // grab the first cohort since only one should exist right now
  const { data: cohortScores } = useClientQuery(BenchmarkClient.getBenchmarkCohortScores, {
    slug: cohort?.slug,
    type: ['TOPIC'],
  });

  const { data: topicVerbatims } = useStudyVerbatims({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
  });

  const { data: lastTopicScores } = useStudyScores({
    studyRef: study?.lastStudyRef,
    teamRef: props.teamRef,
    type: ['TOPIC'],
  });

  const data = useMemo(() => {
    if (!study || !topics || !topicScores || !topicVerbatims) {
      return undefined;
    }

    const data: TopicDataRecord[] = [];

    const topicsBySlug = keyBy(topics, 'slug');
    const benchmarkBySlug = keyBy(cohortScores, 'slug');
    const promptCountByTopicSlug = countBy(study.prompts, 'topicSlug');
    const topicPriorityBySlug = getTopicPriorityBySlug(topicVerbatims);
    const commentCountByTopicSlug = countBy(
      topicVerbatims.filter((verbatim) => verbatim.responseText),
      'topicSlug'
    );

    // create a data record for each topic
    for (const score of topicScores) {
      const topic = topicsBySlug[score.slug];

      // get last topic score if applicable
      const lastScore = lastTopicScores?.find(({ slug }) => slug === score.slug);
      const benchmark = benchmarkBySlug[topic.slug];
      if (topic) {
        data.push({
          topicSlug: topic.slug,
          topicLabel: topic.label,
          score: score.scaledAvg,
          scoreTrend: lastScore ? score.scaledAvg - lastScore.scaledAvg : undefined,
          freqDist: score.freqDist,
          promptCount: promptCountByTopicSlug[topic.slug] ?? 0,
          priority: topicPriorityBySlug[topic.slug] ?? 0,
          commentCount: commentCountByTopicSlug[topic.slug] ?? 0,
          calculatedBenchmark:
            benchmark && props.benchmarkPercentile
              ? score.scaledAvg - benchmark[props.benchmarkPercentile]
              : undefined,
        });
      }
    }

    return sortBy(data, 'topicLabel');
  }, [
    study,
    topics,
    topicScores,
    topicVerbatims,
    cohortScores,
    lastTopicScores,
    props.benchmarkPercentile,
  ]);

  const columns: DevExTableColumn<TopicDataRecord>[] = [
    {
      key: 'topic',
      title: 'Topic',
      dataIndex: 'topicLabel',
      sorter: (a, b) => a.topicLabel.localeCompare(b.topicLabel),
      width: '120px',
    },
    {
      key: 'priority',
      title: (
        <Tooltip
          title={`Priority is based on the number of votes received by respondents when asked, "${VERBATIMS_PROMPT_TEXT}".`}
        >
          Priority
        </Tooltip>
      ),
      render: (record) =>
        record.priority ? (
          <span>#{record.priority}</span>
        ) : (
          <span style={{ color: theme.color.text.tertiary }}>&mdash;</span>
        ),
      sorter: (a, b) => {
        if (a.priority && b.priority) {
          return a.priority - b.priority;
        }
        return a.priority ? -1 : 1; // sort zeroes to the end
      },
      align: 'end',
      width: '70px',
      defaultSortOrder: 'ascend',
    },
    {
      key: 'score',
      title: 'Score',
      render: (record) => (
        <Flex justify="end">
          <ScoreBlock score={record.score} />
        </Flex>
      ),
      sorter: (a, b) => a.score - b.score,
      width: '60px',
    },
    {
      key: 'scoreTrend',
      title: (
        <Tooltip
          title={
            !study?.sequenceRef && seriesEnabled
              ? 'Add this survey to a series to see how your scores have evolved.'
              : ''
          }
        >
          Trend
        </Tooltip>
      ),

      render: (record) => <TrendCell trend={record.scoreTrend} />,
      sorter: (a, b) => (a.scoreTrend || 0) - (b.scoreTrend || 0),
      width: '60px',
    },
    ...(props.benchmarkPercentile
      ? [
          {
            key: 'scoreBenchmark',
            title: 'Benchmark',
            render: (record) => (
              <Flex justify="end">
                <BenchmarkCell
                  benchmark={
                    record.calculatedBenchmark !== undefined
                      ? record.calculatedBenchmark
                      : undefined
                  }
                />
              </Flex>
            ),
            sorter: (a, b) => a.calculatedBenchmark - b.calculatedBenchmark,
            width: '60px',
          },
        ]
      : []),
    {
      key: 'prompts',
      title: 'Questions',
      dataIndex: 'promptCount',
      sorter: (a, b) => a.promptCount - b.promptCount,
      align: 'right',
      width: '90px',
    },
    {
      key: 'sentiment',
      title: 'Sentiment',
      render: (record) => <FreqDistBar freqDist={record.freqDist} />,
      sorter: (a, b) => a.score - b.score,
    },
    {
      key: 'comments',
      title: 'Comments',
      render: (record) =>
        !!record.commentCount && (
          <Flex gap={theme.variable.spacing.xs} align="center" justify="right">
            {record.commentCount}
            <FontAwesomeIcon icon={faCommentLines} />
          </Flex>
        ),
      sorter: (a, b) => a.commentCount - b.commentCount,
      width: '90px',
    },
  ];

  const TopicTableRow: CustomComponent = (rowProps) => {
    const topicSlug = rowProps['data-row-key'];

    return (
      <Tooltip
        title={
          <Flex align="center" gap={theme.variable.spacing.xs}>
            Click to expand topic
            <FontAwesomeIcon icon={faLeftFromLine} />
          </Flex>
        }
      >
        <tr
          style={{ cursor: 'pointer' }}
          onClick={() => {
            trackEvent('survey-analyzer:table:click', {
              surveyRef: props.studyRef,
              topicSlug,
            });
            topicDrawer.open({
              studyRef: props.studyRef,
              topicSlug,
              teamRef: props.teamRef,
            });
          }}
        >
          {rowProps.children}
        </tr>
      </Tooltip>
    );
  };

  return (
    <DevExTable
      data-cy="topics-table"
      columns={columns}
      dataSource={data}
      rowKey="topicSlug"
      components={{ body: { row: TopicTableRow } }}
    />
  );
};
