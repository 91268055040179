/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { faArrowTurnDownRight, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { max, partition, sortBy } from 'lodash-es';
import { css } from 'styled-components';

import { RosterClient, Team } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';
import { CATCH_ALL_TEAM_REF } from 'jf/pages/study/question/QuestionCardDemographic';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { ChildTeamCard } from './ChildTeamCard';
import { ParentTeamCard } from './ParentTeamCard';

export type UnsavedTeam = Team & {
  isDeleted: boolean;
};

type TeamsEditorProps = {
  onChange: (unsavedTeams: UnsavedTeam[]) => void;
  style?: React.CSSProperties;
};

export const TeamsEditor: React.FC<TeamsEditorProps> = (props) => {
  const theme = useDevExTheme();

  const [unsavedTeams, setUnsavedTeams] = useState<UnsavedTeam[]>([]);

  const { data: teams } = useClientQuery(RosterClient.getTeams);

  const baseLevel = max(teams?.map((team) => team.level)) ?? 1;
  const onlyBaseTeams = baseLevel === 1;

  // initialize unsavedTeams
  useEffect(() => {
    if (teams) {
      const unsavedTeams = sortBy(teams, 'value')
        .filter((team) => team.ref !== CATCH_ALL_TEAM_REF)
        .map((team) => ({
          ...team,
          isDeleted: false,
        }));

      setUnsavedTeams(unsavedTeams);
    }
  }, [teams]);

  useEffect(() => {
    props.onChange(unsavedTeams);
  }, [unsavedTeams]);

  const onDeleteTeam = (team: UnsavedTeam) => {
    if (team.isEmp) {
      // toggle hidden status of EMP team
      team.isHidden = !team.isHidden;
      setUnsavedTeams([...unsavedTeams]);
    } else if (team.ref.startsWith('UNSAVED')) {
      // remove unsaved team
      setUnsavedTeams(unsavedTeams.filter((t) => t.ref !== team.ref));
    } else {
      // toggle deleted status of non-EMP team
      team.isDeleted = !team.isDeleted;
      setUnsavedTeams([...unsavedTeams]);
    }
  };

  const onAddTeam = (parentTeam: UnsavedTeam | undefined) => {
    // skip if unnamed team already exists under parent
    if (unsavedTeams.find((team) => team.parentRef === parentTeam?.ref && !team.value)) {
      return;
    }

    const ref = `UNSAVED_${btoa(Math.random().toString()).substring(0, 24)}`;

    // add a team with no name, so that the user can name it
    setUnsavedTeams((unsavedTeams) => [
      ...unsavedTeams,
      {
        value: '',
        ref,
        parentRef: parentTeam?.ref,
        level: baseLevel,
        levelName: '',
        isLeaf: true,
        isHidden: false,
        isEmp: false,
        isDeleted: false,
      },
    ]);
  };

  const onRenameTeam = (ref: string, name: string) => {
    const team = unsavedTeams.find((team) => team.ref === ref);
    if (team) {
      team.value = name;
      setUnsavedTeams([...unsavedTeams]);
    }
  };

  // render teams recursively to create hierarchical structure
  const renderTeam = (team: UnsavedTeam) => {
    const childTeams = unsavedTeams.filter((t) => t.parentRef === team.ref);

    if (!childTeams.length) {
      return <ChildTeamCard key={team.ref} team={team} onDelete={() => onDeleteTeam(team)} />;
    }

    let [baseChildTeams, nonBaseChildTeams] = partition(
      childTeams,
      (team) => team.level === baseLevel
    );

    // we currently only allow adding new base level teams next to ones that already exist
    const allowAdding = team.level === baseLevel - 1 && !team.isLeaf;

    return (
      <Flex vertical gap={theme.variable.spacing.sm} key={team.ref}>
        <ParentTeamCard team={team} onAdd={allowAdding ? () => onAddTeam(team) : undefined} />

        <Flex>
          <FontAwesomeIcon
            icon={faArrowTurnDownRight}
            style={{ padding: theme.variable.spacing.sm }}
          />

          <Flex vertical gap={theme.variable.spacing.sm} style={{ flexGrow: 1 }}>
            {!!baseChildTeams.length && renderChildTeams(team, baseChildTeams)}
            {nonBaseChildTeams.map(renderTeam)}
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderChildTeams = (parentTeam: UnsavedTeam | undefined, childTeams: UnsavedTeam[]) => {
    return (
      <div
        css={css`
          display: grid;
          grid-template-columns: ${childTeams.length ? '1fr 1fr' : '1fr'};
          gap: ${(props) => props.theme.variable.spacing.sm};
        `}
      >
        {childTeams.map((childTeam) => {
          return (
            <ChildTeamCard
              key={childTeam.ref}
              team={childTeam}
              style={{ width: 'auto' }}
              onDelete={() => onDeleteTeam(childTeam)}
              onAdd={() => onAddTeam(parentTeam)}
              onRename={(name) => onRenameTeam(childTeam.ref, name)}
            />
          );
        })}

        {
          // when there's no parent team (like in the case of non-EMP companies), provide team adding button in grid
          !parentTeam && !childTeams.some((team) => !team.value) && (
            <DevExButton
              type="dashed"
              icon={<FontAwesomeIcon icon={faPlus} />}
              style={{ height: 42, justifyContent: 'center' }}
              onClick={() => onAddTeam(parentTeam)}
            >
              Add team(s)
            </DevExButton>
          )
        }
      </div>
    );
  };

  return (
    <Flex vertical gap={theme.variable.spacing.sm} style={props.style}>
      {onlyBaseTeams
        ? renderChildTeams(undefined, unsavedTeams)
        : unsavedTeams.filter((team) => team.level === 1).map(renderTeam)}
    </Flex>
  );
};
