import React, { useEffect, useMemo, useState } from 'react';
import { Flex } from 'antd';
import { CustomComponent } from 'antd/es/_util/type';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { keyBy } from 'lodash-es';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { css } from 'styled-components';

import { BenchmarkClient, Study, StudyClient, StudyLibraryClient } from 'jf/api';
import { DevExPageLayout } from 'jf/common/DevExPageLayout';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExTable } from 'jf/components/DevExTable';
import { compareNullableNumbers } from 'jf/utils/compareNullableNumbers';
import { useClientQuery } from 'jf/utils/useClientQuery';
import { usePageStorage } from 'jf/utils/usePageStorage';

import { BenchmarkPercentile, BenchmarkSelect } from '../benchmark/BenchmarkSelect';
import { BenchmarkCell } from '../insights/BenchmarkCell';
import { TrendCell } from '../insights/TrendCell';
import { ScoreBlock } from '../ScoreBlock';
import { makeStudyAnalyzerPath } from '../StudyAnalyzerPage';
import { TeamCascader } from '../TeamCascader';
import { useStudyScores } from '../useStudyScores';

import { PromptResults } from './PromptResults';

type PromptDataRecord = {
  index: number;
  promptSlug: string;
  promptText: string;
  score?: number;
  scoreTrend?: number;
  calculatedBenchmark?: number;
};

export const ResultsTab: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef: string }>();
  const theme = useDevExTheme();
  const { bakunawaDevexBenchmarksQ224 } = useFlags();

  const [benchmarkPercentile, setBenchmarkPercentile] = useState<BenchmarkPercentile>();
  const [promptSlug, setPromptSlug] = useState<string>();

  usePageStorage({ slug: [promptSlug, setPromptSlug] }, undefined, true);

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });
  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);
  const { data: promptScores } = useStudyScores({
    studyRef,
    teamRef,
    type: ['PROMPT'],
  });

  const isClosed = study?.status === Study.status.CLOSED;
  const showTeams = isClosed;
  const showBenchmarks =
    !!bakunawaDevexBenchmarksQ224 && !!window.dx.user?.company.aggregatable && study && isClosed;

  const { data: cohorts } = useClientQuery(BenchmarkClient.getBenchmarkCohorts, null, {
    enabled: showBenchmarks,
  });
  const cohort = cohorts?.[0]; // grab the first cohort since only one should exist right now
  const { data: cohortScores } = useClientQuery(BenchmarkClient.getBenchmarkCohortScores, {
    slug: cohort?.slug,
    type: ['PROMPT'],
  });

  const { data: lastPromptScores } = useStudyScores({
    studyRef: study?.lastStudyRef,
    teamRef,
    type: ['PROMPT'],
  });

  const data = useMemo(() => {
    if (!study || !prompts || !promptScores) {
      return undefined;
    }

    const data: PromptDataRecord[] = [];

    const promptsBySlug = keyBy(prompts, 'slug');
    const benchmarkBySlug = keyBy(cohortScores, 'slug');

    if (promptScores.length) {
      for (const score of promptScores) {
        // get last prompt score if applicable
        const lastScore = lastPromptScores?.find(({ slug }) => slug === score.slug);
        const benchmark = benchmarkBySlug[score.slug];

        data.push({
          index: study.prompts.findIndex(({ slug }) => slug === score.slug),
          promptSlug: score.slug,
          promptText: promptsBySlug[score.slug].text,
          score: score.scaledAvg,
          scoreTrend: lastScore ? score.scaledAvg - lastScore.scaledAvg : undefined,
          calculatedBenchmark:
            benchmark && benchmarkPercentile
              ? score.scaledAvg - benchmark[benchmarkPercentile]
              : undefined,
        });
      }
    } else {
      // if survey is still open and we have no responses, just enumerate all of the study's prompts
      for (let i = 0; i < study.prompts.length; i++) {
        const slug = study.prompts[i].slug;
        const prompt = promptsBySlug[slug];

        if (prompt) {
          data.push({
            index: i,
            promptSlug: slug,
            promptText: prompt.text,
          });
        }
      }
    }

    data.sort((a, b) => (a.score ?? 0) - (b.score ?? 0));
    return data;
  }, [study, promptScores, lastPromptScores, prompts, cohortScores, benchmarkPercentile]);

  // initialize slugs as needed with the first option
  useEffect(() => {
    if (data?.length) {
      setPromptSlug(data[0].promptSlug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const PromptTableRow: CustomComponent = (rowProps) => {
    const rowPromptSlug = rowProps['data-row-key'];
    const active = rowPromptSlug === promptSlug;

    return (
      <tr
        css={css`
          cursor: pointer;

          td {
            background: ${active ? theme.color.accent.aura.primary : undefined};
          }
        `}
        onClick={() => setPromptSlug(rowPromptSlug)}
      >
        {rowProps.children}
      </tr>
    );
  };

  return (
    <DevExPageLayout>
      <Flex gap={theme.variable.spacing.sm}>
        {showTeams && (
          <TeamCascader
            studyRef={studyRef}
            value={teamRef}
            onChange={(teamRef) =>
              navigate({
                pathname: makeStudyAnalyzerPath(studyRef, teamRef, 'questions'),
                search: location.search,
              })
            }
            allowClear
          />
        )}
        {showBenchmarks && (
          <BenchmarkSelect value={benchmarkPercentile} onChange={setBenchmarkPercentile} />
        )}
      </Flex>

      <Flex gap={theme.variable.spacing.lg}>
        <DevExTable
          data-cy="prompts-table"
          columns={[
            {
              key: 'index',
              title: '',
              render: (datum) => <Flex justify="end">{datum.index + 1}</Flex>,
              sorter: (a, b) => a.index - b.index,
            },
            {
              key: 'name',
              title: 'Question',
              render: (datum) => (
                <div
                  title={datum.promptText}
                  css={css`
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: normal;
                  `}
                >
                  {datum.promptText}
                </div>
              ),
            },
            {
              key: 'score',
              title: 'Score',
              render: (datum) => (
                <Flex justify="end">
                  <ScoreBlock score={datum.score} />
                </Flex>
              ),
              sorter: (a, b, order) => compareNullableNumbers(a.score, b.score, order),
              width: '60px',
            },
            ...(isClosed
              ? [
                  {
                    key: 'scoreTrend',
                    title: 'Trend',
                    render: (datum) => <TrendCell trend={datum.scoreTrend} />,
                    sorter: (a, b, order) =>
                      compareNullableNumbers(a.scoreTrend, b.scoreTrend, order),
                    width: '60px',
                  },
                ]
              : []),
            ...(benchmarkPercentile
              ? [
                  {
                    key: 'scoreBenchmark',
                    title: 'Benchmark',
                    render: (datum) => (
                      <Flex justify="end">
                        <BenchmarkCell benchmark={datum.calculatedBenchmark} />
                      </Flex>
                    ),
                    sorter: (a, b, order) =>
                      compareNullableNumbers(a.calculatedBenchmark, b.calculatedBenchmark, order),
                    width: '60px',
                  },
                ]
              : []),
          ]}
          dataSource={data}
          rowKey={'promptSlug'}
          components={{ body: { row: PromptTableRow } }}
          style={{ width: benchmarkPercentile ? 560 : 460 }}
        />

        <PromptResults studyRef={studyRef} teamRef={teamRef} promptSlug={promptSlug} />
      </Flex>
    </DevExPageLayout>
  );
};
