/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatedTeamRequest } from '../models/CreatedTeamRequest';
import type { PatchedTeamRequest } from '../models/PatchedTeamRequest';
import type { RosterSegments } from '../models/RosterSegments';
import type { Team } from '../models/Team';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RosterClient {

    /**
     * Get roster team and segment names and values
     * @returns RosterSegments
     * @throws ApiError
     */
    public static getRosterSegments(): CancelablePromise<RosterSegments> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/roster/segments',
        });
    }

    /**
     * Create a new team
     * @returns any No response body
     * @throws ApiError
     */
    public static createTeam({
        requestBody,
    }: {
        requestBody: CreatedTeamRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/roster/team',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a team's details.
     * @returns Team
     * @throws ApiError
     */
    public static getTeam({
        teamRef,
    }: {
        teamRef: string,
    }): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/roster/team/{teamRef}',
            path: {
                'teamRef': teamRef,
            },
        });
    }

    /**
     * Update a team
     * @returns Team
     * @throws ApiError
     */
    public static updateTeam({
        teamRef,
        requestBody,
    }: {
        teamRef: string,
        requestBody?: PatchedTeamRequest,
    }): CancelablePromise<Team> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/roster/team/{teamRef}',
            path: {
                'teamRef': teamRef,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a team
     * @returns void
     * @throws ApiError
     */
    public static deleteTeam({
        teamRef,
    }: {
        teamRef: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/roster/team/{teamRef}',
            path: {
                'teamRef': teamRef,
            },
        });
    }

    /**
     * Get all active teams.
     * @returns Team
     * @throws ApiError
     */
    public static getTeams(): CancelablePromise<Array<Team>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/roster/teams',
        });
    }

}
