/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BenchmarkNumericScore = {
    /**
     * * `PROMPT` - PROMPT
     * * `SUBTOPIC` - SUBTOPIC
     * * `TOPIC` - TOPIC
     */
    type: BenchmarkNumericScore.type;
    /**
     * Can refer to a CanonicalTopic, CanonicalSubtopic, CanonicalPrompt or CustomerPrompt, based on `type`.  Will be null for Study-level benchmarks.
     */
    slug?: string | null;
    /**
     * The 10th percentile 0-100 value
     */
    p10: number;
    /**
     * The 25th percentile 0-100 value
     */
    p25: number;
    /**
     * The 50th percentile 0-100 value
     */
    p50: number;
    /**
     * The 75th percentile 0-100 value
     */
    p75: number;
    /**
     * The 90th percentile 0-100 value
     */
    p90: number;
};

export namespace BenchmarkNumericScore {

    /**
     * * `PROMPT` - PROMPT
     * * `SUBTOPIC` - SUBTOPIC
     * * `TOPIC` - TOPIC
     */
    export enum type {
        PROMPT = 'PROMPT',
        SUBTOPIC = 'SUBTOPIC',
        TOPIC = 'TOPIC',
    }


}

