import React from 'react';
import { Flex } from 'antd';
import {
  faArrowRightLong,
  faArrowTrendDown,
  faArrowTrendUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil } from 'lodash';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { KeyMetric } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExCard } from 'jf/components/DevExCard';

import { MetricTopicSlug } from './TopicMetrics';

const styles = {
  metricCard: css`
    .metricCard__valueRow {
      align-items: first baseline;
      gap: ${(props) => props.theme.variable.spacing.sm};
      text-wrap: nowrap;
      justify-content: space-between;
    }
    .card__body {
      height: 100%;
      justify-content: space-between;
    }
  `,
};

const isWeeklyUnit = (unit?: string, isWeekly?: boolean) => {
  return unit === 'days' || (unit === null && isWeekly);
};

type ConversionEntry = {
  units: string;
  value?: number;
};

export const unitConversion = (slug: string, inputValue?: number) => {
  const toHours = (input: number | undefined) =>
    input ? Math.round(input * 24 * 100) / 100 : undefined;

  // add to convertMap for specific unit conversion
  const convertMap: Record<string, ConversionEntry> = {
    teamIncidentMeanTimeToResolve: {
      units: 'hours',
      value: toHours(inputValue),
    },
  };
  return slug in convertMap ? convertMap[slug] : null;
};

export const getSymbolForUnit = (unit: string, isWeekly: boolean | undefined, slug?: string) => {
  if (unit === 'days') {
    return 'days';
  }
  if (unit === 'percent') {
    return '%';
  }

  if (isWeeklyUnit(unit, isWeekly)) {
    return '/wk';
  }

  if (slug && unitConversion(slug)) {
    return unitConversion(slug)?.units || null;
  }
  if (unit === 'hours') {
    return 'days';
  }
  if (unit) {
    return unit;
  }
  return null;
};

type FormattedMetricProps = {
  metric: number | string;
  unit: string;
  isWeekly?: boolean;
  slug?: string;
};

const FormattedMetric = ({ metric, unit, isWeekly, slug }: FormattedMetricProps) => {
  const symbol = getSymbolForUnit(unit, isWeekly, slug);
  const theme = useDevExTheme();
  return (
    <Flex align="baseline">
      <div
        style={{
          fontSize: theme.variable.fontSize.xl,
          fontWeight: 'bold',
        }}
      >
        {metric}
      </div>
      {!!unit && (
        <div
          style={{ fontSize: theme.variable.fontSize.sm, marginLeft: theme.variable.spacing.sm }}
        >{`${symbol}`}</div>
      )}
    </Flex>
  );
};

export const formatThousands = (value: number) =>
  value >= 1000 ? `${parseFloat((value / 1000).toFixed(1))}k` : value;

export const getPercentChange = (a: number, b: number) => {
  // so that we don't get NaN for this case
  if (a === 0 && b === 0) {
    return 0;
  }
  return ((b - a) / Math.abs(a)) * 100;
};

const ArrowIndicator: React.FC<{ value: number; isUpGreen: boolean }> = ({ value, isUpGreen }) => {
  const up = value > 0;
  const theme = useDevExTheme();
  const color = () => {
    if ((up && isUpGreen) || (!up && !isUpGreen)) {
      return theme.color.visualization.polarity.positive.high.text;
    }
    if ((!up && isUpGreen) || (up && !isUpGreen)) {
      return theme.color.visualization.polarity.negative.high.text;
    }
  };
  return (
    <span>
      {value > 0 ? (
        <FontAwesomeIcon icon={faArrowTrendUp} color={color()} />
      ) : (
        <FontAwesomeIcon icon={faArrowTrendDown} color={color()} />
      )}
      <span style={{ color: color() }}>{` ${value.toFixed(1)}%`}</span>
    </span>
  );
};

export const formatMetric = (metricValue: number, unit: string) => {
  switch (unit) {
    case 'percent':
      return parseFloat((metricValue * 100).toFixed(1));
    case 'hours': // divide by 24
      return parseFloat((metricValue / 24).toFixed(2));
    default:
      return parseFloat(metricValue.toFixed(1));
  }
};

type MetricCardProps = {
  metric: KeyMetric;
  topicSlug: MetricTopicSlug;
  empLink: string;
};

export const MetricCard: React.FC<MetricCardProps> = (props) => {
  const {
    title,
    timeframeValue: value,
    valueLabel: unit,
    timeframeValuePrev: valuePrev,
    isUpGreen,
  } = props.metric;
  const percentChange = getPercentChange(valuePrev, value);
  const theme = useDevExTheme();
  const formattedMetric = formatMetric(value, unit);

  const onClick = () => {
    trackEvent('survey-analyzer:topic-metric:navigate', {
      metricTitle: title,
      metricSlug: props.metric.slug,
      topicSlug: props.topicSlug,
    });
    window.open(props.empLink, '_blank');
  };

  return (
    <div css={styles.metricCard}>
      <DevExCard onClick={onClick} style={{ height: '100%' }}>
        <Flex>
          <div style={{ fontWeight: 'bold' }}>{title}</div>
        </Flex>
        <Flex className="metricCard__valueRow">
          {!isNil(value) ? (
            <FormattedMetric metric={formatThousands(formattedMetric)} unit={unit || ''} />
          ) : (
            <span style={{ color: theme.color.visualization.polarity.neutral.text }}>&mdash;</span>
          )}
          <span>
            {percentChange === Infinity ? (
              <FontAwesomeIcon
                icon={faArrowRightLong}
                color={theme.color.visualization.polarity.neutral.text}
              />
            ) : (
              <ArrowIndicator value={percentChange} isUpGreen={isUpGreen} />
            )}
          </span>
        </Flex>
      </DevExCard>
    </div>
  );
};
