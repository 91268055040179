import React, { useState } from 'react';
import { Flex } from 'antd';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faClipboard, faRectangleVerticalHistory } from '@fortawesome/pro-thin-svg-icons';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { CircledFontAwesomeIcon } from 'jf/components/CircledFontAwesomeIcon';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExLoader } from 'jf/components/DevExLoader';
import { DevExModal } from 'jf/components/DevExModal';
import { DevExRadioButtons } from 'jf/components/DevExRadioButtons';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import {
  STUDIES_TABLE_FILTER_OPTIONS,
  StudiesTable,
  StudiesTableFilter,
} from '../studies/StudiesTable';

const AddExistingStudySection: React.FC<{ sequenceRef: string; onClose: () => void }> = ({
  sequenceRef,
  onClose: closeModal,
}) => {
  const theme = useDevExTheme();

  const { data: studies, isLoading } = useClientQuery(StudyClient.getStudies);
  const { mutateAsync: updateStudy } = useClientMutation(StudyClient.updateStudy);
  const queryClient = useQueryClient();

  const [selectedFilter, setSelectedFilter] = useState(StudiesTableFilter.ALL);

  return (
    <Flex vertical gap={theme.variable.spacing.md}>
      <div>Click on the survey you would like to add.</div>
      <Flex vertical gap={theme.variable.spacing.md}>
        <DevExRadioButtons
          items={STUDIES_TABLE_FILTER_OPTIONS}
          onChange={(filter: StudiesTableFilter) => {
            setSelectedFilter(filter);
          }}
          value={selectedFilter}
        />
        {isLoading ? (
          <DevExLoader />
        ) : (
          <StudiesTable
            filter={selectedFilter}
            onRowClick={(study) => {
              updateStudy({
                studyRef: study.ref,
                requestBody: {
                  sequenceRef,
                },
              }).then(() => {
                queryClient.invalidateQueries('GET_STUDIES');
                queryClient.removeQueries(['GET_STUDY', study.ref]);
              });
              closeModal();
            }}
            studies={(studies || []).filter(
              (study) => study.sequenceRef !== sequenceRef || study.sequenceRef === null
            )}
            columns={['name', 'status', 'last_updated']}
          />
        )}
      </Flex>
    </Flex>
  );
};

export const AddStudyToSequenceModal: React.FC<{ sequenceRef: string }> = (props) => {
  const modal = useJFOverlay(AddStudyToSequenceModal);
  const theme = useDevExTheme();
  const navigate = useNavigate();

  const [hasSelectedExisting, setHasSelectedExisting] = useState(false);

  const onCancel = () => {
    modal.close();
    setHasSelectedExisting(false);
  };

  return (
    <DevExModal
      title="Add a survey to this series"
      open={modal.isOpened}
      onCancel={onCancel}
      footer={null}
      width={720}
    >
      {hasSelectedExisting ? (
        <AddExistingStudySection sequenceRef={props.sequenceRef} onClose={modal.close} />
      ) : (
        <Flex vertical gap={theme.variable.spacing.md}>
          <div>What would you like to do?</div>
          <div
            style={{
              gap: theme.variable.spacing.md,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
            }}
          >
            <AddStudyOptionCard
              icon={faRectangleVerticalHistory}
              title="Add an existing survey"
              subtitle="Add an existing survey to see how your scores and participation have evolved over time."
              onClick={() => {
                setHasSelectedExisting(true);
              }}
            />
            <AddStudyOptionCard
              title="Create a new survey"
              subtitle="Create a new survey and see how your scores and participation are evolving over time."
              icon={faClipboard}
              onClick={() => {
                modal.close();
                navigate('study/create');
              }}
            />
          </div>
        </Flex>
      )}
    </DevExModal>
  );
};

const AddStudyOptionCard: React.FC<{
  onClick: () => void;
  title: string;
  subtitle: string;
  icon: IconDefinition;
}> = ({ icon, title, subtitle, onClick }) => {
  const theme = useDevExTheme();

  return (
    <DevExCard onClick={onClick}>
      <Flex vertical gap={theme.variable.spacing.md}>
        <Flex justify="center">
          <CircledFontAwesomeIcon icon={icon} />
        </Flex>
        <div
          style={{
            fontSize: theme.variable.fontSize.md,
            lineHeight: theme.variable.lineHeight,
            textAlign: 'center',
          }}
        >
          {title}
        </div>
      </Flex>
    </DevExCard>
  );
};
