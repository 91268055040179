import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Input, InputProps, InputRef } from 'antd';
import classNames from 'classnames';
import { css } from 'styled-components';

import { computeTextWidth, getElementFont } from '../utils/computeTextWidth';

const styles = {
  input: css`
    &.ant-input,
    .ant-input {
      height: 32px;
      background: ${(props) => props.theme.color.background.primary};
      border: 1px solid ${(props) => props.theme.color.card.border};
      border-radius: ${(props) => props.theme.variable.borderRadius};
      color: ${(props) => props.theme.color.text.primary};
      padding-inline: ${(props) => props.theme.variable.spacing.sm};
      transition: border 150ms ease;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;

      &:-webkit-autofill {
        -webkit-background-clip: text;
        -webkit-text-fill-color: ${(props) => props.theme.color.text.primary};
        caret-color: ${(props) => props.theme.color.text.primary};
        -webkit-box-shadow: 0 0 0 20px ${(props) => props.theme.color.accent.aura.primary} inset !important;
      }

      &::placeholder {
        color: ${(props) => props.theme.color.text.tertiary};
      }

      &:focus {
        border-color: ${(props) => props.theme.color.accent.primary};
      }

      &.ant-input-lg {
        height: 40px;
        border-radius: 20px;
        padding-inline: 12px;
      }

      &.input--error {
        border-color: ${(props) => props.theme.color.status.error.text};
      }
    }

    .ant-input-group-addon {
      color: ${(props) => props.theme.color.accent.inverse.primary};
      background: ${(props) => props.theme.color.accent.primary};
      border-color: ${(props) => props.theme.color.accent.primary};
      border-radius: ${(props) => props.theme.variable.borderRadius};
      border-start-end-radius: 0 !important;
      border-end-end-radius: 0 !important;
      padding-inline: ${(props) => props.theme.variable.borderRadius};

      &:first-child {
        padding-left: ${(props) => props.theme.variable.borderRadius};
      }

      &:last-child {
        padding-right: ${(props) => props.theme.variable.borderRadius};
      }
    }

    .ant-input-group-addon + input {
      border-start-start-radius: 0 !important;
      border-end-start-radius: 0 !important;
    }

    &.ant-input-group-wrapper-lg .ant-input-group-addon {
      border-radius: 20px;
      padding-inline: 12px;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }
  `,
};

interface DevExInputProps extends Omit<InputProps, 'onChange'> {
  value?: string;
  onChange?: (value: string) => void;
  error?: boolean;
  autosize?: boolean;
}

export const DevExInput = React.forwardRef<InputRef, DevExInputProps>(
  ({ error, autosize, ...props }, ref) => {
    const innerRef = useRef<InputRef>(null);
    const [width, setWidth] = useState<string>();

    // to use internal useRef and external forwardRef, we need useImperativeHandle to couple the two
    useImperativeHandle(ref, () => innerRef.current!);

    const className = classNames({
      'input--error': error,
    });

    useEffect(() => {
      if (autosize && innerRef?.current?.input && props.value) {
        setWidth(`${computeTextWidth(props.value, getElementFont(innerRef.current.input)) + 18}px`);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value, autosize]);

    return (
      <Input
        ref={innerRef}
        css={styles.input}
        className={className}
        {...props}
        onChange={(event) => props.onChange?.(event.target.value)}
        style={{
          width,
          ...props.style,
        }}
      />
    );
  }
);
