import React from 'react';
import { Flex, Tabs, TabsProps } from 'antd';
import { css } from 'styled-components';

import { useDevExTheme } from '../common/DevExThemeContext';

import { DevExBadge } from './DevExBadge';
import { DevExSkeleton } from './DevExSkeleton';

const styles = {
  tabs: css`
    color: inherit;
    line-height: inherit;

    .ant-tabs-nav {
      ::before {
        border-color: ${(props) => props.theme.color.card.border};
      }

      .ant-tabs-ink-bar {
        background-color: ${(props) => props.theme.color.accent.primary};
      }

      .ant-tabs-tab {
        font-size: ${(props) => props.theme.variable.fontSize.md};

        .ant-tabs-tab-btn {
          color: ${(props) => props.theme.color.text.secondary};
          text-shadow: none;
        }

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: ${(props) => props.theme.color.text.primary};
          }
        }

        &.ant-tabs-tab-disabled {
          pointer-events: none;

          .ant-tabs-tab-btn {
            color: ${(props) => props.theme.color.text.disabled};
          }
        }
      }
    }
  `,
};

// couldn't import this type directly from AntD, so I apologize for this mess - @nathan
type Tab = Required<Pick<TabsProps, 'items'>>['items'][number];

interface DevExTab extends Tab {
  count?: number;
}

interface DevExTabsProps extends TabsProps {
  items?: DevExTab[];
  loading?: boolean;
}

export const DevExTabs: React.FC<DevExTabsProps> = (props) => {
  const { items, ...passthroughProps } = props;

  const theme = useDevExTheme();

  const renderItem = (item: DevExTab) => {
    if (props.loading) {
      return <DevExSkeleton width={80} />;
    }

    // if item.count is provided, add a DevExBadge beside item.label
    if (item.count !== undefined) {
      return (
        <Flex gap={theme.variable.spacing.xs}>
          {item.label}
          <DevExBadge count={item.count} active={props.activeKey === item.key} />
        </Flex>
      );
    }

    return item.label;
  };

  return (
    <Tabs
      css={styles.tabs}
      items={items?.map((item) => ({
        ...item,
        label: renderItem(item),
        disabled: props.loading || item.disabled,
      }))}
      {...passthroughProps}
    />
  );
};
