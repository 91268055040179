import React from 'react';
import { Flex } from 'antd';
import { sum } from 'lodash-es';
import { css } from 'styled-components';

import { useFreqDistColors } from '../useFreqDistColors';

const styles = {
  bar: css`
    width: 100%;
    min-width: 200px;
    height: 18px;
    border-radius: ${(props) => props.theme.variable.spacing.xs};
    overflow: hidden;

    > div {
      height: 100%;
    }
  `,
};

const percentizeFreqDist = (data: number[]) => {
  const dataSum = sum(data);
  return data.map((d) => (d / dataSum) * 100);
};

type FreqDistBarProps = {
  freqDist: number[];
};

export const FreqDistBar: React.FC<FreqDistBarProps> = (props) => {
  const colors = useFreqDistColors();

  if (props.freqDist.length !== colors.length) {
    console.error(
      `FreqDistBar expected ${colors.length} values but received ${props.freqDist.length}.`
    );
    return null;
  }

  const percentages = percentizeFreqDist(props.freqDist);

  return (
    <Flex css={styles.bar}>
      {colors.map((color, i) => (
        <div key={i} style={{ width: `${percentages[i]}%`, background: color }} />
      ))}
    </Flex>
  );
};
