import React, { ReactNode } from 'react';
import { Flex } from 'antd';

import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExCheckbox } from 'jf/components/DevExCheckbox';
import { DevExCollapsible } from 'jf/components/DevExCollapsible';
import { DevExPopover } from 'jf/components/DevExPopover';
import { DevExTag } from 'jf/components/DevExTag';
import { DevExTextArea } from 'jf/components/DevExTextArea';
import { useLivePropState } from 'jf/utils/useLivePropState';

type VerbatimSectionOption = {
  key: string;
  label: string;
  popoverContent?: ReactNode;
};

export type VerbatimSection = {
  label: ReactNode;
  options: VerbatimSectionOption[];
};

type VerbatimSectionsProps = {
  sections: VerbatimSection[];
  value: Record<string, string>;
  onChange: (verbatims: Record<string, string>) => void;
  disabled?: boolean;
};

export const VerbatimSections: React.FC<VerbatimSectionsProps> = (props) => {
  const theme = useDevExTheme();
  const [verbatims, setVerbatims] = useLivePropState(props.value, props.onChange);
  const onChecked = (key: string, checked: boolean) => {
    if (checked) {
      verbatims[key] = '';
      // delay focus to prevent Enter key stroke on checkbox from being applied to textarea
      setTimeout(() => document.getElementById(key)?.focus(), 0);
    } else {
      delete verbatims[key];
    }
    setVerbatims({ ...verbatims });
  };

  const onType = (key: string, text: string) => {
    setVerbatims((verbatims) => ({ ...verbatims, [key]: text }));
  };

  return (
    <Flex vertical gap={theme.variable.spacing.lg}>
      {props.sections.map((section, i) => {
        return (
          !!section.options.length && (
            <Flex vertical gap={theme.variable.spacing.md} key={i}>
              {section.label}

              {section.options.map((option) => {
                const verbatim = verbatims[option.key];
                return (
                  <Flex key={option.key} vertical>
                    <Flex gap={theme.variable.spacing.sm}>
                      <DevExCheckbox
                        checked={verbatim !== undefined}
                        onChange={(checked) => onChecked(option.key, checked)}
                        tabIndex={props.disabled ? -1 : 0}
                      />
                      <DevExPopover placement="right" content={option.popoverContent}>
                        <DevExTag>{option.label}</DevExTag>
                      </DevExPopover>
                    </Flex>

                    <DevExCollapsible collapsed={verbatim === undefined}>
                      <div style={{ paddingTop: theme.variable.spacing.md }}> </div>
                      <DevExTextArea
                        id={option.key}
                        placeholder="How might we improve?"
                        style={{ maxWidth: 400 }}
                        value={verbatim}
                        onChange={(text) => onType(option.key, text)}
                        tabIndex={verbatim === undefined || props.disabled ? -1 : 0}
                      />
                    </DevExCollapsible>
                  </Flex>
                );
              })}
            </Flex>
          )
        );
      })}
    </Flex>
  );
};
