/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SegmentValue } from './SegmentValue';
import type { TeamSegment } from './TeamSegment';

export type NumericScoreWithTeamOrSegment = {
    /**
     * * `PROMPT` - PROMPT
     * * `SUBTOPIC` - SUBTOPIC
     * * `TOPIC` - TOPIC
     */
    type: NumericScoreWithTeamOrSegment.type;
    /**
     * Can refer to a CanonicalTopic, CanonicalSubtopic, CanonicalPrompt or CustomerPrompt, based on `type`.
     */
    slug: string;
    scaledAvg: number;
    'n': number;
    freqDist: Array<number>;
    teamSegment?: TeamSegment;
    segment?: SegmentValue;
};

export namespace NumericScoreWithTeamOrSegment {

    /**
     * * `PROMPT` - PROMPT
     * * `SUBTOPIC` - SUBTOPIC
     * * `TOPIC` - TOPIC
     */
    export enum type {
        PROMPT = 'PROMPT',
        SUBTOPIC = 'SUBTOPIC',
        TOPIC = 'TOPIC',
    }


}

