import React from 'react';
import { createGlobalStyle, css } from 'styled-components';

import { getDevExEnv } from 'jf/utils/getDevExEnv';

const FONT_WEIGHTS = {
  Thin: 100,
  ExtraLight: 200,
  Light: 300,
  Regular: 400,
  Medium: 500,
  SemiBold: 600,
  Bold: 700,
  ExtraBold: 800,
  Black: 900,
} as const;

type FontWeight = keyof typeof FONT_WEIGHTS;

const makeFontFace = (family: string, weight: string, italic: boolean) => {
  const baseUrl = getDevExEnv().baseUrl || '';
  const filepath = `${baseUrl}/static/fonts/${family}/${family}-${weight}${italic ? 'Italic' : ''}.ttf`;
  return css`
    @font-face {
      font-family: ${family};
      src: url(${filepath}) format('truetype');
      font-weight: ${FONT_WEIGHTS[weight]};
      font-style: ${italic ? 'italic' : 'normal'};
    }
  `;
};

// create a @font-face for every weight in a family, normal and italic
const compileFontFaces = (family: string, weights: FontWeight[]) => {
  return weights.map(
    (weight) => css`
      ${makeFontFace(family, weight, false)}
      ${makeFontFace(family, weight, true)}
    `
  );
};

type FontFaceProps = {
  name: string;
  weights: FontWeight[];
};

/**
 * Loads a series of font files for a specific font family from `/static/fonts/`.
 */
export const FontFamily: React.FC<FontFaceProps> = (props) => {
  const GlobalStyle = createGlobalStyle`
    ${compileFontFaces(props.name, props.weights)}
  `;
  return <GlobalStyle />;
};
