import React, { useState } from 'react';
import { Flex, Tooltip } from 'antd';
import { faAngleLeft, faClipboard, IconName } from '@fortawesome/pro-regular-svg-icons';
import * as regularIcons from '@fortawesome/pro-regular-svg-icons';
import { faBookSpells, faPaste } from '@fortawesome/pro-thin-svg-icons';
import * as thinIcons from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { Study, StudyClient, StudyLibraryClient, StudyTemplate } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExDivider } from 'jf/components/DevExDivider';
import { DevExLink } from 'jf/components/DevExLink';
import { SimpleTransition } from 'jf/components/SimpleTransition';
import { parseIconName } from 'jf/utils/parseIconName';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { TOPIC_HYGIENE_PANEL_EXPANDED_WIDTH, TopicHygiene } from '../editStudy/TopicHygiene';
import { StudiesTable, StudiesTableFilter } from '../studies/StudiesTable';

import { CreateStudyCard } from './CreateStudyCard';
import { useCreateStudyState } from './CreateStudyController';

type StudyTemplateMeta = {
  iconName: IconName;
  color: string;
};

export const ChooseStudyStep: React.FC = () => {
  const theme = useDevExTheme();
  const createStudyState = useCreateStudyState();

  const [creationMethod, setCreationMethod] = useState<'duplicate' | 'template'>();
  const [activeTemplate, setActiveTemplate] = useState<StudyTemplate>();
  const [showActiveTemplate, setShowActiveTemplate] = useState(false);

  const { data: studies } = useClientQuery(StudyClient.getStudies);
  const { data: studyTemplates } = useClientQuery(StudyLibraryClient.getStudyTemplates);
  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);

  const closedStudies = studies?.filter((study) => study.status === Study.status.CLOSED);

  const getStudyTemplateMeta = (key: string) => {
    const meta: { [key: string]: StudyTemplateMeta } = {
      alpha_baseline: {
        iconName: 'notes-medical',
        color: theme.color.foundations.green,
      },
      beta_voting: {
        iconName: 'wave-triangle',
        color: theme.color.foundations.blue,
      },
      beta_baseline: {
        iconName: 'notes-medical',
        color: theme.color.foundations.green,
      },
      beta_comprehensive: {
        iconName: 'book',
        color: theme.color.foundations.blue,
      },
      unknown: {
        iconName: 'question-square',
        color: theme.color.foundations.white,
      },
    };
    return meta[key] || meta.unknown;
  };

  const getTopicsFromPrompts = (template: StudyTemplate) => {
    const topics: string[] = [];

    for (const promptSlug of template.promptSlugs) {
      const matchingItem = prompts?.find((item) => item.slug === promptSlug);
      if (matchingItem) {
        const topic = matchingItem.topicSlug;
        if (topic && !topics.includes(topic)) {
          topics.push(topic);
        }
      }
    }
    return topics.length;
  };

  return (
    <Flex vertical gap={theme.variable.spacing.lg} align="center">
      <Flex style={{ alignSelf: 'stretch' }}>
        <DevExButton
          icon={<FontAwesomeIcon icon={faAngleLeft} />}
          style={!creationMethod ? { opacity: 0, pointerEvents: 'none' } : undefined}
          onClick={() => setCreationMethod(undefined)}
        >
          Back
        </DevExButton>
      </Flex>

      {!creationMethod && (
        <>
          <Flex gap={theme.variable.spacing.lg}>
            <CreateStudyCard
              icon={faBookSpells}
              title="Use a template"
              description="Choose one of our templates for a quick start and customize your survey."
              onClick={() => {
                setCreationMethod('template');
              }}
            />
            <Tooltip title={!closedStudies?.length ? "You haven't done any surveys yet." : ''}>
              <div>
                <CreateStudyCard
                  icon={faPaste}
                  title="Duplicate a past survey"
                  description="Duplicate one of your past surveys and customize it to fit your current needs."
                  onClick={() => setCreationMethod('duplicate')}
                  disabled={!closedStudies?.length}
                />
              </div>
            </Tooltip>
          </Flex>

          <DevExDivider
            style={{ color: theme.color.text.tertiary, fontSize: theme.variable.fontSize.xs }}
          >
            or
          </DevExDivider>

          <DevExButton
            onClick={() => {
              trackEvent(`survey-creator:blank:navigate`);

              createStudyState.update({
                studyToCreate: {
                  name: `New Blank Survey`,
                  sequencedPrompts: [],
                  flags: ['voting_enabled'],
                },
                stepIndex: createStudyState.stepIndex + 1,
              });
            }}
          >
            <Flex
              style={{
                fontSize: theme.variable.fontSize.xs,
                color: theme.color.text.tertiary,
              }}
            >
              <FontAwesomeIcon icon={faClipboard} style={{ marginRight: '8px' }} />
              Start from a blank survey
            </Flex>
          </DevExButton>
          <div
            style={{
              fontSize: theme.variable.fontSize.xs,
              color: theme.color.text.tertiary,
            }}
          >
            You can still browse our template questions for inspiration.
          </div>
        </>
      )}

      {creationMethod === 'template' && (
        <>
          <div style={{ fontSize: theme.variable.fontSize.lg }}>
            Select the template you'd like to use
          </div>

          {studyTemplates?.map((template) => {
            const active = showActiveTemplate && activeTemplate?.name === template.name;

            return (
              <Flex
                key={template.name}
                vertical
                align="end"
                style={{ width: '100%' }}
                gap={theme.variable.spacing.sm}
              >
                <DevExCard
                  style={{ width: '100%' }}
                  onClick={() => {
                    trackEvent('survey-creator:templates:clone', {
                      slug: template.slug,
                      name: template.name,
                      description: template.description,
                    });

                    createStudyState.update({
                      studyToCreate: {
                        name: `New ${template.name} Survey`,
                        sequencedPrompts: template.promptSlugs,
                        flags: template.flags,
                      },
                      stepIndex: createStudyState.stepIndex + 1,
                    });
                  }}
                >
                  <Flex align="center">
                    <FontAwesomeIcon
                      icon={thinIcons[parseIconName(getStudyTemplateMeta(template.slug).iconName)]}
                      style={{ marginRight: theme.variable.spacing.md }}
                      size="3x"
                    />
                    <Flex vertical gap={theme.variable.spacing.md}>
                      <div style={{ fontSize: theme.variable.fontSize.md, fontWeight: 'bold' }}>
                        {template.name}
                      </div>
                      <div>{template.description}</div>
                      <div
                        style={{ color: theme.color.text.tertiary }}
                      >{`${template.promptSlugs.length} questions | ${getTopicsFromPrompts(template)} topics`}</div>
                    </Flex>
                  </Flex>
                </DevExCard>

                <DevExLink
                  onClick={() => {
                    if (active) {
                      setShowActiveTemplate(false);
                    } else {
                      setActiveTemplate(template);
                      setShowActiveTemplate(true);
                    }
                  }}
                >
                  {active ? 'Hide details' : 'Show details'}
                </DevExLink>
              </Flex>
            );
          })}

          <div
            css={css`
              position: fixed;
              right: 0;
              bottom: 0;
            `}
          >
            <SimpleTransition
              name="template-hygiene"
              timeout={300}
              in={showActiveTemplate}
              cssInit={css`
                transform: translateX(0);
              `}
              cssExit={css`
                transform: translateX(${`${TOPIC_HYGIENE_PANEL_EXPANDED_WIDTH}px`});
              `}
            >
              <TopicHygiene
                icon={
                  activeTemplate && (
                    <FontAwesomeIcon
                      icon={
                        regularIcons[
                          parseIconName(getStudyTemplateMeta(activeTemplate.slug).iconName)
                        ]
                      }
                    />
                  )
                }
                title={activeTemplate?.name}
                promptSlugs={activeTemplate?.promptSlugs ?? []}
                css={css`
                  height: calc(100vh - 123px);
                `}
              />
            </SimpleTransition>
          </div>
        </>
      )}

      {creationMethod === 'duplicate' && (
        <>
          <div style={{ fontSize: theme.variable.fontSize.lg }}>
            Select the past survey you'd like to use
          </div>
          <Flex vertical gap={theme.variable.spacing.md} style={{ width: '100%' }}>
            <StudiesTable
              filter={StudiesTableFilter.CLOSED}
              onRowClick={(study) => {
                trackEvent('survey-creator:past-surveys:clone', {
                  ref: study.ref,
                  name: study.name,
                  closeDate: study.closeDate,
                });

                createStudyState.update({
                  studyToCreate: {
                    name: `Duplicate of ${study.name}`,
                    welcomeMessage: study.welcomeMessage,
                    sequencedPrompts: study.prompts.map((prompt) => prompt.slug),
                    flags: study.flags,
                    sequenceRef: study.sequenceRef,
                  },
                  // create a new StudySequence if cloning a study that is not already in one
                  studySequenceToCreate: !study.sequenceRef
                    ? { name: `${study.name} Series`, studyRefs: [study.ref] }
                    : undefined,
                  stepIndex: createStudyState.stepIndex + 1,
                });
              }}
              studies={studies || []}
              columns={['name', 'status', 'last_updated', 'created_by']}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
};
