/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Flex } from 'antd';
import { DateTime, Duration } from 'luxon';
import { useNavigate } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { AuthClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExCheckbox } from 'jf/components/DevExCheckbox';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExLink } from 'jf/components/DevExLink';
import { useClientMutationError } from 'jf/utils/useClientMutationError';
import { useClientMutation } from 'jf/utils/useClientQuery';

import { AuthFormContext, authStyles, EMAIL_PLACEHOLDER } from './AuthPage';

const EMAIL_DELAY_MS = 120_000;

export const CreateAccountForm: React.FC = () => {
  const navigate = useNavigate();
  const theme = useDevExTheme();
  const { email, setEmail } = useContext(AuthFormContext);

  const [agreesToTos, setAgreesToTos] = useState(false);
  const [emailSentAt, setEmailSentAt] = useState<DateTime>();
  const [emailDelayMs, setEmailDelayMs] = useState(0);

  const createAccount = useClientMutation(AuthClient.createAccount);
  const createAccountError = useClientMutationError();

  const validated = !!(email && agreesToTos);

  const onCreateAccount = () => {
    trackEvent('free-inbound:account:create');

    createAccount
      .mutateAsync({
        requestBody: { email, agreesToTos },
      })
      .then(() => {
        setEmailSentAt(DateTime.now());
        setEmailDelayMs(0);
      })
      .catch(createAccountError.handle);
  };

  // reset error if email is changed
  useEffect(() => {
    if (!createAccount.isIdle) {
      createAccountError.reset();
    }
  }, [email]);

  useEffect(() => {
    // create Interval to update emailDelayMs
    const interval = setInterval(() => {
      if (emailSentAt) {
        setEmailDelayMs((emailDelayMs) => {
          if (emailDelayMs < 0) {
            clearInterval(interval);
          }
          return EMAIL_DELAY_MS - DateTime.now().diff(emailSentAt).milliseconds;
        });
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [emailSentAt]);

  return (
    <DevExCard css={authStyles.form}>
      <Flex vertical gap={theme.variable.spacing.sm} className="card__header">
        <div className="header__title">Create your account</div>
        <div>It's free — no contract required.</div>
      </Flex>

      <Flex vertical gap={theme.variable.spacing.lg}>
        {emailSentAt ? (
          <div className="card__message">
            An email has been sent to <em>{email}</em> to finish creating your account.
          </div>
        ) : (
          <Flex vertical gap={theme.variable.spacing.md}>
            <Flex vertical gap={theme.variable.spacing.sm}>
              <label>Email</label>
              <DevExInput
                placeholder={EMAIL_PLACEHOLDER}
                type="email"
                value={email}
                onChange={setEmail}
              />

              {!!createAccountError.message && (
                <div className="card__message message--error">{createAccountError.message}</div>
              )}
            </Flex>

            <Flex align="center" gap={theme.variable.spacing.sm}>
              <DevExCheckbox checked={agreesToTos} onChange={setAgreesToTos} />
              <div style={{ fontSize: theme.variable.fontSize.xs }}>
                I agree to the{' '}
                <DevExLink href="/terms" target="_blank">
                  Terms & Conditions
                </DevExLink>
                .
              </div>
            </Flex>
          </Flex>
        )}

        <Flex vertical gap={theme.variable.spacing.sm}>
          <DevExButton
            type="primary"
            onClick={onCreateAccount}
            disabled={!validated || emailDelayMs > 0}
            loading={createAccount.isLoading}
          >
            {emailSentAt
              ? `Resend email${emailDelayMs > 0 ? ` • ${Duration.fromMillis(emailDelayMs).toFormat('m:ss')}` : ''}`
              : 'Create account'}
          </DevExButton>
        </Flex>

        <div className="card__footer">
          Already have an account?{' '}
          <DevExLink onClick={() => navigate('/auth/sign-in')}>Sign in</DevExLink>
        </div>
      </Flex>
    </DevExCard>
  );
};
