import React from 'react';

import { getDataAttributes } from 'jf/utils/data';

import { renderWithFeatureFlags } from './renderWithFeatureFlags';

import '../entry/tracing.js';
import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';

/**
 * Renders a React app from an entrypoint file.
 * @param Component React component
 * @param id id of a div in the spa template to render inside
 */
export const renderReactApp = async (Component: React.FC, id: string) => {
  const element = document.getElementById(id);

  if (element) {
    const props = getDataAttributes(element, 'camelCase');
    const ComponentWithProps = () => <Component {...props} />;

    renderWithFeatureFlags(ComponentWithProps, element);
  }
};
