import React from 'react';
import { Helmet } from 'react-helmet-async';

type DevExPageTitleProps = {
  children: string;
};

export const DevExPageTitle: React.FC<DevExPageTitleProps> = (props) => (
  <Helmet>
    <title>{props.children}</title>
  </Helmet>
);
