/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BenchmarkCohort } from '../models/BenchmarkCohort';
import type { BenchmarkNumericScore } from '../models/BenchmarkNumericScore';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BenchmarkClient {

    /**
     * @returns BenchmarkNumericScore
     * @throws ApiError
     */
    public static getBenchmarkCohortScores({
        slug,
        type,
    }: {
        slug: string,
        /**
         * One param per score type (PROMPT, SUBTOPIC, TOPIC) to fetch.  Can fetch multiple e.g. type=PROMPT&type=TOPIC.  Defaults to type=PROMPT if unspecified.
         */
        type?: Array<'PROMPT' | 'SUBTOPIC' | 'TOPIC'>,
    }): CancelablePromise<Array<BenchmarkNumericScore>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/benchmark/cohort/{slug}/scores',
            path: {
                'slug': slug,
            },
            query: {
                'type': type,
            },
        });
    }

    /**
     * @returns BenchmarkCohort
     * @throws ApiError
     */
    public static getBenchmarkCohorts(): CancelablePromise<Array<BenchmarkCohort>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/benchmark/cohorts',
        });
    }

}
