import { desaturate, lighten } from 'polished';

import { useDevExTheme } from 'jf/common/DevExThemeContext';

// destaurate and lighten color by amount
const dl = (amount: number, color: string) => desaturate(amount / 2, lighten(amount, color));

const getScoreColorIndex = (score: number) => {
  const chunks = [15, 25, 35, 45, 55, 65, 75, 85, 100];
  for (let i = 0; i < chunks.length; i++) {
    if (score <= chunks[i]) {
      return i;
    }
  }
  return 4;
};

/**
 * Get discrete colors for the 0 - 100 score range.
 */
export const useScoreColors = () => {
  const theme = useDevExTheme();

  const negative = theme.color.visualization.polarity.negative.high.background;
  const neutral = theme.color.visualization.polarity.neutral.background;
  const positive = theme.color.visualization.polarity.positive.high.background;

  const scoreColors = [
    negative,
    dl(0.12, negative),
    dl(0.24, negative),
    dl(0.34, negative),
    neutral,
    dl(0.34, positive),
    dl(0.24, positive),
    dl(0.12, positive),
    positive,
  ];

  const colorByScore = (score: number) => {
    if (score < 0) {
      return theme.color.visualization.polarity.neutral.background;
    }
    return scoreColors[getScoreColorIndex(score)];
  };

  return { scoreColors, colorByScore };
};
