/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { StudyClient } from 'jf/api';
import { startFullstory } from 'jf/common/DevEx';
import { JFOverlayController } from 'jf/common/useJFOverlay';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { EditStudyPage } from './EditStudyPage';
import { EditStudyStateProvider, useEditStudyState } from './EditStudyStateContext';

export const EditStudyController: React.FC = () => {
  return (
    <EditStudyStateProvider>
      <JFOverlayController>
        <EditStudyPageController />
      </JFOverlayController>
    </EditStudyStateProvider>
  );
};

const EditStudyPageController: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => startFullstory('survey-editor'), []);

  const { studyRef } = useParams<{ studyRef: string }>();

  const { data: study, error: studyError } = useClientQuery(StudyClient.getStudy, { studyRef });

  const editStudyState = useEditStudyState();

  useEffect(() => {
    if (study && !editStudyState.loaded) {
      // populate editStudyState from study
      editStudyState.update(
        {
          name: study.name,
          notes: study.notes,
          welcomeMessage: study.welcomeMessage,
          promptSlugs: study.prompts.map((prompt) => prompt.slug),
          flags: study.flags,
        },
        true
      );
    }
  }, [study, editStudyState.loaded]);

  useEffect(() => {
    if (studyError?.status === 404) {
      navigate(
        `/error?code=404&message=Sorry, we couldn't find the survey you were looking for. It may have been deleted.`
      );
    }
  }, [studyError]);

  return <EditStudyPage />;
};
