/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthToken } from '../models/AuthToken';
import type { CreatedAuthToken } from '../models/CreatedAuthToken';
import type { DevExUser } from '../models/DevExUser';
import type { EmailAddressRequest } from '../models/EmailAddressRequest';
import type { LoginRequest } from '../models/LoginRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthClient {

    /**
     * Create a long-lasting API auth token.
     * @returns CreatedAuthToken
     * @throws ApiError
     */
    public static createApiAuthToken(): CancelablePromise<CreatedAuthToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/api/token',
        });
    }

    /**
     * List API auth tokens that have been created.
     * @returns AuthToken
     * @throws ApiError
     */
    public static getApiAuthTokens(): CancelablePromise<Array<AuthToken>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/api/tokens',
        });
    }

    /**
     * Create a new inactive user given an email address.
     * @returns any No response body
     * @throws ApiError
     */
    public static createAccount({
        requestBody,
    }: {
        requestBody: EmailAddressRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/create-account',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Authenticates a non-emp user and activates an account if needed.
     * @returns any No response body
     * @throws ApiError
     */
    public static authEmailVerifyToken({
        token,
    }: {
        token: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/email/verify/{token}',
            path: {
                'token': token,
            },
        });
    }

    /**
     * @returns DevExUser
     * @throws ApiError
     */
    public static login({
        requestBody,
    }: {
        requestBody: LoginRequest,
    }): CancelablePromise<DevExUser> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static logout(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/logout/',
        });
    }

}
