import React from 'react';
import { Flex, Tooltip } from 'antd';
import { faLineChart } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { StudyClient, StudySequenceClient } from 'jf/api';
import { DevExHeader } from 'jf/common/DevExHeader';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExLottie } from 'jf/components/DevExLottie';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import SavedCloud from 'jf/devex/lottie/SavedCloud.json';
import SavingCloud from 'jf/devex/lottie/SavingCloud.json';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { SharePreviewButton } from '../study/SharePreviewButton';

import { useEditStudyState } from './EditStudyStateContext';
import { PublishStudyModal } from './PublishStudyModal';

const styles = {
  header: css`
    .header__name {
      .ant-input {
        font-size: inherit;
        font-family: inherit;
      }
    }

    .header__name {
      .ant-input {
        border-radius: ${(props) => props.theme.variable.borderRadius};
        height: auto;
        line-height: inherit;

        &:not(:hover):not(:focus):not(.input--error) {
          border-color: transparent;
        }
      }
    }
  `,
};

export const EditStudyHeader: React.FC = () => {
  const { studyRef } = useParams<{ studyRef: string }>();
  const navigate = useNavigate();

  const theme = useDevExTheme();

  const editStudyState = useEditStudyState();
  const publishStudyModal = useJFOverlay(PublishStudyModal);

  const actionsDisabled =
    !editStudyState.saved || !editStudyState.loaded || !editStudyState.promptSlugs.length;

  const onPublish = () => {
    trackEvent('survey-editor:publish:start', { surveyRef: studyRef });
    publishStudyModal.open();
  };

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });

  const { data: sequence } = useClientQuery(StudySequenceClient.getStudySequence, {
    sequenceRef: study?.sequenceRef,
  });

  // name is a requirement to saving
  const saving = !editStudyState.saved && !!editStudyState.name;

  return (
    <DevExHeader
      left={
        <Flex gap={theme.variable.spacing.md}>
          <DevExButton
            size="large"
            type="outline"
            onClick={() => {
              if (saving) {
                window.alert('Please wait while your survey is being saved.');
              } else {
                navigate('/studies');
              }
            }}
          >
            Back to surveys
          </DevExButton>

          {editStudyState.loaded && (
            <Flex
              gap={theme.variable.spacing.xs}
              style={{ color: theme.color.text.secondary }}
              align="center"
            >
              <DevExLottie
                key={!saving ? 'saved' : 'saving'}
                data={!saving ? SavedCloud : SavingCloud}
                loop={saving}
                colors={[theme.color.card.background, theme.color.text.secondary]}
                style={{ height: 24 }}
              />
              {!saving ? 'Saved' : 'Saving'}
            </Flex>
          )}
        </Flex>
      }
      right={
        <Flex vertical justify="space-between" align="end" gap={theme.variable.spacing.lg}>
          <Flex gap={theme.variable.spacing.md}>
            <Tooltip title="Share a preview link of your draft to get feedback.">
              <div>
                <SharePreviewButton>Share</SharePreviewButton>
              </div>
            </Tooltip>
            <Tooltip title="Publish your survey when you’re ready.">
              <DevExButton
                size="large"
                type="outline"
                disabled={actionsDisabled}
                onClick={onPublish}
              >
                Publish
              </DevExButton>
            </Tooltip>
          </Flex>
        </Flex>
      }
    >
      <Flex css={styles.header} gap={theme.variable.spacing.xs} vertical>
        <div className="header__name">
          {editStudyState.loaded ? (
            <DevExInput
              value={editStudyState.name}
              onChange={(value) => editStudyState.update({ name: value })}
              placeholder="Add name"
              error={!editStudyState.name}
              autosize
              style={{ minWidth: 240 }}
            />
          ) : (
            <DevExSkeleton width={320} height={39} fontSize="xl" />
          )}
        </div>
        <div>
          {editStudyState.loaded ? (
            sequence ? (
              <DevExButton
                icon={<FontAwesomeIcon icon={faLineChart} />}
                type="text"
                style={{ paddingInline: theme.variable.spacing.sm }}
                onClick={() => navigate(`/series/${sequence.ref}/`)}
              >
                {sequence.name}
              </DevExButton>
            ) : null
          ) : (
            <DevExSkeleton width={100} height={30} fontSize="sm" />
          )}
        </div>
      </Flex>
    </DevExHeader>
  );
};
