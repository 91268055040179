import React, { useMemo } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { CustomComponent } from 'antd/es/_util/type';
import { DateTime } from 'luxon';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { Study } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExMenu } from 'jf/components/DevExMenu';
import { DevExPopover } from 'jf/components/DevExPopover';
import { DevExTable, DevExTableColumn } from 'jf/components/DevExTable';

import { DeleteStudyModal } from './DeleteStudyModal';
import { RenameStudyModal } from './RenameStudyModal';
import { StudyStatusTag } from './StudyStatusTag';

export const enum StudiesTableFilter {
  ALL = 'ALL',
  USER = 'USER',
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export const STUDIES_TABLE_FILTER_OPTIONS = [
  {
    key: StudiesTableFilter.ALL,
    label: 'All',
  },
  {
    key: StudiesTableFilter.USER,
    label: 'Created by me',
  },
  {
    key: StudiesTableFilter.DRAFT,
    label: 'Draft',
  },
  {
    key: StudiesTableFilter.OPEN,
    label: 'Open',
  },
  {
    key: StudiesTableFilter.CLOSED,
    label: 'Closed',
  },
];

type StudiesTableColumn = 'name' | 'created_by' | 'last_updated' | 'status' | 'actions';

type StudiesTableProps = {
  filter: StudiesTableFilter;
  onRowClick?: (study: Study) => void;
  studies: Study[];
  columns?: StudiesTableColumn[];
};

export const StudiesTable: React.FC<StudiesTableProps> = (props) => {
  const theme = useDevExTheme();
  const renameStudyModal = useJFOverlay(RenameStudyModal);
  const deleteStudyModal = useJFOverlay(DeleteStudyModal);
  const { studies } = props;

  const filteredStudies = useMemo(() => {
    switch (props.filter) {
      case StudiesTableFilter.USER:
        return studies?.filter((study) => study.createdBy?.ref === window.dx.user?.ref);
      case StudiesTableFilter.DRAFT:
        return studies?.filter((study) => study.status === 'DRAFT');
      case StudiesTableFilter.OPEN:
        return studies?.filter((study) => study.status === 'OPEN');
      case StudiesTableFilter.CLOSED:
        return studies?.filter((study) => study.status === 'CLOSED');
      case StudiesTableFilter.ALL:
      default:
        return studies;
    }
  }, [props.filter, studies]);

  const columnNames = props.columns;
  const defaultColumns: DevExTableColumn<Study>[] = [
    {
      key: 'name',
      title: 'Name',
      render: (study) => (
        <div
          title={study.name}
          style={{
            fontSize: theme.variable.fontSize.md,
            maxWidth: '360px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: 600,
          }}
        >
          {study.name}
        </div>
      ),
      width: '240px',
    },
    {
      key: 'status',
      title: 'Status',
      render: (study) => <StudyStatusTag status={study.status} />,
      width: '80px',
    },
    {
      key: 'last_updated',
      title: 'Last updated',
      render: (study) => DateTime.fromISO(study.lastUpdated).toLocaleString(DateTime.DATE_MED),
      width: '80px',
    },
    {
      key: 'created_by',
      title: 'Created by',
      render: (study) => (
        <div
          css={css`
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {study.createdBy?.name}
        </div>
      ),
      width: '160px',
    },
    {
      key: 'actions',
      title: '',
      align: 'right',
      render: (study) => (
        <DevExPopover
          trigger={['click']}
          content={
            <DevExMenu
              items={[
                {
                  key: 'rename',
                  label: 'Rename',
                  onClick: () => {
                    renameStudyModal.open({
                      studyName: study.name,
                      studyRef: study.ref,
                    });
                    trackEvent('survey-list:survey-actions:start-rename', { surveyRef: study.ref });
                  },
                },
                {
                  type: 'divider',
                },
                {
                  key: 'delete',
                  label: 'Delete',
                  danger: true,
                  onClick: () => {
                    deleteStudyModal.open({
                      studyRef: study.ref,
                      studyName: study.name,
                    });
                    trackEvent('survey-list:survey-actions:start-delete', { surveyRef: study.ref });
                  },
                },
              ]}
              selectable={false}
            />
          }
        >
          <DevExButton
            icon={<EllipsisOutlined />}
            onClick={(event) => {
              event.stopPropagation();
              trackEvent('survey-list:survey-actions:open', { surveyRef: study.ref });
            }}
            type="text"
          />
        </DevExPopover>
      ),
      width: '32px',
    },
  ];

  const columns = columnNames
    ? defaultColumns.filter((column) => columnNames.includes(column.key! as StudiesTableColumn))
    : defaultColumns;

  const StudyTableRow: CustomComponent = (rowProps) => {
    const ref = rowProps['data-row-key'];
    const index = filteredStudies!.findIndex((study) => study.ref === ref);
    const study = filteredStudies![index];
    return (
      <>
        {
          // use empty row to add gaps between actual rows
          index > 0 && <tr style={{ height: theme.variable.spacing.md }} />
        }
        <tr
          onClick={() => props.onRowClick?.(study)}
          // make row look like a DevExCard
          css={css`
            background-color: ${(props) => props.theme.color.card.background};
            box-shadow: 0 0 0 1px ${(props) => props.theme.color.card.border};
            border-radius: ${(props) => props.theme.variable.borderRadius};
            padding: ${(props) => props.theme.variable.spacing.sm};
            cursor: pointer;

            &:not(:last-child) {
              padding-bottom: ${(props) => props.theme.variable.spacing.sm};
            }

            td {
              padding: ${(props) => props.theme.variable.spacing.md} !important;
              background-color: transparent !important;
            }

            &:hover,
            &:focus {
              box-shadow: 0 0 0 2px ${(props) => props.theme.color.accent.primary};
              outline: none;
            }
          `}
          tabIndex={0}
        >
          {rowProps.children}
        </tr>
      </>
    );
  };

  return (
    <DevExTable
      columns={columns}
      dataSource={filteredStudies}
      rowKey="ref"
      components={{ body: { row: StudyTableRow } }}
      locale={{
        emptyText: (
          <DevExEmpty iconName="clipboard" label="No surveys match your current filter." />
        ),
      }}
      css={css`
        th {
          padding-inline: 16px !important;
        }
      `}
    />
  );
};
