/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { faCheck, faQuestion, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { UserClient } from 'jf/api';
import { refreshOpenAPIHeaders } from 'jf/common/DevEx';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExInput } from 'jf/components/DevExInput';
import { useClientMutationError } from 'jf/utils/useClientMutationError';
import { useClientMutation } from 'jf/utils/useClientQuery';

import { authStyles } from './AuthPage';

type PasswordValidator = {
  label: string;
  validate?: (password: string) => boolean;
};

const PASSWORD_VALIDATORS: PasswordValidator[] = [
  {
    label: 'Is at least 8 characters',
    validate: (password) => password.length >= 8,
  },
  {
    label: 'Includes at least one letter',
    validate: (password) => /[a-zA-Z]/.test(password),
  },
  {
    label: 'Does not contain email handle',
  },
  {
    label: 'Is not too common',
  },
];

export const SetPasswordForm: React.FC = () => {
  const theme = useDevExTheme();
  const navigate = useNavigate();

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [hasBlurred, setHasBlurred] = useState(false);

  const updateUserPassword = useClientMutation(UserClient.updateUserPassword);
  const updateUserPasswordError = useClientMutationError();

  const validated = !!(password1 && password2);

  const onSetPassword = () => {
    trackEvent('free-inbound:password:create');

    updateUserPassword
      .mutateAsync({
        requestBody: {
          newPassword1: password1,
          newPassword2: password2,
        },
      })
      .then(() => {
        // Direct the user into the app once their password has been set
        if (window.dx.user) {
          window.dx.user = { ...window.dx.user, isSetup: true };
        }
        refreshOpenAPIHeaders();
        navigate('/');
      })
      .catch(updateUserPasswordError.handle);
  };

  // reset error if password1/password2 is changed
  useEffect(() => {
    if (!updateUserPassword.isIdle) {
      updateUserPasswordError.reset();
    }
  }, [password1, password2]);

  return (
    <DevExCard css={authStyles.form}>
      <Flex vertical gap={theme.variable.spacing.xs} className="card__header">
        <div className="header__title">Set password</div>
      </Flex>

      <Flex vertical gap={theme.variable.spacing.sm}>
        {/* Hidden email field so password managers like 1P recognize username */}
        <DevExInput
          type="email"
          placeholder="Email"
          value={window.dx.user?.email ?? ''}
          style={{ display: 'none' }}
        />

        <DevExInput
          type="password"
          placeholder="Password"
          value={password1}
          onChange={setPassword1}
          onBlur={() => setHasBlurred(true)}
        />

        <DevExInput
          type="password"
          placeholder="Confirm password"
          value={password2}
          onChange={setPassword2}
          onKeyUp={(event) => event.key === 'Enter' && onSetPassword()}
        />

        {!!updateUserPasswordError.message && (
          <div className="card__message message--error">{updateUserPasswordError.message}</div>
        )}
      </Flex>

      <Flex vertical gap={theme.variable.spacing.sm}>
        {PASSWORD_VALIDATORS.map((validator, i) => {
          let color = theme.color.text.secondary;
          let icon = faQuestion;
          if (hasBlurred && validator.validate) {
            const validated = validator.validate(password1);
            color = validated ? theme.color.status.success.text : theme.color.status.error.text;
            icon = validated ? faCheck : faXmark;
          }
          return (
            <Flex key={i} align="center" gap={theme.variable.spacing.sm} style={{ color }}>
              <FontAwesomeIcon icon={icon} style={{ width: theme.variable.fontSize.sm }} />
              <div>{validator.label}</div>
            </Flex>
          );
        })}
      </Flex>

      <DevExButton
        type="primary"
        onClick={onSetPassword}
        disabled={!validated}
        loading={updateUserPassword.isLoading}
      >
        Set password
      </DevExButton>
    </DevExCard>
  );
};
