import React from 'react';
import { TooltipProps } from 'antd';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CopyLinkPopover } from 'jf/components/CopyLinkPopover';
import { DevExButton } from 'jf/components/DevExButton';

type SharePreviewButtonProps = {
  popupPlacement?: TooltipProps['placement'];
};

export const SharePreviewButton: React.FC<SharePreviewButtonProps> = (props) => {
  return (
    <CopyLinkPopover
      title="Share Preview Link"
      description="Anyone with this link will be able to access the same preview and respond to the
study but all responses will be ignored."
      link={window.location.href}
      placement={props.popupPlacement}
    >
      <DevExButton type="outline" size="large" icon={<FontAwesomeIcon icon={faLink} />}>
        {props.children ? props.children : 'Share preview'}
      </DevExButton>
    </CopyLinkPopover>
  );
};
