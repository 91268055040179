import { useState } from 'react';

import { ApiError } from 'jf/api';

/**
 * Provides a handler that will parse a `useClientMutation` error into a human-readable message.
 */
export const useClientMutationError = () => {
  const [message, setMessage] = useState<string>();

  // parse error message
  const handle = (error: ApiError) => {
    const body = error?.body;

    setMessage(() => {
      if (!body) {
        return undefined;
      }

      try {
        // try to parse ValidationError that will look like
        // { email: ['Error message.'] } or { detail: 'Error message.' }
        const value = Object.values(body)[0];
        return Array.isArray(value) ? value[0] : value;
      } catch {}

      return JSON.stringify(body);
    });
  };

  const reset = () => setMessage(undefined);

  return { message, handle, reset };
};
