import React, { useState } from 'react';

import { Prompt, PublicPrompt } from 'jf/api';

import { LikertScale } from './LikertScale';
import { QuestionCard, QuestionCardProps } from './QuestionCard';

interface QuestionCardPromptProps extends Omit<QuestionCardProps, 'onBack' | 'onNext'> {
  number: number;
  onBack?: (score?: number) => void;
  onNext?: (score?: number) => void;
  prompt: PublicPrompt | Prompt;
  tag?: string;
  initialScore?: number;
}

export const QuestionCardPrompt: React.FC<QuestionCardPromptProps> = (props) => {
  const { prompt, ...cardProps } = props;

  const [selectedScore, setSelectedScore] = useState<number | undefined>(props.initialScore);

  const isLikert = prompt.type === Prompt.type.RATING_5L || prompt.type === Prompt.type.RATING_5N;

  return (
    <QuestionCard
      title={props.prompt.text}
      subtitle={<span>Question {props.number}</span>}
      tag={props.tag}
      validated={!!selectedScore}
      auto
      {...cardProps}
      onBack={props.onBack && (() => props.onBack?.(selectedScore))}
      onNext={props.onNext && (() => props.onNext?.(selectedScore))}
    >
      {isLikert && (
        <LikertScale
          value={selectedScore}
          onChange={setSelectedScore}
          polarity={prompt.polarity}
          numeric={prompt.type === Prompt.type.RATING_5N}
          disabled={props.disabled}
        />
      )}
    </QuestionCard>
  );
};
