import React, { useLayoutEffect, useRef, useState } from 'react';

type CenteredStickyProps = {
  className?: string;
};

export const CenteredSticky: React.FC<CenteredStickyProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, []);

  return (
    <div
      ref={ref}
      className={props.className}
      style={{ position: 'sticky', top: `calc(50vh - ${Math.round(height / 2)}px)` }}
    >
      {props.children}
    </div>
  );
};
