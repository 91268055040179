import React from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import ReactDOM from 'react-dom';

import { getDevExEnv } from './getDevExEnv';

export const renderWithFeatureFlags = (Component: React.FC, element: Element | null) => {
  const env = getDevExEnv();

  let context: any = undefined;

  const user = window.dx.user;
  if (user) {
    let safeName = 'JF User';

    if (user.name) {
      const [firstName, lastName] = user.name.split(' ');
      safeName = `${firstName} ${lastName ? `${lastName[0]}.` : ''}`;
    }

    context = {
      kind: 'user',
      key: user.ref,
      name: `${safeName} (${user.company.slug})`,
      company: user.company.slug,
      is_jf_employee: user.email?.endsWith('@jellyfish.co'),
    };
  }

  const LDProvider = withLDProvider({
    clientSideID: env.launchDarklyId,
    context: context,
    options: { disableSyncEventPost: true },
  })(Component);

  ReactDOM.render(<LDProvider />, element);
};
