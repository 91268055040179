import React from 'react';
import { Flex } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';

type ErrorPageProps = {
  code?: number;
  message?: string;
};

export const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const theme = useDevExTheme();

  const code = props.code ?? parseInt(searchParams.get('code') ?? '500');
  const message = props.message ?? searchParams.get('message') ?? 'An unexpected error occurred.';

  return (
    <Flex
      vertical
      align="center"
      justify="center"
      style={{ flex: 1 }}
      gap={theme.variable.spacing.lg}
    >
      <div
        style={{
          fontSize: theme.variable.fontSize.xxl,
          fontWeight: 600,
          fontFamily: theme.variable.fontFamily.secondary,
        }}
      >
        {code}
      </div>
      <div style={{ color: theme.color.text.secondary }}>{message}</div>
      {code === 404 && (
        <DevExButton type="outline" onClick={() => navigate('/')}>
          Go home
        </DevExButton>
      )}
    </Flex>
  );
};
