import React, { useState } from 'react';
import { Button, ButtonProps } from 'antd';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import clipboardCopy from 'clipboard-copy';
import { css } from 'styled-components';

import { useDevExTheme } from '../common/DevExThemeContext';

const styles = {
  button: css`
    &&&.ant-btn {
      border: none;
      border-radius: ${(props) => props.theme.variable.borderRadius};
      transition:
        all 150ms ease,
        padding 0s;
      outline: none !important;
      box-shadow: none;
      display: inline-flex;
      align-items: center;
      gap: 6px;
      line-height: 1;
      height: 32px;
      background: none;
      color: ${(props) => props.theme.color.text.secondary};
      padding-block: 0;

      &:disabled,
      &.ant-btn-disabled,
      &.button--loading {
        cursor: default;
        opacity: 0.6;
        pointer-events: none;
      }

      &:hover,
      &:focus,
      &.button--active {
        background-color: ${(props) => props.theme.color.button.primary.aura};
        color: ${(props) => props.theme.color.text.primary};
      }

      &.button--large {
        border-radius: 20px;
        font-size: ${(props) => props.theme.variable.fontSize.md};
        height: 40px;
      }

      &.button--primary {
        background-color: ${(props) => props.theme.color.button.primary.background};
        color: ${(props) => props.theme.color.button.primary.text};

        &:hover,
        &:focus,
        &.button--active {
          background-color: ${(props) => props.theme.color.button.primary.background};
          color: ${(props) => props.theme.color.button.primary.text};
        }
      }

      &.button--outline {
        :after {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          box-shadow: 0 0 0 1px ${(props) => props.theme.color.text.tertiary};
        }
      }

      &.button--dashed {
        :after {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          outline: 1px dashed ${(props) => props.theme.color.text.tertiary};
          outline-offset: -2px;
        }
      }

      &.button--text {
        :after {
          content: none;
        }
      }

      :after {
        content: '';
        position: absolute;
        border-radius: ${(props) => parseInt(props.theme.variable.borderRadius) * 1.2}px;
        transition: all 150ms ease;
        top: 2px;
        left: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
      }

      &.button--large:after {
        border-radius: ${20 * 1.2}px;
      }

      &:hover,
      &:focus,
      &.button--active {
        :after {
          top: -2px;
          left: -2px;
          width: calc(100% + 4px);
          height: calc(100% + 4px);
          box-shadow: 0 0 0 2px ${(props) => props.theme.color.accent.primary};
        }
      }

      .button__icon {
        display: flex;
        justify-content: center;
        font-size: ${(props) => props.theme.variable.fontSize.md};
        min-width: ${(props) => props.theme.variable.fontSize.md};
        width: ${(props) => props.theme.variable.fontSize.md};

        svg {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .button__text {
        overflow: hidden;
        padding-block: 2px;
      }

      &.button--shiny {
        color: ${(props) => props.theme.color.text.primary};
        border: 2px solid #0000;
        background:
          linear-gradient(
              ${(props) => props.theme.color.background.primary},
              ${(props) => props.theme.color.background.primary}
            )
            padding-box,
          linear-gradient(
              var(--angle),
              ${(props) => props.theme.color.foundations.purple},
              ${(props) => props.theme.color.foundations.purple},
              ${(props) => props.theme.color.foundations.yellow}
            )
            border-box;
        animation: 1.5s rotate ease-in-out infinite; // this looks best but it's not really ideal. could be tweaked in the future
        &:not(:hover):after {
          box-shadow: none;
        }
      }

      @keyframes rotate {
        to {
          --angle: 360deg;
        }
      }

      @property --angle {
        syntax: '<angle>';
        initial-value: 0deg;
        inherits: false;
      }
    }
  `,
};

interface DevExButtonProps extends Omit<ButtonProps, 'size' | 'type'> {
  size?: 'default' | 'large';
  type?: 'default' | 'primary' | 'outline' | 'text' | 'dashed';
  copyValue?: string | (() => Promise<string>);
  iconPosition?: 'left' | 'right';
  active?: boolean;
  shiny?: boolean;
}

export const DevExButton: React.FC<DevExButtonProps> = (props) => {
  let {
    icon,
    iconPosition = 'left',
    type,
    size,
    copyValue,
    active,
    shiny,
    ...passthroughProps
  } = props;

  const theme = useDevExTheme();

  const [copying, setCopying] = useState(false);
  const [copyCooldown, setCopyCooldown] = useState(false);

  const iconOnly = !!icon && !props.children;
  const iconOrder = iconPosition === 'left' ? 0 : 2;

  const className = classNames(props.className, {
    'button--primary': type === 'primary',
    'button--outline': type === 'outline',
    'button--dashed': type === 'dashed',
    'button--text': type === 'text',
    'button--large': size === 'large',
    'button--active': active,
    'button--shiny': shiny,
    'button--loading': props.loading,
  });

  let text = props.children;

  // change to successful "Copied" state for cooldown period
  if (copyCooldown) {
    icon = <FontAwesomeIcon icon={faCheck} />;
    text = 'Copied';
  }

  const paddingFactor = size === 'large' ? 1.5 : 1;
  const paddingInline = parseInt(theme.variable.spacing[iconOnly ? 'sm' : 'md']) * paddingFactor;

  return (
    <Button
      css={styles.button}
      {...passthroughProps}
      style={{
        paddingInline,
        ...props.style,
      }}
      className={className}
      onClick={async (event) => {
        props.onClick?.(event);

        if (copyValue) {
          let cv: string = '';

          if (typeof copyValue === 'string') {
            cv = copyValue;
          } else if (typeof copyValue === 'function') {
            setCopying(true);
            cv = await copyValue();
            setCopying(false);
          }

          clipboardCopy(cv);
          setCopyCooldown(true);
          setTimeout(() => setCopyCooldown(false), 3000);
        }
      }}
      disabled={props.disabled || copyCooldown}
      loading={props.loading || copying}
    >
      {!!icon && (
        <div
          className={'button__icon'}
          style={{
            order: iconOrder,
            margin: iconOnly ? -1 : 0, // icon is 18px but size it like its 16px -- this ensures square icon buttons
          }}
        >
          {icon}
        </div>
      )}
      {!!text && <div className={'button__text'}>{text}</div>}
    </Button>
  );
};
