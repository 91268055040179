import React, { useContext } from 'react';
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { trackEvent } from 'jf/analytics/Analytics';
import { JFOverlayController, useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExSelect } from 'jf/components/DevExSelect';
import { useLivePropState } from 'jf/utils/useLivePropState';
import { usePageStorage } from 'jf/utils/usePageStorage';

import { BenchmarkModal } from './BenchmarkModal';

export type BenchmarkPercentile = 'p90' | 'p75' | 'p50' | 'p25' | 'p10';

type _BenchmarkContext = {
  percentile?: BenchmarkPercentile;
  setPercentile: (percentile?: BenchmarkPercentile) => void;
};

const BenchmarkContext = React.createContext<_BenchmarkContext>({
  setPercentile: () => {},
});

export const useBenchmark = () => useContext(BenchmarkContext);

type BenchmarkSelectProps = {
  value: BenchmarkPercentile | undefined;
  onChange: (benchmark: BenchmarkPercentile | undefined) => void;
};

export const BenchmarkSelect: React.FC<BenchmarkSelectProps> = (props) => {
  const [benchmarkPercentile, setBenchmarkPercentile] = useLivePropState(
    props.value,
    props.onChange
  );

  // track benchmark percentile selection in LocalStorage and URL
  usePageStorage(
    { benchmark: [benchmarkPercentile, setBenchmarkPercentile] },
    'devex_benchmark_percentile'
  );

  const context: _BenchmarkContext = {
    percentile: benchmarkPercentile,
    setPercentile: setBenchmarkPercentile,
  };

  return (
    <BenchmarkContext.Provider value={context}>
      <JFOverlayController>
        <InternalBenchmarkSelect />
      </JFOverlayController>
    </BenchmarkContext.Provider>
  );
};

const InternalBenchmarkSelect: React.FC = () => {
  const benchmarkModal = useJFOverlay(BenchmarkModal);
  const benchmark = useBenchmark();

  return (
    <div
      onClick={() => {
        trackEvent('survey-analyzer:benchmarks:open');
        benchmarkModal.open();
      }}
      css={{ cursor: 'pointer', width: 'fit-content' }}
    >
      <DevExSelect
        placeholder={
          <>
            <FontAwesomeIcon icon={faBullseyeArrow} />
            Benchmark
          </>
        }
        keepPlaceholder
        allowClear
        value={benchmark.percentile}
        onChange={benchmark.setPercentile}
        style={{ pointerEvents: 'none' }}
        options={[
          {
            value: 'p90',
            label: 'All Companies / 90th Percentile',
          },
          {
            value: 'p75',
            label: 'All Companies / 75th Percentile',
          },
          {
            value: 'p50',
            label: 'All Companies / 50th Percentile',
          },
          {
            value: 'p25',
            label: 'All Companies / 25th Percentile',
          },
          {
            value: 'p10',
            label: 'All Companies / 10th Percentile',
          },
        ]}
      />
    </div>
  );
};
