import React from 'react';
import { Badge, BadgeProps } from 'antd';
import classNames from 'classnames';
import { css } from 'styled-components';

const styles = {
  badge: css`
    &.ant-badge {
      sup.ant-badge-count {
        color: ${(props) => props.theme.color.text.tertiary};
        background-color: ${(props) => props.theme.color.text.aura};
        box-shadow: none;
      }

      &.badge--active sup.ant-badge-count {
        color: ${(props) => props.theme.color.text.primary};
      }
    }
  `,
};

interface DevExBadgeProps extends BadgeProps {
  active?: boolean;
}

export const DevExBadge: React.FC<DevExBadgeProps> = (props) => {
  const { active, ...passthroughProps } = props;

  const className = classNames({
    'badge--active': !!active,
  });

  return <Badge css={styles.badge} className={className} showZero {...passthroughProps} />;
};
