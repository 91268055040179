import React, { useEffect, useRef, useState } from 'react';
import { InputRef } from 'antd';
import { useQueryClient } from 'react-query';

import { trackEvent } from 'jf/analytics/Analytics';
import { StudyClient } from 'jf/api';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExModal } from 'jf/components/DevExModal';
import { useClientMutation } from 'jf/utils/useClientQuery';

type RenameStudyModalProps = {
  studyName: string;
  studyRef: string;
};

export const RenameStudyModal: React.FC<RenameStudyModalProps> = (props) => {
  const modal = useJFOverlay(RenameStudyModal);
  const queryClient = useQueryClient();

  const inputRef = useRef<InputRef>(null);
  const [input, setInput] = useState(props.studyName);

  const { mutateAsync: updateStudy, isLoading: isUpdating } = useClientMutation(
    StudyClient.updateStudy
  );

  const onCancel = () => {
    if (!isUpdating) {
      modal.close();
    }
  };

  const onOk = () => {
    trackEvent('survey-list:survey-actions:rename', {
      surveyRef: props.studyRef,
      oldName: props.studyName,
      newName: input,
    });
    updateStudy({
      studyRef: props.studyRef,
      requestBody: {
        name: input,
      },
    }).then(() => {
      queryClient.invalidateQueries('GET_STUDIES');
      queryClient.removeQueries(['GET_STUDY', props.studyRef]);
      modal.close(true);
    });
  };

  // reset name after reopening modal
  useEffect(() => {
    if (modal.isOpened) {
      setInput(props.studyName);
      setTimeout(() => inputRef.current?.select(), 0);
    }
  }, [modal.isOpened, props.studyName]);

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={onCancel}
      onOk={onOk}
      title="Rename this survey"
      footer={
        <>
          <DevExButton onClick={onCancel} disabled={isUpdating}>
            Cancel
          </DevExButton>
          <DevExButton
            type="primary"
            onClick={onOk}
            loading={isUpdating}
            disabled={input === props.studyName}
          >
            Save
          </DevExButton>
        </>
      }
    >
      <DevExInput ref={inputRef} value={input} onChange={setInput} placeholder="Survey name" />
    </DevExModal>
  );
};
