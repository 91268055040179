import { useMemo } from 'react';
import { groupBy, mapValues, uniqBy } from 'lodash-es';

import { StudyClient } from 'jf/api';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyScoresBySegment } from './useStudyScoresBySegment';

type UseStudySegmentsProps = {
  studyRef: string | undefined;
  teamRef?: string;
};

/**
 * Compile the available segments for a study based on the fetched segment scores.
 * @returns An array of segments, including a `key`, `name`, and its `options` (each containining a `value` and `label`).
 * @example
 * [
 *   {
 *     key: 'teamSegment:Team',
 *     name: 'Team,
 *     options: [{ value: '019a8f86-1021-463e-bf93-12b53751c683', label: 'Bakunawa' }, ...]
 *   },
 *   {
 *   key: 'segment:Location',
 *   name: 'Location,
 *   options: [{ value: 'e3267648-166a-475a-a9e1-a33b34901a0c', label: 'Boston' }, ...]
 *   },
 *   ...
 *  ]
 */
export const useStudySegments = (props: UseStudySegmentsProps) => {
  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef: props.studyRef });
  const { data: scoresBySegment } = useStudyScoresBySegment({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: ['TOPIC'],
  });

  const studySegmentOptions = useMemo(() => {
    if (!study || !scoresBySegment) {
      return undefined;
    }

    // group scores by segmentKey based off of segment.name or teamSegment.name
    // this will naturally ignore teams when filtering by teamRef (since all scores will have a segment)
    const scoresBySegmentKey = groupBy(
      scoresBySegment?.map((score) => ({
        ...score,
        segmentKey: score.segment
          ? `segment:${score.segment.name}`
          : `teamSegment:${score.teamSegment!.name}`,
      })),
      'segmentKey'
    );

    // reduce scores to unique options with a value and label
    const segmentOptionsBySegmentKey = mapValues(scoresBySegmentKey, (scores) =>
      uniqBy(scores, (score) => score.segment?.ref ?? score.teamSegment!.ref).map((score) => ({
        value: score.segment?.ref ?? score.teamSegment!.ref,
        label: score.segment?.value ?? score.teamSegment!.value,
      }))
    );

    let studySegmentOptions = Object.entries(segmentOptionsBySegmentKey).map(
      ([segmentKey, segmentOptions]) => ({
        key: segmentKey,
        name: segmentKey.split(':')[1],
        options: segmentOptions,
      })
    );

    const findSegmentIndex = (key: string) =>
      study.analysisSegments.findIndex((analysisSegment) => analysisSegment.key === key);

    // use study.analysisSegments to determine what segments we show and in what order
    return studySegmentOptions
      .filter((segment) =>
        study.analysisSegments.some(
          (analysisSegment) => analysisSegment.key === segment.key && analysisSegment.visible
        )
      )
      .sort((a, b) => findSegmentIndex(a.key) - findSegmentIndex(b.key));
  }, [scoresBySegment, study]);

  return studySegmentOptions;
};
