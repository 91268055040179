import React from 'react';
import { Flex } from 'antd';
import {
  faArrowRightLong,
  faArrowTrendDown,
  faArrowTrendUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/DevExThemeContext';

type TrendCellProps = {
  trend?: number;
};
const styles = {
  cellStyles: css`
    .cell__value {
      color: ${({ theme }) => theme.color.text.primary};
    }
  `,
};

export const TrendCell: React.FC<TrendCellProps> = ({ trend }) => {
  const theme = useDevExTheme();

  if (trend === undefined) {
    return (
      <Flex justify="center" css={styles.cellStyles}>
        &mdash;
      </Flex>
    );
  }
  return (
    <Flex justify="center" css={styles.cellStyles} gap={theme.variable.spacing.xs}>
      <span className="cell__value">
        {trend === 0 || trend < 0 ? '' : '+'}
        {trend}
      </span>
      <div>
        {trend === 0 && (
          <FontAwesomeIcon
            icon={faArrowRightLong}
            color={theme.color.visualization.polarity.neutral.text}
          />
        )}
        {trend > 0 && (
          <FontAwesomeIcon
            icon={faArrowTrendUp}
            color={theme.color.visualization.polarity.positive.high.text}
          />
        )}
        {trend < 0 && (
          <FontAwesomeIcon
            icon={faArrowTrendDown}
            color={theme.color.visualization.polarity.negative.high.text}
          />
        )}
      </div>
    </Flex>
  );
};
