import React from 'react';
import { FullStory, init } from '@fullstory/browser';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
// Required so we can use the css prop in JSX across our app
// The empty import will be compiled out
// https://styled-components.com/docs/api#usage-with-typescript
import type {} from 'styled-components/cssprop';

import { FontFamily } from 'jf/components/FontFamily';
import { AuthAccessPage } from 'jf/pages/auth/AuthAccessPage';
import { AuthPage } from 'jf/pages/auth/AuthPage';
import { CreateAccountForm } from 'jf/pages/auth/CreateAccountForm';
import { OnboardingPage } from 'jf/pages/auth/OnboardingPage';
import { ResetPasswordForm } from 'jf/pages/auth/ResetPasswordForm';
import { SetPasswordForm } from 'jf/pages/auth/SetPasswordForm';
import { SignInForm } from 'jf/pages/auth/SignInForm';
import { TermsPage } from 'jf/pages/auth/TermsPage';
import { ErrorPage } from 'jf/pages/error/ErrorPage';
import { StudySequencePage } from 'jf/pages/studySequence/StudySequencePage';
import { DevExEnv, getDevExEnv } from 'jf/utils/getDevExEnv';
import { queryClient } from 'jf/utils/queryClient';
// import types for what we set globally
import type {} from 'jf/utils/WindowTypes';

import { OpenAPI } from '../api';
import { ColorConfigPage } from '../pages/admin/ColorConfigPage';
import { CreateStudyController } from '../pages/createStudy/CreateStudyController';
import { EditStudyController } from '../pages/editStudy/EditStudyController';
import { StudiesController } from '../pages/studies/StudiesController';
import { StudyController } from '../pages/study/StudyController';
import { InsightsTab } from '../pages/studyAnalyzer/insights/InsightsTab';
import { ParticipationTab } from '../pages/studyAnalyzer/participation/ParticipationTab';
import { ResultsTab } from '../pages/studyAnalyzer/results/ResultsTab';
import { StudyAnalyzerPage } from '../pages/studyAnalyzer/StudyAnalyzerPage';
import { StudyRevealController } from '../pages/studyAnalyzer/studyReveal/studyReveal';
import { getCookie } from '../utils/getCookie';

import { DevExAntConfigProvider } from './DevExAntConfigProvider';
import { DevExErrorBoundary } from './DevExErrorBoundary';
import { DevExThemeProvider } from './DevExThemeContext';
import { DevExWrapper } from './DevExWrapper';

export const refreshOpenAPIHeaders = () => {
  const headers: NonNullable<typeof OpenAPI.HEADERS> = {};

  // send latest CSRFToken
  headers['X-CSRFToken'] = getCookie('devex_csrftoken')!;

  // send scope_token for authorization if needed
  const queryParams = new URLSearchParams(window.location.search);
  const scopeToken = queryParams.get('scope_token');
  if (!window.dx.user && scopeToken) {
    headers.Authorization = `ScopeToken ${scopeToken}`;
  }

  OpenAPI.HEADERS = headers;
};

refreshOpenAPIHeaders();

export const startFullstory = (page: string) => {
  // use in useEffect
  if (window.dx.user && window.dx.user.email && !window.dx.user.email.includes('jellyfish.co')) {
    init({
      orgId: 'FPF15',
      devMode: getDevExEnv().key === DevExEnv.DEV,
    });

    FullStory('setIdentity', {
      uid: `${window.dx.user.id}`,
      properties: {
        email: window.dx.user.email,
        company: window.dx.user.company.slug,
        page,
        isEmp: window.dx.user.company.isEmp,
      },
    });
  }
};

const ROOBERT = <FontFamily name="Roobert" weights={['Light', 'Regular', 'Medium', 'SemiBold']} />;

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="" ErrorBoundary={DevExErrorBoundary}>
      {/* REQUIRE AUTH + NAV */}
      <Route path="/" element={<DevExWrapper reqAuth showNav />}>
        <Route index element={<StudiesController />} />
        <Route path={'studies/'} element={<StudiesController />} />
        <Route path={'series/:sequenceRef/'} element={<StudySequencePage />} />
        <Route path={'study/:studyRef/analyze/'} element={<StudyAnalyzerPage />}>
          <Route index path="insights/" element={<InsightsTab />} />
          <Route path="questions/" element={<ResultsTab />} />
          <Route path="participation/" element={<ParticipationTab />} />
          <Route path=":teamRef/insights" element={<InsightsTab />} />
          <Route path=":teamRef/questions" element={<ResultsTab />} />
        </Route>
      </Route>

      {/* REQUIRE AUTH + NO NAV */}
      <Route path="/" element={<DevExWrapper reqAuth />}>
        <Route path="study/:studyRef/reveal" element={<StudyRevealController />} />
        <Route path={'study/create/'} element={<CreateStudyController />} />
        <Route path={'study/:studyRef/edit/'} element={<EditStudyController />} />
        <Route path={'admin2/colors'} element={<ColorConfigPage />} />
        <Route path={'auth/'} element={<AuthPage />}>
          <Route path={'set-password'} element={<SetPasswordForm />} />
        </Route>
        <Route path={'auth/onboarding'} element={<OnboardingPage />} />
      </Route>

      {/* OPTIONAL AUTH + NO NAV */}
      <Route path="/" element={<DevExWrapper />}>
        <Route path={'study/:studyRef'} element={<StudyController />} />
        <Route path="error" element={<ErrorPage />} />
        <Route path="terms" element={<TermsPage />} />
        <Route
          path="*"
          element={
            <ErrorPage
              code={404}
              message="Sorry, we couldn't find the page you were looking for."
            />
          }
        />
      </Route>

      {/* REQUIRE NO AUTH */}
      <Route path="/" element={<DevExWrapper reqNoAuth />}>
        <Route path={'auth/'} element={<AuthPage />}>
          <Route path={'sign-in'} element={<SignInForm />} />
          <Route path={'create-account'} element={<CreateAccountForm />} />
          <Route path={'reset-password'} element={<ResetPasswordForm />} />
        </Route>
        <Route path={'auth/access'} element={<AuthAccessPage />} />
      </Route>
    </Route>
  )
);

export const DevEx: React.FC = () => {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <DevExThemeProvider>
          <DevExAntConfigProvider>
            {ROOBERT}
            <RouterProvider router={router} />
          </DevExAntConfigProvider>
        </DevExThemeProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};
