import React, { useState } from 'react';
import { Flex } from 'antd';
import { faShieldHalved } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { PublicStudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExInput } from 'jf/components/DevExInput';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyState } from './StudyStateContext';
import { StudyTitle } from './StudyTitle';

const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,16}$/;

const styles = css`
  .anonymous-info {
    width: 100%;
    align-items: center;
    padding: ${({ theme }) => theme.variable.spacing.md};
    color: ${({ theme }) => theme.color.text.secondary};

    .anonymous-text {
      padding: ${({ theme }) => theme.variable.spacing.sm} 0px;
    }
    p {
      color: ${({ theme }) => theme.color.text.tertiary};
      line-height: ${({ theme }) => theme.variable.lineHeight};
      width: min(450px, 100%);
    }
  }
`;
export const StudyStart: React.FC = () => {
  const theme = useDevExTheme();

  const studyState = useStudyState();
  const { data: study } = useClientQuery(PublicStudyClient.getPublicStudy, {
    studyRef: studyState.refs.studyRef,
  });

  const [searchParams] = useSearchParams();
  const message = searchParams.get('message') ?? '';

  const [email, setEmail] = useState('');
  // email will be used in StudyController to get/create a StudyResponse and progress the stageIndex as needed
  const onStart = () => {
    trackEvent('survey-taker:email:start', {
      surveyRef: studyState.refs.studyRef,
      email,
    });
    studyState.update({ email });
  };

  const validated = EMAIL_REGEX.test(email);

  return (
    <Flex vertical align="center" gap={48} css={styles}>
      <StudyTitle>
        You've been invited by{' '}
        <span style={{ color: theme.color.accent.secondary }}>{study?.companyName}</span> to take a{' '}
        <span style={{ color: theme.color.accent.secondary }}>DevEx</span> survey!
      </StudyTitle>
      <Flex wrap="wrap" gap="middle" justify="center" style={{ width: '100%' }}>
        <Flex vertical style={{ width: 'min(450px, 100%)' }} gap={theme.variable.spacing.sm}>
          <DevExInput
            size="large"
            addonBefore="Email"
            placeholder="Enter your work email address"
            value={email}
            onChange={setEmail}
            onKeyUp={(event) => event.key === 'Enter' && validated && onStart()}
          />
          {!!message && (
            <div
              style={{
                lineHeight: theme.variable.lineHeight,
                color: theme.color.status.error.text,
              }}
            >
              {message}
            </div>
          )}
        </Flex>
        <DevExButton
          type="primary"
          size="large"
          disabled={!validated}
          onClick={onStart}
          loading={!!studyState.email}
        >
          Start survey
        </DevExButton>
      </Flex>
      <Flex vertical className="anonymous-info">
        <Flex align="center" gap={theme.variable.spacing.sm}>
          <FontAwesomeIcon icon={faShieldHalved} />
          <span className="anonymous-text">Your responses are anonymous</span>
        </Flex>
        <p>
          We use your email address to map your responses to the correct groups like your team or
          location, and we don't report results by group unless that group contains at least 3
          people.
        </p>
      </Flex>
    </Flex>
  );
};
