import React from 'react';
import { useParams } from 'react-router-dom';

import { StudyClient } from 'jf/api';
import { DevExPageLayout } from 'jf/common/DevExPageLayout';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { ParticipationBySegmentCard } from './ParticipationBySegmentCard';
import { ParticipationCard } from './ParticipationCard';

export const ParticipationTab: React.FC = () => {
  const { studyRef } = useParams<{ studyRef: string }>();

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });

  return (
    <DevExPageLayout>
      <ParticipationCard studyRef={studyRef} />
      {study && <ParticipationBySegmentCard studyRef={studyRef} />}
    </DevExPageLayout>
  );
};
