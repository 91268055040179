import React, { useRef, useState } from 'react';
import { Flex } from 'antd';
import { faArrowRight, faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { StudyClient } from 'jf/api';
import { RAW_THEMES, resolveTheme, useDevExTheme } from 'jf/common/DevExThemeContext';
import { CopyLinkPopover } from 'jf/components/CopyLinkPopover';
import { useClientMutation } from 'jf/utils/useClientQuery';

import { SHARE_LINK_EXPIRY_DAYS } from '../StudyAnalyzerPage';

import { usePageIndexTracker } from './studyReveal';

import JFLogo from 'jf/images/jf-logo.svg';

export const RevealEnd: React.FC = () => {
  const theme = useDevExTheme();
  const navigate = useNavigate();
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef: string }>();
  const targetRef = useRef(null);

  const [shareLink, setShareLink] = useState('');
  const { mutateAsync: shareStudy } = useClientMutation(StudyClient.shareStudy);

  let overrideTheme = structuredClone(RAW_THEMES.dark);
  overrideTheme = resolveTheme(overrideTheme);
  usePageIndexTracker(targetRef, 'reveal-end');

  return (
    <Flex
      style={{
        backgroundColor: theme.color.reveal.dark.background,
        width: '100vw',
        height: '101vh',
        fontSize: '64px',
        fontFamily: theme.variable.fontFamily.secondary,
        color: theme.color.reveal.accent,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      ref={targetRef}
      gap={theme.variable.spacing.xxl}
    >
      {window.dx.user?.isCustomerAdmin ? (
        <>
          <ThemeProvider theme={overrideTheme}>
            <CopyLinkPopover
              title="Share Reveal"
              description={`The link above will grant public access to this reveal to whomever you share it with. It will expire in ${SHARE_LINK_EXPIRY_DAYS} days.`}
              link={shareLink}
              placement="bottomRight"
              theme={overrideTheme}
              onCopy={() => trackEvent('survey-reveal:copy-link:click')}
            >
              <Flex
                style={{
                  cursor: 'pointer',
                }}
                gap={theme.variable.spacing.lg}
                onClick={() => {
                  trackEvent('survey-reveal:share:click');
                  if (studyRef) {
                    shareStudy({
                      studyRef,
                      requestBody: {
                        teamRef,
                        expiryDays: SHARE_LINK_EXPIRY_DAYS,
                      },
                    }).then((response) => {
                      const queryParams = new URLSearchParams(window.location.search);
                      queryParams.append('scope_token', response.scopeToken);
                      setShareLink(
                        `${window.location.origin}${window.location.pathname}?${queryParams}`
                      );
                    });
                  }
                }}
              >
                Share
                <FontAwesomeIcon icon={faLink} />
              </Flex>
            </CopyLinkPopover>
          </ThemeProvider>
          <Flex
            style={{
              cursor: 'pointer',
            }}
            gap={theme.variable.spacing.lg}
            onClick={() => {
              trackEvent('survey-reveal:explore:click');
              navigate(`/study/${studyRef}/analyze/insights`);
            }}
          >
            Explore
            <FontAwesomeIcon icon={faArrowRight} />
          </Flex>
        </>
      ) : (
        <Flex align="center" justify="center">
          {/* jf logo */}
          <JFLogo
            height="64px"
            color={theme.color.reveal.accent}
            style={{ paddingInlineEnd: theme.variable.spacing.sm }}
          />
          <div
            style={{
              fontSize: '64px',
              fontFamily: theme.variable.fontFamily.secondary,
              gap: theme.variable.spacing.lg,
            }}
          >
            <span style={{ fontWeight: 'bold', color: theme.color.reveal.dark.text }}>DevEx</span>
          </div>
        </Flex>
      )}
    </Flex>
  );
};
