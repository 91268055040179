import React, { ReactNode, useEffect, useState } from 'react';
import { Flex } from 'antd';
import classNames from 'classnames';
import { delay } from 'lodash-es';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExTag } from 'jf/components/DevExTag';

const styles = {
  questionCard: css`
    border-left: 4px solid ${(props) => props.theme.color.accent.secondary};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100%;
    padding: ${(props) => props.theme.variable.spacing.xl};

    .questionCard__body {
      gap: ${(props) => props.theme.variable.spacing.lg};
    }

    &.questionCard--disabled {
      cursor: not-allowed;
      user-select: none;
    }

    .questionCard__subtitle {
      color: ${(props) => props.theme.color.text.tertiary};
      font-size: ${(props) => props.theme.variable.fontSize.md};
      padding-bottom: ${(props) => props.theme.variable.spacing.md};
      width: 100%;

      span {
        font-weight: 600;
        color: ${(props) => props.theme.color.accent.secondary};
      }
    }

    .questionCard__title {
      font-size: max(
        ${(props) => props.theme.variable.fontSize.md},
        min(${(props) => props.theme.variable.fontSize.lg}, 4vw)
      );
      line-height: ${(props) => props.theme.variable.lineHeight};

      em {
        color: ${(props) => props.theme.color.accent.secondary};
      }
    }

    .questionCard__description {
      font-size: ${(props) => props.theme.variable.fontSize.sm};
      color: ${(props) => props.theme.color.text.secondary};
      line-height: ${(props) => props.theme.variable.lineHeight};
      padding-top: ${(props) => props.theme.variable.spacing.xs};
    }
  `,
};

export type QuestionCardProps = {
  actions?: ReactNode;
  onBack?: () => void;
  onNext?: () => void;
  nextLabel?: string;
  disabled?: boolean;
  loading?: boolean;
  subtitle?: ReactNode;
  tag?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  validated?: boolean;
  auto?: boolean; // automatically progress when validated
};

export const QuestionCard: React.FC<QuestionCardProps> = (props) => {
  const theme = useDevExTheme();

  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => setFirstRender(false), []);

  useEffect(() => {
    if (!firstRender && props.auto && props.validated) {
      delay(() => props.onNext?.(), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.validated, props.auto]);

  const className = classNames({
    'questionCard--disabled': props.disabled,
  });

  return (
    <DevExCard css={styles.questionCard} className={className}>
      <Flex vertical>
        <Flex justify="space-between" align="baseline">
          <div className={'questionCard__subtitle'}>{props.subtitle}</div>
          {!!props.tag &&
            (typeof props.tag === 'string' ? <DevExTag>{props.tag}</DevExTag> : props.tag)}
        </Flex>
        {!!props.title && <div className={'questionCard__title'}>{props.title}</div>}
        {!!props.description && (
          <div className={'questionCard__description'}>{props.description}</div>
        )}
      </Flex>

      <div style={{ pointerEvents: props.disabled ? 'none' : 'auto' }}>{props.children}</div>
      <Flex justify="flex-end" gap={theme.variable.spacing.sm}>
        {props.actions}
        {props.onBack && (
          <DevExButton onClick={() => props.onBack?.()} disabled={props.disabled || props.loading}>
            Back
          </DevExButton>
        )}
        {props.onNext && (
          <DevExButton
            onClick={() => props.onNext?.()}
            type="primary"
            disabled={props.disabled || !props.validated}
            loading={props.loading}
          >
            {props.nextLabel ?? 'Next'}
          </DevExButton>
        )}
      </Flex>
    </DevExCard>
  );
};
