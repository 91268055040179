// tracing.js
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { Resource } from '@opentelemetry/resources';
import {
  BatchSpanProcessor,
  StackContextManager,
  WebTracerProvider,
} from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';

import { getEnv } from 'jf/utils/getEnv';

const ENV = getEnv(); // prd, stg demo, etc
const dataset = `jellyfish-fe-${ENV}`;

// The exporter is responsible for sending traces from the browser to your collector
const exporter = new OTLPTraceExporter({
  url: 'https://api.honeycomb.io/v1/traces',
  headers: {
    'x-honeycomb-team': process.env.HONEYCOMB_FRONTEND_KEY,
    'x-honeycomb-dataset': dataset,
  },
});

// The TracerProvider is the core library for creating traces
const provider = new WebTracerProvider({
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: 'browser',
  }),
});
// The processor sorts through data as it comes in, before it is sent to the exporter
provider.addSpanProcessor(new BatchSpanProcessor(exporter));

// We cannot use the ZoneContextManager, so we're falling back to this one
provider.register({
  contextManager: new StackContextManager(),
});

// Note: auto-instrumenting creates far too many spans for us to use
//    - it grabs every static asset, vendor lib (e.g. pendo, LD)
//    - every URL in the app that gets hit
//    - every click
// We'll have to experiment on what we want to actually trace
registerInstrumentations({
  instrumentations: [],
});
