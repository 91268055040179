import React, { useEffect } from 'react';
import { Flex } from 'antd';
import {
  faExclamationCircle,
  faListRadio,
  faPlay,
  faPlus,
  faPoll,
} from '@fortawesome/pro-regular-svg-icons';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keyBy } from 'lodash-es';
import { useNavigate, useParams } from 'react-router-dom';
import { Container as DragContainer, Draggable } from 'react-smooth-dnd';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { Prompt, RosterClient, StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExDivider } from 'jf/components/DevExDivider';
import { DevExMenuItem } from 'jf/components/DevExMenuItem';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { DevExTag } from 'jf/components/DevExTag';
import { arrayOf } from 'jf/utils/arrayOf';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { CATCH_ALL_TEAM_REF } from '../study/question/QuestionCardDemographic';
import { VERBATIMS_PROMPT_TEXT } from '../study/question/QuestionCardVerbatims';

import { DEFAULT_TEAM_DEMOGRAPHIC_TEXT, DEFAULT_WELCOME_MESSAGE } from './EditStudyPage';
import { useEditStudyState, useStudyEditorSteps } from './EditStudyStateContext';
import { PromptIcon } from './PromptIcon';
import { PromptsModal } from './PromptsModal';

const styles = {
  sidebar: css`
    width: 400px;
    border-right: 1px solid ${(props) => props.theme.color.card.border};

    .sidebar__panel {
      padding: ${(props) => props.theme.variable.spacing.sm};
      display: flex;
      flex-direction: column;
      gap: ${(props) => props.theme.variable.spacing.xs};

      &.panel--questions {
        flex: 1 1 0;
        overflow: auto;
        padding-top: 0;

        .panel__entries {
          overflow: auto;
          padding-top: 8px;

          .smooth-dnd-container {
            flex-shrink: 0;
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.color.card.border};
      }

      .panel__heading {
        padding: ${(props) => props.theme.variable.spacing.sm};
        padding-bottom: ${(props) => props.theme.variable.spacing.xs};
        font-weight: 600;
        font-size: ${(props) => props.theme.variable.fontSize.md};
      }
    }
  `,
  promptPanelEntry: css`
    cursor: grab;

    .promptPanelEntry__dragIcon {
      padding-inline: ${(props) => props.theme.variable.spacing.xs};
    }

    &:not(:hover) {
      .promptPanelEntry__dragIcon {
        display: none;
      }
    }

    &:hover {
      .promptPanelEntry__promptIcon {
        display: none;
      }
    }
  `,
};

const PanelEntry: React.FC<{
  index?: number;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
}> = (props) => {
  const editStudyState = useEditStudyState();

  const id = `study-step-${props.index}`;

  // scroll entry into view
  useEffect(() => {
    if (props.index === editStudyState.stepIndex) {
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [props.index, id, editStudyState.stepIndex]);

  return (
    <DevExMenuItem
      id={id}
      active={props.index === editStudyState.stepIndex}
      onClick={() => editStudyState.update({ stepIndex: props.index })}
      style={{ pointerEvents: props.disabled ? 'none' : undefined, ...props.style }}
      className={props.className}
    >
      {props.children}
    </DevExMenuItem>
  );
};

const PromptPanelEntry: React.FC<{
  index?: number;
  prompt: Prompt;
}> = (props) => {
  const theme = useDevExTheme();

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const topic = topics?.find((topic) => topic.slug === props.prompt.topicSlug);

  return (
    <PanelEntry index={props.index} css={styles.promptPanelEntry}>
      <Flex gap={theme.variable.spacing.sm} align="center">
        <PromptIcon
          type={props.prompt.type}
          style={{ fontSize: 18 }}
          className="promptPanelEntry__promptIcon"
        />
        <FontAwesomeIcon
          icon={faGripDotsVertical}
          style={{ fontSize: 20 }}
          className="promptPanelEntry__dragIcon"
        />
        <span>{props.prompt.text}</span>
      </Flex>

      {topic && <DevExTag style={{ flexShrink: 0 }}>{topic.label}</DevExTag>}
    </PanelEntry>
  );
};

export const EditStudySidebar: React.FC = () => {
  const { studyRef } = useParams<{ studyRef: string }>();
  const navigate = useNavigate();

  const theme = useDevExTheme();
  const editStudyState = useEditStudyState();
  const promptsModal = useJFOverlay(PromptsModal);

  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);
  const { data: teams } = useClientQuery(RosterClient.getTeams);

  const promptsBySlug = keyBy(prompts, 'slug');

  const studySteps = useStudyEditorSteps();

  const numberedStepStartIndex = studySteps.findIndex((step) => step.numbered);
  const numberedSteps = studySteps.slice(numberedStepStartIndex);

  const movePrompt = (oldIndex: number, newIndex: number) => {
    const slug = editStudyState.promptSlugs.splice(oldIndex, 1)[0]; // remove slug at oldIndex
    editStudyState.promptSlugs.splice(newIndex, 0, slug); // insert slug at newIndex

    // update stepIndex to the prompt being moved
    const stepIndex = newIndex + numberedStepStartIndex;

    editStudyState.update({ promptSlugs: [...editStudyState.promptSlugs], stepIndex });
  };

  const selectableTeams = teams?.filter((team) => team.isLeaf && !team.isHidden);

  // show warning when company has not configured any teams (ignore catch all team)
  const showTeamConfigWarning =
    selectableTeams?.filter((team) => team.ref !== CATCH_ALL_TEAM_REF).length === 0;

  const onPreview = () => {
    trackEvent('survey-editor:preview:start', { surveyRef: studyRef });
    navigate(`/study/${studyRef}`);
  };

  const actionsDisabled =
    !editStudyState.saved || !editStudyState.loaded || !editStudyState.promptSlugs.length;

  return (
    <Flex vertical css={styles.sidebar}>
      <div className="sidebar__panel">
        <Flex justify="space-between" style={{ fontSize: 'inherit' }}>
          <DevExButton
            icon={<FontAwesomeIcon icon={faPlus} />}
            type="outline"
            onClick={() => promptsModal.open()}
            disabled={!editStudyState.loaded}
          >
            Add question
          </DevExButton>

          <DevExButton
            icon={<FontAwesomeIcon icon={faPlay} />}
            disabled={actionsDisabled}
            onClick={onPreview}
          >
            Preview
          </DevExButton>
        </Flex>
      </div>

      <div className="sidebar__panel">
        {editStudyState.loaded ? (
          <PanelEntry index={0}>
            <span>{editStudyState.welcomeMessage || DEFAULT_WELCOME_MESSAGE}</span>
          </PanelEntry>
        ) : (
          <PanelEntry disabled>
            <DevExSkeleton width={['100%', '90%']} fontSize="sm" />
          </PanelEntry>
        )}
      </div>

      <div className="sidebar__panel">
        {editStudyState.loaded ? (
          <PanelEntry
            index={1}
            style={{
              background: showTeamConfigWarning ? theme.color.status.warning.background : undefined,
            }}
          >
            <Flex gap={theme.variable.spacing.sm} align="center">
              <FontAwesomeIcon icon={faListRadio} />
              <span>{DEFAULT_TEAM_DEMOGRAPHIC_TEXT}</span>
              {showTeamConfigWarning && (
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  color={theme.color.status.warning.text}
                  fontSize={18}
                />
              )}
            </Flex>
          </PanelEntry>
        ) : (
          <PanelEntry disabled>
            <DevExSkeleton width={'50%'} fontSize="sm" />
          </PanelEntry>
        )}
      </div>

      <div className="sidebar__panel panel--questions">
        <div className="panel__entries">
          {editStudyState.loaded && !!prompts ? (
            <DragContainer
              onDrop={(result) => {
                if (result.removedIndex != null && result.addedIndex != null) {
                  movePrompt(result.removedIndex, result.addedIndex);
                }
              }}
            >
              {numberedSteps
                .filter((step) => step.type === 'PROMPT')
                .map((step) => {
                  const promptSlug = editStudyState.promptSlugs[step.typeIndex!];
                  const prompt = promptsBySlug[promptSlug];
                  return (
                    prompt && (
                      <Draggable key={promptSlug}>
                        <PromptPanelEntry
                          index={step.typeIndex! + numberedStepStartIndex}
                          prompt={prompt}
                        />
                      </Draggable>
                    )
                  );
                })}
            </DragContainer>
          ) : (
            arrayOf(6).map((i) => (
              <PanelEntry key={i} disabled>
                <DevExSkeleton width={['100%', '70%']} fontSize="sm" />
              </PanelEntry>
            ))
          )}

          {studySteps.find((step) => step.type === 'VOTING') && (
            <>
              <DevExDivider
                style={{ margin: theme.variable.spacing.sm, minWidth: 0, width: 'auto' }}
              />
              <PanelEntry index={studySteps.length - 1}>
                <Flex gap={theme.variable.spacing.sm} align="center">
                  <FontAwesomeIcon icon={faPoll} style={{ fontSize: 18 }} />
                  <span>{VERBATIMS_PROMPT_TEXT}</span>
                </Flex>
              </PanelEntry>
            </>
          )}
        </div>
      </div>
    </Flex>
  );
};
