import React from 'react';
import {
  faAlignLeft,
  faList,
  faSmile,
  faTachometerAltFast,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Prompt } from 'jf/api';

const PROMPT_TYPE_ICONS: { [key in Prompt.type]: IconDefinition } = {
  RATING_5L: faSmile,
  RATING_5N: faTachometerAltFast,
  OPEN_END: faAlignLeft,
  MULTI_SEL: faList,
  SINGLE_SEL: faList,
};

type PromptIconProps = {
  type: Prompt.type;
  style?: React.CSSProperties;
  className?: string;
};

export const PromptIcon: React.FC<PromptIconProps> = (props) => (
  <FontAwesomeIcon
    icon={PROMPT_TYPE_ICONS[props.type]}
    style={props.style}
    className={props.className}
  />
);
