import React, { ReactNode } from 'react';
import { Flex } from 'antd';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/DevExThemeContext';

import { QuestionCard, QuestionCardProps } from './QuestionCard';

const styles = {
  message: css`
    font-size: max(
      ${(props) => props.theme.variable.fontSize.md},
      min(${(props) => props.theme.variable.fontSize.lg}, 4vw)
    );
    line-height: ${(props) => props.theme.variable.lineHeight};
  `,
};

interface QuestionCardIntroProps extends QuestionCardProps {
  message: ReactNode;
  questionCount: number;
}

export const QuestionCardIntro: React.FC<QuestionCardIntroProps> = (props) => {
  const theme = useDevExTheme();

  // average time per question = 30s
  const minutes = Math.ceil(props.questionCount / 2);

  return (
    <QuestionCard {...props} subtitle={<span>Welcome</span>} validated>
      <Flex css={styles.message} vertical gap="large">
        <div>{props.message}</div>

        <div>
          There are{' '}
          <strong>
            {props.questionCount} questions (~{minutes} mins)
          </strong>
          .
        </div>

        <div>
          Remember, your responses are always{' '}
          <span style={{ color: theme.color.accent.secondary }}>anonymous</span>. Responses are only
          ever segmented by anonymous attributes, such as team, role, and location, in groups of 3
          or more.
        </div>
      </Flex>
    </QuestionCard>
  );
};
