import { useDevExTheme } from 'jf/common/DevExThemeContext';

/**
 * Get discrete colors for the frequency distribution of scores.
 */
export const useFreqDistColors = () => {
  const theme = useDevExTheme();

  return [
    theme.color.visualization.polarity.negative.high.background,
    theme.color.visualization.polarity.negative.low.background,
    theme.color.visualization.polarity.neutral.background,
    theme.color.visualization.polarity.positive.low.background,
    theme.color.visualization.polarity.positive.high.background,
  ];
};
