import React from 'react';
import { escapeRegExp } from 'lodash-es';

import { DevExSelect, DevExSelectOption } from 'jf/components/DevExSelect';
import { useLivePropState } from 'jf/utils/useLivePropState';

import { QuestionCard, QuestionCardProps } from './QuestionCard';

export const CATCH_ALL_TEAM_REF = '00000000-0000-0000-0000-000000000000';

type DemographicOption = {
  label: string;
  value: string;
};

interface QuestionCardDemographicProps extends QuestionCardProps {
  title: string;
  placeholder: string;
  options: DemographicOption[];
  value?: string;
  onBack?: (value?: string) => void;
  onNext?: (value?: string) => void;
}

export const QuestionCardDemographic: React.FC<QuestionCardDemographicProps> = (props) => {
  const [value, setValue] = useLivePropState(props.value);

  let options = [...props.options].sort((a, b) => {
    // sort catch all option to the end
    const otherA = a.value === CATCH_ALL_TEAM_REF;
    const otherB = b.value === CATCH_ALL_TEAM_REF;
    if (otherA || otherB) {
      return otherA ? 1 : -1;
    }

    return a.label.localeCompare(b.label);
  });

  const filterOption = (searchQuery: string, option: DemographicOption) => {
    const regex = new RegExp(escapeRegExp(searchQuery), 'i');

    // always show catch all option when searching
    const isCatchAll = option.value === CATCH_ALL_TEAM_REF;

    return isCatchAll || regex.test(option.label.toString());
  };

  const renderOption = (option: DevExSelectOption) => {
    if (option.value === CATCH_ALL_TEAM_REF) {
      return 'None of the above';
    }
    return option.label;
  };

  return (
    <QuestionCard
      {...props}
      subtitle={props.subtitle ?? 'Demographics'}
      onBack={props.onBack && (() => props.onBack?.(value))}
      onNext={props.onNext && (() => props.onNext?.(value))}
      validated={!!value}
    >
      <DevExSelect
        size="large"
        style={{ width: '100%' }}
        placeholder={props.placeholder}
        options={options}
        value={value}
        onChange={setValue}
        showSearch
        filterOption={filterOption}
        optionRender={renderOption}
        labelRender={renderOption}
        disabled={props.disabled}
      />
    </QuestionCard>
  );
};
