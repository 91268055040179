/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useMemo } from 'react';
import { ConfigProvider as AntThemeProvider, ThemeConfig as AntThemeConfig } from 'antd';
import { cloneDeep, get, mapValues, set } from 'lodash-es';
import * as polished from 'polished';
import { DefaultTheme, ThemeProvider as StyledThemeProvider } from 'styled-components';

import { useLocalStorage } from 'jf/utils/useBrowserStorage';

import { DevExEnv, getDevExEnv } from '../utils/getDevExEnv';

const rgbaToHex = (rgba) => {
  let [r, g, b, a] = rgba
    .slice(5, -1)
    .split(',')
    .map((x) => parseFloat(x));
  a = Math.round(a * 255);
  return `#${[r, g, b, a].map((x) => x.toString(16).padStart(2, '0')).join('')}`;
};

/**
 * Resolve a theme color from a functional syntax. Includes the ability to handle dynamic colors and chaining of polished effects.
 *
 * @example
 * "#20cb43" => "#20cb43"
 * "#20cb43 / darken 0.25" => "#0f5d1f"
 * "color.foundations.light" => "#FFFFFF"
 * "color.foundations.light / darken 0.25 / opacify 0.25" => "rgba(191,191,191,0.75)"
 */
export const resolveColor = (theme: DevExTheme, token: string) => {
  let [color, ...effects] = token.split(' / ');

  // resolve dynamic color from theme if applicable
  if (color[0] !== '#') {
    const actualColor = get(theme, color);

    if (!actualColor || typeof actualColor !== 'string') {
      throw new Error(`Could not resolve dynamic color "${color}".`);
    }

    color = actualColor;
  }

  // resolve polished effects if applicable
  for (const effect of effects) {
    const [functionName, amount] = effect.split(' ');

    if (!polished[functionName]) {
      throw new Error(`"${functionName}" is not a valid polished coloring function.`);
    }
    if (!amount) {
      throw new Error(`"${functionName}" coloring function missing parameter.`);
    }

    color = polished[functionName](amount, color);

    if (color.startsWith('rgba')) {
      color = rgbaToHex(color);
    }
  }

  return color.toUpperCase();
};

/**
 * Recursively traverse `DevExTheme` to resolve functional color syntax.
 */
export const resolveTheme = (theme: DevExTheme, path?: string): DevExTheme => {
  // get nested slice of DevExTheme if applicable
  const slice = path ? get(theme, path) : theme;

  for (const [key, value] of Object.entries(slice)) {
    const colorPath = `${path ? `${path}.` : ''}${key}`;

    if (typeof value === 'string' && !path?.startsWith('variable')) {
      // resolve color
      set(theme, colorPath, resolveColor(theme, value));
    } else if (typeof value === 'object') {
      // recurse
      resolveTheme(theme, colorPath);
    }
  }
  return theme;
};

export const COLOR_FOUNDATIONS = {
  light: '#F9F6FE',
  dark: '#0D062B',
  gray: '#CFCDD5',
  white: '#FFFFFF',
  green: '#13E2BF',
  blue: '#1E85FF',
  yellow: '#CFEF09',
  red: '#FF502A',
  purple: '#7319F2',
  magenta: '#DB2781',
};

const VARIABLES = {
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '48px',
    xxl: '96px',
  },
  borderRadius: '8px',
  fontSize: {
    xxs: '12px', // condensed chart labels
    xs: '14px', // chart labels / footnotes / tag text
    sm: '16px', // body text / default buttons
    md: '18px', // large text / large buttons / tabs
    lg: '20px', // card headers
    xl: '28px', // page headers
    xxl: '52px', // page statements
  },
  lineHeight: 1.5,
  fontFamily: {
    primary: '"Source Sans Pro", "Segoe UI", "Droid Sans", Tahoma, Arial, sans-serif',
    secondary: '"Roobert", sans-serif',
  },
};

const DarkTheme = {
  color: {
    foundations: COLOR_FOUNDATIONS,
    accent: {
      primary: 'color.foundations.purple / darken 0.05 / desaturate 0.1',
      secondary: 'color.foundations.yellow',
      aura: {
        primary: 'color.accent.primary / opacify -0.8 / desaturate 0.3 / lighten 0.05',
        secondary: 'color.accent.secondary / opacify -0.8 / desaturate 0.3 / lighten 0.05',
      },
      inverse: {
        primary: 'color.foundations.light',
        secondary: 'color.foundations.dark',
      },
    },
    text: {
      primary: 'color.foundations.light',
      secondary: 'color.foundations.white / opacify -0.4',
      tertiary: 'color.foundations.white / opacify -0.6',
      disabled: 'color.text.tertiary',
      aura: 'color.text.primary / opacify -0.85 / desaturate 0.2',
    },
    background: {
      primary: 'color.foundations.dark',
      secondary: 'color.background.primary / lighten 0.06 / desaturate 0.35',
      tertiary: 'color.background.primary / lighten 0.16 / desaturate 0.41',
    },
    reveal: {
      accent: 'color.foundations.yellow',
      dark: {
        background: 'color.foundations.dark',
        text: 'color.foundations.light',
        secondary: 'color.foundations.white / opacify -0.4',
      },
      header: {
        background: 'color.foundations.dark / opacify -1',
        scroll: 'color.foundations.dark',
      },
      purple: {
        main: {
          text: 'color.foundations.light',
          background: 'color.foundations.purple',
        },
        card: {
          text: 'color.foundations.dark',
          background: 'color.foundations.light',
          secondary: 'color.foundations.dark / opacify -0.4',
        },
      },
      scores: {
        text: 'color.foundations.dark',
        background: 'color.foundations.light',
        secondary: 'color.foundations.dark / opacify -0.4',
        expandable: {
          background: 'color.foundations.white',
          text: 'color.foundations.dark',
          border: 'color.foundations.light / darken 0.07 / desaturate 0.35',
        },
      },
    },
    card: {
      background: 'color.background.secondary',
      border: 'color.background.tertiary',
      shadow: 'color.foundations.dark / opacify -0.6',
    },
    tooltip: {
      text: 'color.foundations.dark',
      background: 'color.foundations.white',
    },
    skeleton: {
      background: 'color.foundations.white / opacify -0.9',
    },
    button: {
      primary: {
        text: 'color.accent.inverse.primary',
        background: 'color.accent.primary',
        aura: 'color.accent.aura.primary',
      },
    },
    link: {
      text: 'color.foundations.blue',
      active: {
        text: 'color.foundations.blue / lighten 0.2',
      },
    },
    tag: {
      default: {
        text: 'color.text.primary',
        background: 'color.background.tertiary',
      },
      green: {
        text: 'color.foundations.green',
        background: 'color.foundations.green / desaturate 0.09 / darken 0.28',
      },
      blue: {
        text: 'color.foundations.blue / lighten 0.09',
        background: 'color.foundations.blue / desaturate 0.22 / darken 0.36',
      },
      yellow: {
        text: 'color.foundations.yellow',
        background: 'color.foundations.yellow / desaturate 0.09 / darken 0.27',
      },
      red: {
        text: 'color.foundations.red',
        background: 'color.foundations.red / desaturate 0.20 / darken 0.42',
      },
      purple: {
        text: 'color.foundations.purple / desaturate 0.01 / lighten 0.38',
        background: 'color.foundations.purple / darken 0.28',
      },
      magenta: {
        text: 'color.foundations.magenta / saturate 0.29 / lighten 0.24',
        background: 'color.foundations.magenta / saturate 0.14 / darken 0.28',
      },
    },
    status: {
      info: {
        text: 'color.tag.blue.text',
        background: 'color.tag.blue.background / opacify -0.85',
        border: 'color.tag.blue.background',
      },
      warning: {
        text: 'color.tag.yellow.text',
        background: 'color.tag.yellow.background / opacify -0.85',
        border: 'color.tag.yellow.background',
      },
      error: {
        text: 'color.tag.red.text',
        background: 'color.tag.red.background / opacify -0.85',
        border: 'color.tag.red.background',
      },
      success: {
        text: 'color.tag.green.text',
        background: 'color.tag.green.background / opacify -0.85',
        border: 'color.tag.green.background',
      },
    },
    visualization: {
      grid: 'color.text.aura',
      polarity: {
        neutral: {
          text: 'color.foundations.white / darken 0.25',
          background: 'color.foundations.white / darken 0.08',
        },
        positive: {
          high: {
            text: 'color.foundations.green / darken 0.05',
            background: 'color.foundations.green / darken 0.05',
          },
          low: {
            text: 'color.foundations.green / lighten 0.20 / desaturate 0.1',
            background: 'color.foundations.green / lighten 0.20 / desaturate 0.1',
          },
        },
        negative: {
          high: {
            text: 'color.foundations.red',
            background: 'color.foundations.red',
          },
          low: {
            text: 'color.foundations.red / lighten 0.20 / desaturate 0.1',
            background: 'color.foundations.red / lighten 0.20 / desaturate 0.1',
          },
        },
      },
      palette: {
        1: {
          data: 'color.foundations.yellow / lighten 0.2 / desaturate 0.1',
        },
      },
    },
    topic: {
      tools_envs: 'color.foundations.green',
      clear_dir: 'color.foundations.blue',
      team_proc: 'color.foundations.yellow',
      con_switch: 'color.foundations.red',
      codebase: 'color.foundations.purple',
      review: 'color.foundations.magenta',
      test: 'color.foundations.green',
      release: 'color.foundations.blue',
      oncall: 'color.foundations.yellow',
      docs: 'color.foundations.red',
      learning: 'color.foundations.purple',
      onboard: 'color.foundations.magenta',
      dev_enable: 'color.foundations.green',
      satis: 'color.foundations.blue',
      perc_prod: 'color.foundations.yellow',
      other: 'color.foundations.gray',
    },
  },
  variable: VARIABLES,
};

export type DevExTheme = typeof DarkTheme;

const LightTheme: DevExTheme = {
  color: {
    foundations: COLOR_FOUNDATIONS,
    accent: {
      primary: 'color.foundations.purple',
      secondary: 'color.foundations.purple',
      aura: {
        primary: 'color.accent.primary / opacify -0.92 / desaturate 0.3 / lighten 0.05',
        secondary: 'color.accent.secondary / opacify -0.92 / desaturate 0.3 / lighten 0.05',
      },
      inverse: {
        primary: 'color.foundations.light',
        secondary: 'color.foundations.dark',
      },
    },
    text: {
      primary: 'color.foundations.dark',
      secondary: 'color.foundations.dark / opacify -0.4',
      tertiary: 'color.foundations.dark / opacify -0.6',
      disabled: 'color.text.tertiary',
      aura: 'color.text.primary / opacify -0.85 / desaturate 0.2',
    },
    background: {
      primary: 'color.foundations.light',
      secondary: 'color.background.primary / darken 0.07 / desaturate 0.35',
      tertiary: 'color.background.primary / darken 0.08 / desaturate 0.5',
    },
    card: {
      background: 'color.foundations.white',
      border: 'color.background.secondary',
      shadow: 'color.foundations.dark / opacify -0.9',
    },
    reveal: {
      ...DarkTheme.color.reveal,
    },
    tooltip: {
      text: 'color.foundations.light',
      background: 'color.foundations.dark',
    },
    skeleton: {
      background: 'color.foundations.dark / opacify -0.95',
    },
    button: DarkTheme.color.button,
    link: DarkTheme.color.link,
    tag: {
      default: {
        text: 'color.text.primary',
        background: 'color.background.tertiary',
      },
      green: {
        text: 'color.foundations.green / desaturate 0.09 / darken 0.33',
        background: 'color.foundations.green / desaturate 0.09 / lighten 0.28',
      },
      blue: {
        text: 'color.foundations.blue / desaturate 0.22 / darken 0.41',
        background: 'color.foundations.blue / lighten 0.14',
      },
      yellow: {
        text: 'color.foundations.yellow / desaturate 0.09 / darken 0.32',
        background: 'color.foundations.yellow',
      },
      red: {
        text: 'color.foundations.red / desaturate 0.09 / darken 0.12',
        background: 'color.foundations.red / lighten 0.05',
      },
      purple: {
        text: 'color.foundations.purple / darken 0.28',
        background: 'color.foundations.purple / desaturate 0.01 / lighten 0.38',
      },
      magenta: {
        text: 'color.foundations.magenta / saturate 0.14 / darken 0.28',
        background: 'color.foundations.magenta / saturate 0.29 / lighten 0.24',
      },
    },
    status: DarkTheme.color.status,
    visualization: {
      ...DarkTheme.color.visualization,
      palette: {
        1: {
          data: 'color.foundations.blue',
        },
      },
    },
    topic: {
      tools_envs: 'color.foundations.green',
      clear_dir: 'color.foundations.blue',
      team_proc: 'color.foundations.yellow',
      con_switch: 'color.foundations.red',
      codebase: 'color.foundations.purple',
      review: 'color.foundations.magenta',
      test: 'color.foundations.green',
      release: 'color.foundations.blue',
      oncall: 'color.foundations.yellow',
      docs: 'color.foundations.red',
      learning: 'color.foundations.purple',
      onboard: 'color.foundations.magenta',
      dev_enable: 'color.foundations.green',
      satis: 'color.foundations.blue',
      perc_prod: 'color.foundations.yellow',
      other: 'color.foundations.gray',
    },
  },
  variable: VARIABLES,
};

export const RAW_THEMES = {
  dark: DarkTheme,
  light: LightTheme,
};

type ThemeKey = keyof typeof RAW_THEMES;

const defaultThemeKey: ThemeKey = (() => {
  const isAtlassian = getDevExEnv().key === DevExEnv.ATLASSIAN;
  const isStorybook = !!process.env.STORYBOOK;

  if (!isAtlassian && !isStorybook && window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    }
  }

  return 'light';
})();

interface DETC extends DevExTheme {
  raw: DevExTheme; // raw theme prior to variable resolution
  key: ThemeKey;
  toggle: () => void;
  update: (themeKey: string) => void;
  customize: (path: string, definition: string) => void;
  reset: () => void;
}

const DevExThemeContext = createContext<DETC>({
  ...RAW_THEMES[defaultThemeKey],
  raw: RAW_THEMES[defaultThemeKey],
  key: defaultThemeKey,
  toggle: () => {},
  update: (themeKey: string) => {},
  customize: () => {},
  reset: () => {},
});

export const useDevExTheme = () => useContext(DevExThemeContext);

export const DevExThemeProvider: React.FC = (props) => {
  const [rawThemes, setRawThemes] = useLocalStorage('devex_raw_themes', cloneDeep(RAW_THEMES));
  const [themeKey, setThemeKey] = useLocalStorage<ThemeKey>('devex_theme_key', defaultThemeKey);

  const themes = useMemo(
    () => mapValues(rawThemes, (theme) => resolveTheme(cloneDeep(theme))),
    [rawThemes]
  );

  const devExThemeContext = useMemo(
    () => ({
      ...themes[themeKey],
      raw: rawThemes[themeKey],
      key: themeKey,
      toggle: () => {
        const targetThemeKey = themeKey === 'dark' ? 'light' : 'dark';
        setThemeKey(targetThemeKey);
      },
      update: (key: ThemeKey) => {
        setThemeKey(key);
      },
      customize: (path: string, definition: string) => {
        set(rawThemes[themeKey], path, definition);
        setRawThemes({ ...rawThemes });
      },
      reset: () => {
        rawThemes[themeKey] = cloneDeep(RAW_THEMES[themeKey]);
        setRawThemes({ ...rawThemes });
      },
    }),
    [themes, themeKey]
  );

  const antTheme: AntThemeConfig = {
    token: {
      fontSize: parseInt(themes[themeKey].variable.fontSize.sm),
    },
  };

  return (
    <DevExThemeContext.Provider value={devExThemeContext}>
      <StyledThemeProvider theme={themes[themeKey] as DefaultTheme}>
        <AntThemeProvider theme={antTheme}>{props.children}</AntThemeProvider>
      </StyledThemeProvider>
    </DevExThemeContext.Provider>
  );
};
