/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';

// Hook from https://usehooks.com/useLocalStorage/ (public domain)
// Modified to allow both local/session storage
const useBrowserStorage =
  (storage: Storage) =>
  <T = any>(key: string, initialValue?: T) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
      try {
        // Get from local storage by key
        const item = storage.getItem(key);
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialValue;
      }
    });

    // Return a wrapped version of useState's setter function that
    // persists the new value to chosen storage
    const setValue = (value: T | ((val: T) => T)) => {
      setStoredValue((storedValue) => {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        if (valueToStore === undefined || valueToStore === initialValue) {
          // Remove from storage
          storage.removeItem(key);
        } else {
          // Save to storage
          storage.setItem(key, JSON.stringify(valueToStore));
        }
        // Save state
        return valueToStore;
      });
    };

    return [storedValue, setValue] as const;
  };

export const useSessionStorage = useBrowserStorage(sessionStorage);
export const useLocalStorage = useBrowserStorage(localStorage);
