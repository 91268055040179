import React from 'react';
import { Flex } from 'antd';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';

import { TeamsEditor } from '../editStudy/teamsEditor/TeamsEditor';

import { useCreateStudyState } from './CreateStudyController';

export const ConfigureTeamsStep: React.FC = () => {
  const theme = useDevExTheme();
  const createStudyState = useCreateStudyState();

  return (
    <Flex vertical gap={theme.variable.spacing.lg} align="center">
      <Flex style={{ alignSelf: 'stretch' }}>
        <DevExButton
          icon={<FontAwesomeIcon icon={faAngleLeft} />}
          onClick={() => createStudyState.update({ stepIndex: createStudyState.stepIndex - 1 })}
        >
          Back
        </DevExButton>
      </Flex>

      <div style={{ fontSize: theme.variable.fontSize.lg }}>
        Now, let's set up the teams that can take this survey
      </div>
      <div style={{ color: theme.color.text.secondary, lineHeight: theme.variable.lineHeight }}>
        Each survey participant will be asked to select their primary team from this list of teams
        while taking the survey. This information will allow you to segment your survey results by
        team, providing valuable insights.
      </div>

      <DevExCard title="Your Teams" style={{ width: '100%' }}>
        <TeamsEditor
          onChange={(unsavedTeams) => createStudyState.update({ teamsToCreate: unsavedTeams })}
        />
      </DevExCard>

      <Flex justify="end" style={{ alignSelf: 'stretch' }}>
        <DevExButton
          type="primary"
          icon={<FontAwesomeIcon icon={faAngleRight} />}
          iconPosition="right"
          onClick={() => createStudyState.update({ stepIndex: createStudyState.stepIndex + 1 })}
        >
          Next
        </DevExButton>
      </Flex>
    </Flex>
  );
};
