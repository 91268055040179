import React from 'react';
import { Flex, Modal, ModalProps } from 'antd';
import { css } from 'styled-components';

import { useDevExTheme } from '../common/DevExThemeContext';

import { DevExButton } from './DevExButton';

const styles = {
  modal: css`
    &.ant-modal {
      .ant-modal-content {
        background-color: ${(props) => props.theme.color.card.background};
        color: ${(props) => props.theme.color.text.primary};
        border-radius: ${(props) => props.theme.variable.borderRadius};
        border: 1px solid ${(props) => props.theme.color.card.border};
        padding: 0;

        .ant-modal-close {
          color: ${(props) => props.theme.color.text.tertiary};

          &:hover {
            color: ${(props) => props.theme.color.text.primary};
          }
        }

        .ant-modal-header {
          background-color: transparent;
          color: inherit;
          margin-bottom: 0;
          border-bottom: 1px solid ${(props) => props.theme.color.card.border};

          .ant-modal-title {
            color: inherit;
            font-size: ${(props) => props.theme.variable.fontSize.lg};
          }
        }

        .ant-modal-header,
        .ant-modal-body,
        .ant-modal-footer {
          padding: ${(props) => props.theme.variable.spacing.md};
        }

        .ant-modal-footer {
          margin: 0;
        }
      }
    }
  `,
};

export const DevExModal: React.FC<ModalProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <Modal
      css={styles.modal}
      {...props}
      footer={
        props.footer === null ? null : (
          <Flex justify="end" gap={theme.variable.spacing.sm}>
            {props.footer ?? (
              <>
                <DevExButton onClick={props.onCancel}>Cancel</DevExButton>
                <DevExButton type="primary" onClick={props.onOk}>
                  {props.okText || 'Ok'}
                </DevExButton>
              </>
            )}
          </Flex>
        )
      }
    />
  );
};
