import React, { useEffect } from 'react';
import { Flex } from 'antd';

import { RosterClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExExpandableAlert, DevExExpandableAlertType } from 'jf/components/DevExExpandableAlert';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { QuestionCard } from '../study/question/QuestionCard';
import {
  CATCH_ALL_TEAM_REF,
  QuestionCardDemographic,
} from '../study/question/QuestionCardDemographic';

import { TeamsEditor } from './teamsEditor/TeamsEditor';
import { DEFAULT_TEAM_DEMOGRAPHIC_TEXT } from './EditStudyPage';
import { useEditStudyState } from './EditStudyStateContext';

export const QuestionCardEditableDemographic = () => {
  const theme = useDevExTheme();
  const editStudyState = useEditStudyState();

  const { data: teams } = useClientQuery(RosterClient.getTeams);

  const selectableTeams = teams?.filter((team) => team.isLeaf && !team.isHidden);

  // show warning when company has not configured any teams (ignore catch all team)
  const showTeamConfigWarning =
    selectableTeams?.filter((team) => team.ref !== CATCH_ALL_TEAM_REF).length === 0;

  useEffect(() => {
    return () => {
      // clear unsavedTeams when leaving step
      editStudyState.update({ unsavedTeams: null });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // alert user if navigating away with unsaved team configuration
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (editStudyState.unsavedTeams) {
        event.preventDefault();
        event.returnValue = ' ';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [editStudyState.unsavedTeams]);

  return editStudyState.unsavedTeams ? (
    <QuestionCard
      subtitle={'Demographics'}
      title={'Edit teams'}
      description={
        <TeamsEditor
          onChange={(unsavedTeams) => editStudyState.update({ unsavedTeams })}
          style={{ paddingTop: theme.variable.spacing.md }}
        />
      }
    />
  ) : (
    <QuestionCardDemographic
      subtitle={
        <Flex vertical gap={theme.variable.spacing.md}>
          {showTeamConfigWarning && (
            <DevExExpandableAlert
              title={'Team Configuration Required'}
              type={DevExExpandableAlertType.WARNING}
            >
              To segment your survey results for analysis, respondents will be required to select
              their primary team at the beginning of the survey. You can configure the available
              team options now.
            </DevExExpandableAlert>
          )}
          Demographics
        </Flex>
      }
      title={DEFAULT_TEAM_DEMOGRAPHIC_TEXT}
      placeholder={'Select team...'}
      options={selectableTeams?.map((team) => ({ value: team.ref, label: team.value })) ?? []}
    />
  );
};
