import React from 'react';
import { Flex } from 'antd';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { useQueryClient } from 'react-query';
import { css } from 'styled-components';

import { Study, StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExMenu } from 'jf/components/DevExMenu';
import { DevExPopover } from 'jf/components/DevExPopover';
import { useClientMutation } from 'jf/utils/useClientQuery';
import { useNavigateToStudy } from 'jf/utils/useNavigateToStudy';

import { StudyStatusBubble } from '../studies/StudyStatusBubble';

export const SEQUENCED_STUDY_CARD_MIN_WIDTH = '240px';

const styles = {
  sequencedStudyCard: css`
    .sequencedStudyCard__title {
      font-size: ${(props) => props.theme.variable.fontSize.md};
      line-height: ${(props) => props.theme.variable.lineHeight};
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .sequencedStudyCard__subtitle {
      color: ${(props) => props.theme.color.text.secondary};
    }

    .sequencedStudyCard__note {
      font-size: ${(props) => props.theme.variable.fontSize.xs};
      color: ${(props) => props.theme.color.text.secondary};
    }
  `,
};

type SequencedStudyCardProps = {
  study: Study;
};

export const SequencedStudyCard: React.FC<SequencedStudyCardProps> = (props) => {
  const navigateToStudy = useNavigateToStudy();
  const theme = useDevExTheme();
  const { mutateAsync: updateStudy } = useClientMutation(StudyClient.updateStudy);
  const queryClient = useQueryClient();

  return (
    <DevExCard css={styles.sequencedStudyCard} onClick={() => navigateToStudy(props.study)}>
      <Flex justify="space-between" gap={theme.variable.spacing.sm}>
        <Flex vertical gap={theme.variable.spacing.xs} style={{ overflow: 'hidden' }}>
          <div className="sequencedStudyCard__title">{props.study.name}</div>
          <div className="sequencedStudyCard__subtitle">{props.study.responseCount} responses</div>
        </Flex>

        <StudyStatusBubble study={props.study} />
      </Flex>
      <Flex justify="space-between" align="center">
        <div className="sequencedStudyCard__note">
          Last updated {DateTime.fromISO(props.study.lastUpdated).toLocaleString(DateTime.DATE_MED)}
        </div>
        <DevExPopover
          trigger={['click']}
          content={
            <DevExMenu
              items={[
                {
                  key: 'remove',
                  label: 'Remove from series',
                  onClick: () => {
                    const { ref } = props.study;
                    updateStudy({
                      studyRef: ref,
                      requestBody: {
                        sequenceRef: null,
                      },
                    }).then(() => {
                      queryClient.invalidateQueries('GET_STUDIES');
                      queryClient.removeQueries(['GET_STUDY', ref]);
                    });
                  },
                },
              ]}
              selectable={false}
            />
          }
          placement="bottom"
        >
          <DevExButton
            type="text"
            icon={<FontAwesomeIcon icon={faEllipsisV} />}
            onClick={(event) => event.stopPropagation()}
          />
        </DevExPopover>
      </Flex>
    </DevExCard>
  );
};
