import React from 'react';
import { Flex, Spin, SpinProps } from 'antd';
import { css } from 'styled-components';

const styles = {
  spin: css`
    flex: 1;
    min-width: 40px;
    min-height: 40px;

    .ant-spin-nested-loading {
      flex: 1;

      .ant-spin {
        .ant-spin-dot-item {
          background-color: ${(props) => props.theme.color.accent.primary};
        }

        .ant-spin-text {
          text-shadow: none;
          color: ${(props) => props.theme.color.accent.primary};
          padding-top: ${(props) => props.theme.variable.spacing.md};
        }
      }
    }
  `,
};

interface DevExLoaderProps extends SpinProps {}

export const DevExLoader: React.FC<DevExLoaderProps> = (props) => {
  return (
    <Flex align="center" justify="center" css={styles.spin}>
      <Spin size="large" {...props}>
        {/* props.tip will only appear if Spin has a child */}
        {props.children ?? <div />}
      </Spin>
    </Flex>
  );
};
