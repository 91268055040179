import React, { useState } from 'react';
import { Flex } from 'antd';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { Study, StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExModal } from 'jf/components/DevExModal';
import { useClientMutation } from 'jf/utils/useClientQuery';

import { useEditStudyState } from './EditStudyStateContext';

export const PublishStudyModal: React.FC = () => {
  const modal = useJFOverlay(PublishStudyModal);
  const { studyRef } = useParams<{ studyRef: string }>();

  const theme = useDevExTheme();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const editStudyState = useEditStudyState();

  const [publishing, setPublishing] = useState(false);

  const closeModal = () => {
    if (!publishing) {
      trackEvent('survey-editor:publish:cancel', { surveyRef: studyRef });
      modal.close();
    }
  };

  const onCancel = () => closeModal();

  const { mutateAsync: updateStudy } = useClientMutation(StudyClient.updateStudy);

  const onOk = () => {
    trackEvent('survey-editor:publish:publish', { surveyRef: studyRef });
    setPublishing(true);
    if (studyRef) {
      updateStudy({
        studyRef,
        requestBody: {
          status: Study.status.OPEN,
        },
      })
        .then(() => {
          queryClient.invalidateQueries('GET_STUDIES');
          queryClient.invalidateQueries(['GET_STUDY', studyRef]);
          queryClient.invalidateQueries('GET_PROMPTS'); // in case prompts were frozen
          queryClient.removeQueries(['GET_PUBLIC_STUDY', studyRef]);
          modal.close(true);
          navigate(`/study/${studyRef}/analyze/participation`);
        })
        .catch(() => setPublishing(false));
    }
  };

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={onCancel}
      onOk={onOk}
      title="Publish Survey"
      footer={
        <>
          <DevExButton onClick={onCancel} disabled={publishing}>
            No, keep editing
          </DevExButton>
          <DevExButton
            type="primary"
            onClick={onOk}
            loading={publishing}
            disabled={!editStudyState.saved}
          >
            {publishing ? 'Publishing' : 'Yes, publish'}
          </DevExButton>
        </>
      }
      width={600}
    >
      <Flex
        vertical
        style={{ fontSize: theme.variable.fontSize.md }}
        gap={theme.variable.spacing.lg}
      >
        <div>
          Once your survey is published, you will no longer be able to make changes to it. You will
          get a link to share with target participants.
        </div>

        <Flex vertical gap={theme.variable.spacing.sm}>
          Give your survey a standout name! Please review and update it now:
          <DevExInput
            value={editStudyState.name}
            onChange={(value) => editStudyState.update({ name: value })}
          />
        </Flex>

        <div>Are you ready to publish?</div>
      </Flex>
    </DevExModal>
  );
};
