import React, { ReactNode, useEffect, useState } from 'react';
import { Flex } from 'antd';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clipboardCopy from 'clipboard-copy';
import { capitalize, get } from 'lodash-es';

import { RAW_THEMES, resolveColor, useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExTag } from 'jf/components/DevExTag';
import { invertDevExThemeColor } from 'jf/utils/invertDevExThemeColor';

const ColorConfigurator: React.FC<{ path: string }> = (props) => {
  const theme = useDevExTheme();

  const [definition, setDefinition] = useState(get(theme.raw, props.path));

  useEffect(() => {
    setDefinition(get(theme.raw, props.path));
  }, [theme, props.path]);

  let color: string = '#ff0000';
  let error = false;

  try {
    color = resolveColor(theme, definition);
  } catch {
    error = true;
  }

  const changed = definition !== get(theme.raw, props.path);

  return (
    <Flex align="center" gap={theme.variable.spacing.md}>
      <div style={{ fontSize: theme.variable.fontSize.md, fontWeight: 500 }}>
        {props.path.split('.').at(-1)}
      </div>
      <DevExInput value={definition} onChange={setDefinition} style={{ width: 320 }} />
      <DevExTag color={color} style={{ color: invertDevExThemeColor(color, theme.key) }}>
        {error ? '!! INVALID COLOR !!' : color}
      </DevExTag>
      {changed && !error && (
        <DevExButton onClick={() => theme.customize(props.path, definition)}>Save</DevExButton>
      )}
    </Flex>
  );
};

const recurseDict = (dict: object, path: string, callback: (path: string) => void) => {
  for (const [key, value] of Object.entries(get(dict, path))) {
    const subPath = `${path}.${key}`;

    if (typeof value === 'object') {
      recurseDict(dict, subPath, callback);
    } else {
      callback(subPath);
    }
  }
};

export const ColorConfigPage: React.FC = () => {
  const theme = useDevExTheme();

  const items: ReactNode[] = [];
  const changes: string[] = [];

  let level = 'color';
  recurseDict(theme, level, (path) => {
    const newLevel = path.split('.').slice(0, -1).join('.');
    const depth = path.split('.').length - 3;
    if (level !== newLevel) {
      items.push(
        <div
          style={{
            fontSize: theme.variable.fontSize.lg,
            fontWeight: 600,
            paddingLeft: (depth - 1) * parseInt(theme.variable.spacing.lg),
            paddingBottom: theme.variable.spacing.sm,
            borderBottom: depth === 1 ? `1px solid ${theme.color.card.border}` : undefined,
          }}
        >
          {path.split('.').at(-2)}
        </div>
      );
    }
    items.push(
      <div style={{ paddingLeft: depth * parseInt(theme.variable.spacing.lg) }}>
        <ColorConfigurator path={path} />
      </div>
    );
    level = newLevel;

    // evaluate changelog
    if (get(theme.raw, path) !== get(RAW_THEMES[theme.key], path)) {
      changes.push(path);
    }
  });

  const onCopy = () => {
    const clipboardValues = [
      [`${capitalize(theme.key)} Mode Changes`],
      ['Color', 'Definition', 'Hex'],
      ...changes.map((path) => [path, get(theme.raw, path), get(theme, path)]),
    ];
    clipboardCopy(clipboardValues.map((r) => r.join('\t')).join('\n'));
  };

  return (
    <Flex
      vertical
      gap={theme.variable.spacing.sm}
      style={{ padding: theme.variable.spacing.lg, width: 'fit-content' }}
    >
      <Flex gap={theme.variable.spacing.sm}>
        <DevExButton type="primary" onClick={() => theme.toggle()}>
          Change color mode
        </DevExButton>
        <DevExButton onClick={() => theme.reset()} disabled={!changes.length}>
          Reset changes
        </DevExButton>
        <DevExButton
          icon={<FontAwesomeIcon icon={faCopy} />}
          onClick={onCopy}
          disabled={!changes.length}
        />
      </Flex>
      {items.map((item, i) => (
        <div key={i}>{item}</div>
      ))}
    </Flex>
  );
};
