import React from 'react';
import Bugsnag, { Event } from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react';

import { getDevExEnv } from './getDevExEnv';

declare const BUGSNAG_KEY, JF_VERSION;

const user = window.dx?.user; // user may be undefined when unauthenticated

const env = getDevExEnv();

// util function to handle specific edge case where we don't know exactly what's getting thrown
// mostly because this is a browser-specific edge case with a particular extension
const isSafariLastPass = (evt: Event) => {
  // something funky is happening so we need to look at originalError instead of evt.errors
  // but in the Safari LastPass case (which we can't repro locally), it's not clear what originalError *is*
  // so just ruthlessly check for the error message in a safe way to avoid ironically throwing an error in onError
  const m = evt?.originalError;
  if (typeof m === 'string') {
    return m.includes('Not implemented on this platform');
  } else if (m?.message && typeof m.message === 'string') {
    return m.message.includes('Not implemented on this platform');
  }
  // if we can't find the error message, we can't know if it's a lastpass error
  return false;
};

Bugsnag.start({
  apiKey: BUGSNAG_KEY,
  appVersion: JF_VERSION,
  enabledReleaseStages: [
    // 'development', // uncomment to test client bugsnag behavior
    'production',
    'demo',
    'staging',
  ],
  releaseStage: env.name,
  user: user && {
    email: user.email ?? undefined,
    id: user.id.toString(),
  },
  metadata: user && {
    // top-level keys show up as new tabs in bugsnag
    company: {
      slug: user.company.slug,
    },
  },
  plugins: [new BugsnagPluginReact()],
  onError: (evt) => {
    // uncomment to test client bugsnag behavior
    // console.log({ bugsnagEvent: evt });
    // return false;

    // if any of the booleans inside the array evaluated are true, the error
    // will not be reported
    const isClientIgnoredError =
      [
        // if this was global code, it was most certainly someone in the dev console
        evt.errors[0].stacktrace?.[0]?.file?.includes('global code'),
        // https://stackoverflow.com/a/50387233
        evt.errors[0].errorMessage.includes('ResizeObserver loop limit exceeded'),
        // the Firefox equivalent of the above error
        evt.errors[0].errorMessage.includes(
          'ResizeObserver loop completed with undelivered notifications'
        ),
        // AbortError that is a side-effect of network requests being cancelled
        // potentially because they navigated away from the page mid-load
        evt.errors[0].errorMessage.includes('The user aborted a request'),
        // if we get this error, it's most likely from a 3rd party extension
        // these are safe to ignore https://stackoverflow.com/a/74216043
        evt.errors[0].stacktrace?.[0]?.file?.includes('webkit-masked-url://hidden/'),
        // the lastpass extension on safari causes this specific error message to be thrown
        // this is not an error in the Jellyfish code, and has no impact on the app
        isSafariLastPass(evt),
        // More Firefox extension shenanigans
        // https://udn.realityripple.com/docs/Web/JavaScript/Reference/Errors/Dead_object
        evt.errors[0].errorMessage.includes(`can't access dead object`),
        // this error often occurs in react-dom, potentially a side-effect of how we lazy load
        // it appears to have no user impact
        evt.errors[0].errorMessage.includes(
          `Cannot read properties of undefined (reading 'default')`
        ),
      ].filter(Boolean).length !== 0;

    if (isClientIgnoredError) {
      // by returning false, we tell bugsnag not to error
      return false;
    }

    // if snag has no stacktrace, by default we would group on "unknown file : unknown method"
    // instead, group on the error message
    if (!evt.groupingHash && evt.errors[0].stacktrace && evt.errors[0].stacktrace.length === 0) {
      evt.groupingHash = evt.errors[0].errorMessage;
    }
  },
});

export const notify = (error: string | Error, groupingHash?: string, apiKey?: string) => {
  const notifiedError = typeof error == 'string' ? new Error(error) : error;
  Bugsnag.notify(notifiedError, (bugsnagEvt) => {
    if (groupingHash) {
      bugsnagEvt.groupingHash = groupingHash;
    }
    if (apiKey) {
      bugsnagEvt.apiKey = apiKey;
    }
  });
};

export const ErrorBoundary = (
  Bugsnag.getPlugin('react') as BugsnagPluginReactResult
).createErrorBoundary(React);
