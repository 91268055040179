/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CanonicalTopic } from '../models/CanonicalTopic';
import type { Prompt } from '../models/Prompt';
import type { PromptPopularity } from '../models/PromptPopularity';
import type { StudyTemplate } from '../models/StudyTemplate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StudyLibraryClient {

    /**
     * @returns Prompt
     * @throws ApiError
     */
    public static getPrompts(): CancelablePromise<Array<Prompt>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/library/prompts',
        });
    }

    /**
     * @returns PromptPopularity
     * @throws ApiError
     */
    public static getPromptPopularity(): CancelablePromise<Array<PromptPopularity>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/library/prompts/popularity',
        });
    }

    /**
     * @returns StudyTemplate
     * @throws ApiError
     */
    public static getStudyTemplates(): CancelablePromise<Array<StudyTemplate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/library/study-templates',
        });
    }

    /**
     * @returns CanonicalTopic
     * @throws ApiError
     */
    public static getTopics(): CancelablePromise<Array<CanonicalTopic>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/library/topics',
        });
    }

}
