import React, { ReactNode, useState } from 'react';
import { Flex } from 'antd';
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import {
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/DevExThemeContext';

import { FeedbackPopover } from './DevExFeedback';
import { DevExTag } from './DevExTag';

const styles = {
  feedback: css`
    border-radius: ${(props) => props.theme.variable.borderRadius} !important;
    width: fit-content;
  `,
};

type ThumbsFeedbackProps = {
  title: ReactNode;
  eventName: string;
};

export const ThumbsFeedback: React.FC<ThumbsFeedbackProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <DevExTag css={styles.feedback} color="yellow">
      <Flex gap={theme.variable.spacing.sm}>
        {props.title}
        <ThumbButton type="UP" eventName={props.eventName} />
        <ThumbButton type="DOWN" eventName={props.eventName} />
      </Flex>
    </DevExTag>
  );
};

type ThumbButtonProps = {
  type: 'UP' | 'DOWN';
  eventName: string;
};

const ThumbButton: React.FC<ThumbButtonProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const active = open || submitted;

  const getIcon = () => {
    switch (props.type) {
      case 'UP':
        switch (active) {
          case true:
            return faThumbsUpSolid;
          case false:
            return faThumbsUp;
        }
      // eslint-disable-next-line no-fallthrough
      case 'DOWN':
        switch (active) {
          case true:
            return faThumbsDownSolid;
          case false:
            return faThumbsDown;
        }
    }
  };

  const eventName = `${props.eventName}:thumbs-feedback:thumbs-${props.type.toLowerCase()}`;
  const placeholder = props.type === 'UP' ? 'What did we do well?' : 'What could be improved?';

  return (
    <FeedbackPopover
      eventName={eventName}
      placeholder={placeholder}
      setSubmitted={setSubmitted}
      submitted={submitted}
      open={open}
      setOpen={setOpen}
    >
      <FontAwesomeIcon
        icon={getIcon()}
        className={classNames({ 'button--active': active })}
        css={css`
          color: ${(props) => props.theme.color.text.secondary};
          cursor: pointer;

          &:hover,
          &.button--active {
            color: ${(props) => props.theme.color.text.primary};
          }
        `}
      />
    </FeedbackPopover>
  );
};
