import React, { useEffect, useRef, useState } from 'react';

type ElementObservation = {
  width: number;
  height: number;
};

type ElementObserverProps = {
  onChange?: (observation: ElementObservation, initial?: boolean) => void; // observe live element state
};

export const ElementObserver: React.FC<ElementObserverProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [lastObservation, setLastObservation] = useState<ElementObservation>();

  useEffect(() => {
    const rso = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      const observation = { width, height };

      // avoid duplicate change events
      if (JSON.stringify(observation) !== JSON.stringify(lastObservation)) {
        const initial = !lastObservation;
        props.onChange?.(observation, initial);

        setLastObservation(observation);
      }
    });

    const element = ref.current;
    element && rso.observe(element);

    return () => {
      element && rso.unobserve(element);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, lastObservation]);

  return <div ref={ref}>{props.children}</div>;
};
