/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { Flex } from 'antd';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFrown, faMeh, faSmile } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mean } from 'lodash-es';

import { PublicPrompt } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';

import { QuestionCard, QuestionCardProps } from './QuestionCard';
import { VerbatimSection, VerbatimSections } from './VerbatimSections';

export const validateVerbatims = (verbatims: { [topicSlug: string]: string }) => {
  return !!Object.values(verbatims).length;
};

export const VERBATIMS_PROMPT_TEXT = 'What topics would you like to see prioritized?';

type VerbatimLabel = {
  icon: IconProp;
  text: string;
};

const VERBATIM_LABELS: VerbatimLabel[] = [
  {
    icon: faFrown,
    text: 'Areas you rated negatively',
  },
  {
    icon: faMeh,
    text: 'Areas you rated neutral',
  },
  {
    icon: faSmile,
    text: 'Areas you rated positively',
  },
];

export interface ScoredPrompt extends PublicPrompt {
  score: number;
  topicLabel: string;
}

interface QuestionCardVerbatimsProps extends Omit<QuestionCardProps, 'onBack' | 'onNext'> {
  number: number;
  onBack?: (verbatims: { [topicSlug: string]: string }) => void;
  onNext?: (verbatims: { [topicSlug: string]: string }) => void;
  scoredPrompts: ScoredPrompt[];
  initialVerbatims?: { [topicSlug: string]: string };
}

export const QuestionCardVerbatims: React.FC<QuestionCardVerbatimsProps> = (props) => {
  const theme = useDevExTheme();

  const [verbatims, setVerbatims] = useState<{ [topicSlug: string]: string }>(
    props.initialVerbatims ?? {}
  );

  const verbatimSections = useMemo(() => {
    const sections: VerbatimSection[] = VERBATIM_LABELS.map((label) => ({
      label: (
        <Flex
          align="center"
          gap={theme.variable.spacing.xs}
          style={{ fontSize: theme.variable.fontSize.md }}
        >
          <FontAwesomeIcon icon={label.icon} />
          {label.text}
        </Flex>
      ),
      options: [],
    }));

    // compile prompts by topic slug
    const promptsByTopic: Record<string, ScoredPrompt[]> = {};
    props.scoredPrompts.forEach((prompt: ScoredPrompt) => {
      if (prompt.topicSlug) {
        if (!promptsByTopic[prompt.topicSlug]) {
          promptsByTopic[prompt.topicSlug] = [];
        }
        promptsByTopic[prompt.topicSlug].push(prompt);
      }
    });

    // compute what section each topic falls under
    Object.entries(promptsByTopic).forEach(([topicSlug, prompts]) => {
      const topicLabel = prompts[0].topicLabel;

      if (topicLabel) {
        const score = mean(prompts.map((prompt) => prompt.score));

        const sectionIndex = score >= 4 ? 2 : score <= 2 ? 0 : 1;
        sections[sectionIndex].options.push({
          key: topicSlug,
          label: topicLabel,
          popoverContent: (
            <ul style={{ margin: 0, paddingInlineStart: theme.variable.spacing.md }}>
              {prompts.map((prompt) => (
                <li key={prompt.slug}>{prompt.text}</li>
              ))}
            </ul>
          ),
        });
      }
    });

    return sections;
  }, [props.scoredPrompts]);

  return (
    <QuestionCard
      title={VERBATIMS_PROMPT_TEXT}
      subtitle={<span>Question {props.number}</span>}
      description="We recommend selecting 2 - 3 topics and leaving feedback for improvement. Hover over a topic to see the associated questions."
      validated={validateVerbatims(verbatims)}
      {...props}
      onBack={props.onBack && (() => props.onBack?.(verbatims))}
      onNext={props.onNext && (() => props.onNext?.(verbatims))}
    >
      <VerbatimSections
        value={verbatims}
        onChange={setVerbatims}
        sections={verbatimSections}
        disabled={props.disabled}
      />
    </QuestionCard>
  );
};
