import { StudyScoreClient } from 'jf/api';
import { useClientQuery } from 'jf/utils/useClientQuery';

type UseStudyScoresBySegmentProps = {
  studyRef: string | undefined;
  teamRef?: string;
  type?: Parameters<typeof StudyScoreClient.getStudyScores>[0]['type'];
};

/**
 * Fetch study scores by segment with optional `teamRef`.
 */
export const useStudyScoresBySegment = (props: UseStudyScoresBySegmentProps) => {
  const studyScoresBySegmentQuery = useClientQuery(StudyScoreClient.getStudyScoresBySegment, {
    studyRef: !props.teamRef ? props.studyRef : undefined, // don't send query if not needed
    type: props.type,
  });
  const studyScoresForTeamBySegmentQuery = useClientQuery(
    StudyScoreClient.getStudyScoresForTeamBySegment,
    {
      studyRef: props.studyRef,
      teamRef: props.teamRef,
      type: props.type,
    }
  );

  return props.teamRef ? studyScoresForTeamBySegmentQuery : studyScoresBySegmentQuery;
};
