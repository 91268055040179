import React, { ReactNode } from 'react';
import { css } from 'styled-components';

const styles = {
  collapsible: css`
    display: grid;
    transition: grid-template-rows 150ms ease;
    overflow: hidden;

    > div {
      grid-row: 1 / span 2;
    }
  `,
};

type DevExCollapsibleProps = {
  children: ReactNode;
  collapsed: boolean;
  style?: React.CSSProperties;
};

/**
 * Helper component to support transitioning an element's height between 0px and auto using a grid-template-rows trick.
 */
export const DevExCollapsible: React.FC<DevExCollapsibleProps> = (props) => (
  <div
    css={styles.collapsible}
    style={{ gridTemplateRows: props.collapsed ? '0fr' : '1fr', ...props.style }}
  >
    <div>{props.children}</div>
  </div>
);
