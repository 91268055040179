import React, { useContext, useRef, useState } from 'react';

type _JFOverlayControllerContext = {
  isOpened: boolean;
  // "any" here is not a concern since the useJFOverlay hook enforces strict typing
  open: (modal: React.FC<any>, props: any) => Promise<boolean>;
  update: (partialProps: any) => void;
  close: (ok: boolean) => void;
};

const JFOverlayControllerContext = React.createContext<_JFOverlayControllerContext>({
  isOpened: false,
  open: () => Promise.resolve(false),
  update: () => {},
  close: () => {},
});

export const JFOverlayController: React.FC = (props) => {
  const [isOpened, setIsOpened] = useState(false);

  // "any" here is not a concern since the useJFOverlay hook enforces strict typing
  const [Overlay, setOverlay] = useState<React.FC<any>>();
  const [overlayProps, setOverlayProps] = useState<any>();

  const modalResolve = useRef<(ok: boolean) => void>();

  const context: _JFOverlayControllerContext = {
    isOpened,
    open: (modal, modalProps) => {
      // open new modal
      setOverlay(() => modal);
      setOverlayProps(modalProps);
      setIsOpened(true);

      // return promise that will resolve after closure
      return new Promise((resolve) => {
        modalResolve.current = resolve;
      });
    },
    update: (partialProps) => {
      setOverlayProps((props) => ({ ...props, ...partialProps }));
    },
    close: (ok) => {
      setIsOpened(false);
      modalResolve.current?.(ok);
    },
  };

  return (
    <JFOverlayControllerContext.Provider value={context}>
      {Overlay && <Overlay {...overlayProps} />}
      {props.children}
    </JFOverlayControllerContext.Provider>
  );
};

export const useJFOverlay = <TProps,>(modal: React.FC<TProps>) => {
  const controller = useContext(JFOverlayControllerContext);

  const open = (
    // use spread operator so we can require the props param only if it's needed
    ...[props]: {} extends TProps ? [TProps?] : [TProps]
  ) => {
    return controller.open(modal, props);
  };

  const update = (partialProps: Partial<TProps>) => {
    controller.update(partialProps);
  };

  const close = (ok = false) => {
    controller.close(ok);
  };

  return {
    isOpened: controller.isOpened,
    open,
    update,
    close,
  };
};
