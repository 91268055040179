import React, { useMemo } from 'react';
import { Flex } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { css } from 'styled-components';

import { StudyLibraryClient } from 'jf/api';
import { DevExSelect, DevExSelectProps } from 'jf/components/DevExSelect';
import { useClientQuery } from 'jf/utils/useClientQuery';

const styles = {
  select: css`
    &&&&.ant-select {
      margin-bottom: ${({ theme }) => theme.variable.spacing.sm};

      .ant-select-selector {
        border-radius: 20px;
        border-color: transparent;
        background-color: ${(props) => props.theme.color.tag.default.background};

        .ant-select-selection-item,
        .ant-select-selection-search input {
          color: ${(props) => props.theme.color.tag.default.text};
        }
      }
    }
  `,
};

export const TopicSelect: React.FC<DevExSelectProps> = (props) => {
  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);

  const options: DefaultOptionType[] = useMemo(() => {
    if (!topics) {
      return [];
    }

    return topics
      .map((topic) => ({
        label: topic.label,
        value: topic.slug,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [topics]);

  return (
    <Flex justify="flex-end">
      <DevExSelect
        {...props}
        options={options}
        placeholder="Choose topic"
        css={styles.select}
        showSearch
      />
      *
    </Flex>
  );
};
