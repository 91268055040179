import React from 'react';
import { Flex } from 'antd';
import { faAngleLeft, faRotateBack } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';

import { DevExHeader } from 'jf/common/DevExHeader';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';

import { SharePreviewButton } from './SharePreviewButton';
import { useStudyState } from './StudyStateContext';

const styles = {
  headerBackground: css`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: ${(props) => props.theme.color.status.info.background};
    z-index: -1;
    border-bottom: 1px solid ${(props) => props.theme.color.status.info.border};
  `,
};

export const PreviewStudyHeader: React.FC = () => {
  const navigate = useNavigate();
  const studyState = useStudyState();
  const theme = useDevExTheme();

  return (
    <DevExHeader
      left={
        window.dx.user && (
          <DevExButton
            type="outline"
            size="large"
            icon={<FontAwesomeIcon icon={faAngleLeft} />}
            onClick={() => navigate(`/study/${studyState.refs.studyRef}/edit`)}
          >
            Back to editing
          </DevExButton>
        )
      }
      right={
        <Flex gap={theme.variable.spacing.md}>
          <DevExButton
            type="outline"
            size="large"
            icon={<FontAwesomeIcon icon={faRotateBack} />}
            onClick={() => studyState.clear()}
            disabled={studyState.stepIndex === 0}
          >
            Restart
          </DevExButton>
          <SharePreviewButton popupPlacement="bottomRight" />
        </Flex>
      }
      style={{
        background: theme.color.background.primary,
        position: 'fixed',
      }}
      subtitle={
        <Flex style={{ fontSize: theme.variable.fontSize.xs }} gap={theme.variable.spacing.xs}>
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{
              color: theme.color.status.info.text,
            }}
          />
          This is a preview of the survey experience. Please note that your responses will not be
          recorded.
        </Flex>
      }
    >
      <div css={styles.headerBackground} />
      Preview
    </DevExHeader>
  );
};
