import React, { ReactNode } from 'react';
import { Flex } from 'antd';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';

import { DevExSkeleton } from '../components/DevExSkeleton';
import { DevExTabs } from '../components/DevExTabs';

import { DevExPageLayout } from './DevExPageLayout';
import { useDevExTheme } from './DevExThemeContext';

const styles = {
  pageHeader: css`
    padding-bottom: 0;

    .pageHeader__title {
      font-size: ${(props) => props.theme.variable.fontSize.xl};
      font-weight: 500;
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
      min-height: 40px; // same height as a large button
    }

    .pageHeader__tabs {
      .ant-tabs-nav {
        padding-top: ${(props) => props.theme.variable.spacing.sm};
        margin-bottom: -1px;
      }
    }
  `,
};

export type DevExPageHeaderTab = {
  key: string;
  label: string;
  href: string;
};

type DevExPageHeaderProps = {
  title?: ReactNode;
  extra?: ReactNode;
  tabs?: DevExPageHeaderTab[];
  tabKey?: string;
};

export const DevExPageHeader: React.FC<DevExPageHeaderProps> = (props) => {
  const navigate = useNavigate();
  const theme = useDevExTheme();

  return (
    <DevExPageLayout css={styles.pageHeader}>
      <Flex vertical gap={theme.variable.spacing.sm}>
        <Flex justify="space-between" align="center" gap={theme.variable.spacing.md}>
          <Flex align="center" gap={theme.variable.spacing.md} className="pageHeader__title">
            {props.title || <DevExSkeleton width={240} fontSize="xl" noLineHeight />}
          </Flex>
          <Flex gap={theme.variable.spacing.md}>{props.extra}</Flex>
        </Flex>
        {props.tabs && (
          <DevExTabs
            className="pageHeader__tabs"
            items={props.tabs}
            activeKey={props.tabKey}
            onChange={(tabKey) => {
              const href = props.tabs?.find((tab) => tab.key === tabKey)?.href;
              href && navigate(href);
            }}
            loading={!props.title}
          />
        )}
      </Flex>
    </DevExPageLayout>
  );
};
