import React from 'react';
import { Flex } from 'antd';
import { faLineChart, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { css } from 'styled-components';

import { StudyClient, StudySequenceClient } from 'jf/api';
import { DevExPageHeader } from 'jf/common/DevExPageHeader';
import { DevExPageLayout } from 'jf/common/DevExPageLayout';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { useClientQuery } from 'jf/utils/useClientQuery';

import {
  SEQUENCED_STUDY_CARD_MIN_WIDTH,
  SequencedStudyCard,
} from '../studySequence/SequencedStudyCard';

import { AddStudyToSequenceModal } from './AddStudyToSequenceModal';
import { TopicScoreTrendChart } from './TopicScoreTrendChart';

import DemoChartLight from 'jf/images/emptySequenceChart.svg';
import DemoChartDark from 'jf/images/emptySequenceChartDark.svg';

export const StudySequencePage = (props) => {
  const theme = useDevExTheme();

  const { sequenceRef } = useParams<{ sequenceRef: string }>();

  const { data: sequence } = useClientQuery(StudySequenceClient.getStudySequence, { sequenceRef });
  const { data: studies } = useClientQuery(StudyClient.getStudies);

  const sequenceStudies = studies?.filter((study) => study.sequenceRef === sequence?.ref);
  const addStudyModal = useJFOverlay(AddStudyToSequenceModal);

  return (
    <Flex vertical align="center" style={{ flex: 1 }}>
      <DevExPageHeader title={sequence?.name} />

      <DevExPageLayout style={{ flex: 1 }}>
        {sequenceStudies && sequenceStudies.length > 0 && (
          <Flex gap={theme.variable.spacing.md}>
            <DevExButton
              style={{ height: '100%', paddingInline: theme.variable.spacing.md }}
              icon={<FontAwesomeIcon icon={faPlus} />}
              type="dashed"
              onClick={() => addStudyModal.open({ sequenceRef: sequenceRef! })}
            />
            <Flex vertical gap={theme.variable.spacing.sm} style={{ width: '100%' }}>
              <div
                css={css`
                  display: grid;
                  grid-template-columns: repeat(
                    auto-fill,
                    minmax(${SEQUENCED_STUDY_CARD_MIN_WIDTH}, 1fr)
                  );
                  gap: ${(props) => props.theme.variable.spacing.md};
                `}
              >
                {sequenceStudies?.map((study) => (
                  <SequencedStudyCard key={study.ref} study={study} />
                ))}
              </div>
            </Flex>
          </Flex>
        )}

        <DevExCard title="How are topic scores trending?">
          <TopicScoreTrendChart studies={sequenceStudies} />
        </DevExCard>

        <DevExCard style={{ height: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
          <Flex vertical gap={theme.variable.spacing.md} align="center">
            <div
              style={{
                fontSize: theme.variable.fontSize.md,
                lineHeight: theme.variable.lineHeight,
                fontWeight: 600,
                gap: theme.variable.spacing.md,
              }}
            >
              <FontAwesomeIcon
                icon={faLineChart}
                style={{ marginRight: theme.variable.spacing.sm }}
              />
              See how your developer sentiment is evolving
            </div>
            <div>
              Continue your series to see how your scores and priorities are evolving over time.
            </div>
            <DevExButton
              style={{ alignSelf: 'center' }}
              type="primary"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => addStudyModal.open({ sequenceRef: sequenceRef! })}
            >
              Survey
            </DevExButton>
          </Flex>
          {theme.key === 'dark' ? <DemoChartDark /> : <DemoChartLight />}
        </DevExCard>
      </DevExPageLayout>
    </Flex>
  );
};
