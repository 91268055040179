/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { cloneDeep, debounce, isEqual, omit } from 'lodash-es';
import { DateTime } from 'luxon';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { Prompt, StudyClient } from 'jf/api';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import { getStudySteps } from '../study/StudyStateContext';

import { UnsavedTeam } from './teamsEditor/TeamsEditor';

export type UnsavedCustomerPrompt = { topicSlug?: string } & Pick<Prompt, 'slug' | 'text' | 'type'>;

type EditStudyState = {
  name: string;
  notes: string;
  stepIndex: number;
  welcomeMessage: string;
  promptSlugs: string[];
  unsavedCustomerPrompt: UnsavedCustomerPrompt | null;
  unsavedWelcomeMessage: string | null;
  unsavedTeams: UnsavedTeam[] | null;
  flags: string[];
};

const DEFAULT_EDIT_STUDY_STATE: EditStudyState = {
  name: `New Survey ${DateTime.now().toLocaleString({ year: 'numeric' })}`,
  notes: '',
  welcomeMessage: '',
  stepIndex: 0,
  promptSlugs: [],
  unsavedCustomerPrompt: null,
  unsavedWelcomeMessage: null,
  unsavedTeams: null,
  flags: [],
};

interface ESSC extends EditStudyState {
  update: (partialEditStudyState: Partial<EditStudyState>, initial?: boolean) => void;
  saved: boolean;
  loaded: boolean;
}

export const EditStudyStateContext = createContext<ESSC>({
  ...DEFAULT_EDIT_STUDY_STATE,
  update: () => undefined,
  saved: true,
  loaded: false,
});

export const EditStudyStateProvider: React.FC = (props) => {
  const { studyRef } = useParams<{ studyRef: string }>();

  const queryClient = useQueryClient();

  const [savedEditStudyState, setSavedEditStudyState] =
    useState<EditStudyState>(DEFAULT_EDIT_STUDY_STATE);
  const [editStudyState, setEditStudyState] = useState<EditStudyState>(DEFAULT_EDIT_STUDY_STATE);
  const [loaded, setLoaded] = useState(false);

  const { mutateAsync: updateStudy } = useClientMutation(StudyClient.updateStudy);

  const saveStudy = useMemo(
    () =>
      debounce((state: EditStudyState) => {
        // name is a requirement to saving
        if (studyRef && state.name) {
          updateStudy({
            studyRef,
            requestBody: {
              name: state.name,
              notes: state.notes,
              welcomeMessage: state.welcomeMessage,
              sequencedPrompts: state.promptSlugs,
              flags: state.flags,
            },
          }).then(() => {
            queryClient.invalidateQueries('GET_STUDIES');
            queryClient.invalidateQueries(['GET_STUDY', studyRef]);
            queryClient.removeQueries(['GET_PUBLIC_STUDY', studyRef]);
            setSavedEditStudyState(cloneDeep(state));
          });
        }
      }, 2500),
    []
  );

  const updateEditStudyState = (partialEditStudyState: Partial<EditStudyState>) => {
    setEditStudyState((editStudyState) => {
      // discard unsavedCustomerPrompt when changing stepIndex before saving
      if (partialEditStudyState.stepIndex !== undefined && editStudyState.unsavedCustomerPrompt) {
        partialEditStudyState.unsavedCustomerPrompt = null;
      }

      // discard unsavedWelcomeMessage when changing stepIndex before saving
      if (
        partialEditStudyState.stepIndex !== undefined &&
        partialEditStudyState.stepIndex !== 0 &&
        editStudyState.unsavedWelcomeMessage !== null
      ) {
        partialEditStudyState.unsavedWelcomeMessage = null;
      }

      const newEditStudyState = { ...editStudyState, ...partialEditStudyState };

      if (!loaded) {
        setSavedEditStudyState(cloneDeep(newEditStudyState));
        setLoaded(true);
      }

      return newEditStudyState;
    });
  };

  const omittedFields: (keyof EditStudyState)[] = [
    'stepIndex',
    'unsavedWelcomeMessage',
    'unsavedCustomerPrompt',
    'unsavedTeams',
  ];
  const stateChanged = !isEqual(
    omit(editStudyState, omittedFields),
    omit(savedEditStudyState, omittedFields)
  );

  useEffect(() => {
    if (stateChanged) {
      saveStudy(editStudyState);
    } else {
      saveStudy.cancel();
    }
  }, [editStudyState]);

  return (
    <EditStudyStateContext.Provider
      value={{
        ...editStudyState,
        update: updateEditStudyState,
        saved: !stateChanged,
        loaded,
      }}
    >
      {props.children}
    </EditStudyStateContext.Provider>
  );
};

export const useEditStudyState = () => useContext(EditStudyStateContext);

export const useStudyEditorSteps = () => {
  const { studyRef } = useParams<{ studyRef: string }>();

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });
  const editStudyState = useEditStudyState();

  if (!study) {
    return [];
  }

  return getStudySteps({
    ...study,
    prompts: editStudyState.promptSlugs.map((slug) => ({ slug, topicSlug: '' })),
    flags: editStudyState.flags,
  });
};
