import React from 'react';
import {
  faClipboard,
  faClipboardCheck,
  faClipboardQuestion,
  faXmark,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';

import { Study } from 'jf/api';
import { DevExTheme } from 'jf/common/DevExThemeContext';
import { DevExTag } from 'jf/components/DevExTag';

type StudyStatusMeta = {
  label: string;
  color: keyof DevExTheme['color']['tag'];
  icon: IconDefinition;
};

export const STUDY_STATUS_META: { [key in Study.status]: StudyStatusMeta } = {
  DRAFT: {
    label: 'Draft',
    color: 'blue',
    icon: faClipboard,
  },
  OPEN: {
    label: 'Open',
    color: 'yellow',
    icon: faClipboardQuestion,
  },
  CLOSED: {
    label: 'Closed',
    color: 'green',
    icon: faClipboardCheck,
  },
  DELETED: {
    label: 'Deleted',
    color: 'red',
    icon: faXmark,
  },
};

type StudyStateTagProps = {
  status: Study.status;
};

export const StudyStatusTag: React.FC<StudyStateTagProps> = (props) => {
  const meta = STUDY_STATUS_META[props.status];

  return <DevExTag color={meta.color}>{meta.label}</DevExTag>;
};
