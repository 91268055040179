import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { faEye, faEyeSlash, faTrash, faTrashSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { css } from 'styled-components';

import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExInput } from 'jf/components/DevExInput';

import { UnsavedTeam } from './TeamsEditor';

export const teamCardStyles = {
  teamCard: css`
    padding-inline: ${(props) => props.theme.variable.spacing.sm};
    padding-block: ${(props) => props.theme.variable.spacing.xs} !important;
    overflow: hidden;
    color: ${(props) => props.theme.color.text.primary};
    flex: 1;

    &.card--hidden {
      opacity: 0.5;
    }

    &.card--deleted {
      color: ${(props) => props.theme.color.status.error.text};
      background-color: ${(props) => props.theme.color.status.error.background};
      border-color: ${(props) => props.theme.color.status.error.border};
    }

    &.card--added {
      color: ${(props) => props.theme.color.status.success.text};
      background-color: ${(props) => props.theme.color.status.success.background};
      border-color: ${(props) => props.theme.color.status.success.border};
    }

    input.card__nameInput {
      background: none;
      border: none;
      padding: 0;
    }
  `,
};

type ChildTeamCardProps = {
  style?: React.CSSProperties;
  team: UnsavedTeam;
  onDelete?: () => void;
  onAdd?: () => void;
  onRename?: (name: string) => void;
};

export const ChildTeamCard: React.FC<ChildTeamCardProps> = (props) => {
  const [input, setInput] = useState('');

  return (
    <DevExCard
      data-cy="child-team-card"
      css={teamCardStyles.teamCard}
      style={{
        width: '50%',
        ...props.style,
      }}
      className={classNames({
        'card--deleted': props.team.isDeleted,
        'card--hidden': props.team.isHidden,
        'card--added': props.team.ref.startsWith('UNSAVED'),
      })}
      size="small"
      title={
        props.team.value || (
          <DevExInput
            className="card__nameInput"
            placeholder="Unnamed team"
            autoFocus
            onKeyUp={(event) => {
              // clicking Enter triggers adding another team
              if (event.key === 'Enter') {
                (event.target as HTMLInputElement).blur();
                props.onAdd?.();
              }
            }}
            value={input}
            onChange={setInput}
            onBlur={() => {
              // blurring completes team editing
              if (input) {
                props.onRename?.(input);
              } else {
                props.onDelete?.();
              }
            }}
          />
        )
      }
      extra={
        !!props.team.value ? (
          <Tooltip
            title={
              props.team.isEmp
                ? props.team.isHidden
                  ? 'Unhide team'
                  : 'Hide team'
                : props.team.isDeleted
                  ? 'Undelete team'
                  : 'Delete team'
            }
          >
            <DevExButton
              type="text"
              icon={
                <FontAwesomeIcon
                  icon={
                    props.team.isEmp
                      ? props.team.isHidden
                        ? faEyeSlash
                        : faEye
                      : props.team.isDeleted
                        ? faTrashSlash
                        : faTrash
                  }
                />
              }
              onClick={props.onDelete}
            />
          </Tooltip>
        ) : undefined
      }
    />
  );
};
