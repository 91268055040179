import React from 'react';

type GradientTransitionProps = {
  topColor: string;
  bottomColor: string;
  middleColor?: string;
};

export const GradientTransition: React.FC<GradientTransitionProps> = (props) => {
  return (
    <>
      <div
        style={{
          height: '10vh',
          backgroundColor: props.topColor,
        }}
      />
      <div
        style={{
          position: 'absolute',
          zIndex: '2',
          width: '100%',
          height: '30vh',
          background: props.middleColor
            ? `linear-gradient(${props.topColor}, ${props.middleColor}, ${props.bottomColor} 100%)`
            : `linear-gradient(${props.topColor}, ${props.bottomColor} 100%)`,
        }}
      />
    </>
  );
};
