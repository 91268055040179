/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SegmentEvent } from '../models/SegmentEvent';
import type { SegmentEventRequest } from '../models/SegmentEventRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnalyticsClient {

    /**
     * Create analytics for app events.
     * @returns SegmentEvent
     * @throws ApiError
     */
    public static createSegmentEvent({
        requestBody,
    }: {
        requestBody: SegmentEventRequest,
    }): CancelablePromise<SegmentEvent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/analytics',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
