import { keyBy } from 'lodash-es';

import {
  NumericScore,
  NumericScoreWithTeamOrSegment,
  StudyLibraryClient,
  TopicVerbatimWithTeamRef,
} from 'jf/api';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyScores } from '../useStudyScores';
import { useStudyScoresBySegment } from '../useStudyScoresBySegment';
import { useStudyVerbatims } from '../useStudyVerbatims';

type UseTopicResultsProps = {
  studyRef: string | undefined;
  topicSlug: string | undefined;
  teamRef?: string;
  segmentRef?: string;
};

/**
 * Compile the score, prompt scores, and verbatims for a topic in a given study.
 * These results can be filtered by a given `teamRef` and `segmentRef`.
 */
export const useTopicResults = (props: UseTopicResultsProps) => {
  const segmentFilter = !!props.segmentRef;

  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);
  const { data: scores } = useStudyScores({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: ['PROMPT', 'TOPIC'],
  });
  const { data: scoresBySegment } = useStudyScoresBySegment({
    studyRef: segmentFilter ? props.studyRef : undefined, // don't fetch if we don't need it
    teamRef: props.teamRef,
    type: ['PROMPT', 'TOPIC'],
  });
  const { data: topicVerbatims } = useStudyVerbatims({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
  });

  let score: NumericScore | undefined = undefined;
  let promptScores: NumericScoreWithTeamOrSegment[] | undefined = undefined;
  let verbatims: TopicVerbatimWithTeamRef[] | undefined = undefined;

  if (
    !prompts ||
    !scores ||
    (segmentFilter && !scoresBySegment) ||
    !topicVerbatims ||
    !props.topicSlug
  ) {
    return { score, promptScores, votes: verbatims, verbatims };
  }

  const promptsBySlug = keyBy(prompts, 'slug');

  const isSpecifiedTopic = (score: NumericScore) =>
    score.type === NumericScore.type.TOPIC && score.slug === props.topicSlug;

  const isIncludedPrompt = (score: NumericScore) =>
    score.type === NumericScore.type.PROMPT &&
    promptsBySlug[score.slug].topicSlug === props.topicSlug;

  if (segmentFilter) {
    const scoresForSpecifiedSegment = scoresBySegment!.filter(
      (score) => score.segment?.ref === props.segmentRef
    );
    score = scoresForSpecifiedSegment.find(isSpecifiedTopic);
    promptScores = scoresForSpecifiedSegment.filter(isIncludedPrompt);
  } else {
    score = scores.find(isSpecifiedTopic);
    promptScores = scores.filter(isIncludedPrompt);
  }

  promptScores.sort((a, b) => a.scaledAvg - b.scaledAvg);

  verbatims = [];
  if (!props.segmentRef) {
    verbatims = topicVerbatims.filter((verbatim) => verbatim.topicSlug === props.topicSlug);
  }

  return {
    score,
    promptScores,
    votes: verbatims,
    verbatims: verbatims.filter((verbatim) => verbatim.responseText),
  };
};
