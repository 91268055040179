/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';
import type { DevExUser } from '../models/DevExUser';
import type { PatchedCompanyRequest } from '../models/PatchedCompanyRequest';
import type { PatchedDevExUserRequest } from '../models/PatchedDevExUserRequest';
import type { PatchedUpdatedPasswordRequest } from '../models/PatchedUpdatedPasswordRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserClient {

    /**
     * Update user attributes.
     * @returns DevExUser
     * @throws ApiError
     */
    public static updateUser({
        requestBody,
    }: {
        requestBody?: PatchedDevExUserRequest,
    }): CancelablePromise<DevExUser> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update user's company.
     * @returns Company
     * @throws ApiError
     */
    public static updateCompany({
        requestBody,
    }: {
        requestBody?: PatchedCompanyRequest,
    }): CancelablePromise<Company> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/user/company',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update user's password.
     * @returns any No response body
     * @throws ApiError
     */
    public static updateUserPassword({
        requestBody,
    }: {
        requestBody?: PatchedUpdatedPasswordRequest,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/user/password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
