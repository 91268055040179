import React from 'react';
import { Flex } from 'antd';
import Icon from '@ant-design/icons';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, useScroll } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import { css } from 'styled-components';

import { trackEvent } from 'jf/analytics/Analytics';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';

import { MotionDiv } from './MotionDiv';

import JFLogoNoText from 'jf/images/jf-logo-no-text.svg';

const styles = {
  header: css`
    z-index: 1;
    position: sticky;
    width: 100%;
    top: 0px;
    z-index: 3;

    .header__progressbar {
      left: 0;
      right: 0;
      height: 2px;
      background-color: ${(props) => props.theme.color.reveal.header.scroll};
      transform-origin: 0%;
      width: 70%;
      margin: 0 auto;
    }

    .header__logobar {
      align-items: center;
      justify-content: center;
      font-size: ${(props) => props.theme.variable.fontSize.xl};
      padding-bottom: ${(props) => props.theme.variable.fontSize.sm};
      transition: background-color 0.8s;
    }
  `,
};

type RevealHeaderProps = {
  color: string;
  background: string;
};

export const RevealHeader: React.FC<RevealHeaderProps> = (props) => {
  const theme = useDevExTheme();
  const { scrollYProgress } = useScroll();
  const navigate = useNavigate();
  const { studyRef } = useParams<{ studyRef: string }>();

  const onSkip = () => {
    trackEvent('survey-reveal:skip:click', {
      surveyRef: studyRef,
    });
    navigate(`/study/${studyRef}/analyze/insights`);
  };

  const headerVariants = {
    visible: { y: '0px', opacity: 1, transition: { duration: 0.05 } },
    hidden: { y: '-20px', opacity: 0, transition: { duration: 0.05 } },
  };

  return (
    <Flex vertical css={styles.header}>
      <MotionDiv variants={headerVariants} scrollYProgress={scrollYProgress} fadeIn={2}>
        <Flex
          style={{
            width: '100%',
            color: props.color,
            background: props.background,
            paddingTop: theme.variable.spacing.lg,
          }}
          className="header__logobar"
          gap="53%"
          align="center"
        >
          <Flex align="center">
            <Icon component={JFLogoNoText} style={{ paddingInline: theme.variable.spacing.xs }} />
            DevEx
          </Flex>
          {window.dx.user?.isCustomerAdmin && (
            <DevExButton
              style={{ fontSize: theme.variable.fontSize.md, color: props.color }}
              iconPosition="right"
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              onClick={() => onSkip()}
            >
              Skip to raw results
            </DevExButton>
          )}
        </Flex>
      </MotionDiv>
      <motion.div className="header__progressbar" style={{ scaleX: scrollYProgress }} />
    </Flex>
  );
};
