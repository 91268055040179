import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const areEqual = <T>(valueA: T, valueB: T) => {
  // sort arrays before comparison
  if (Array.isArray(valueA) && Array.isArray(valueB)) {
    return JSON.stringify([...valueA].sort()) === JSON.stringify([...valueB].sort());
  }

  return JSON.stringify(valueA) === JSON.stringify(valueB);
};

/**
 * Create a live useState that can be updated via prop and will notify a callback when an update oocurs.
 *
 * @param propsValue props.value
 * @param propsOnValueChange props.onValueChange
 */
export const useLivePropState = <T>(
  propsValue: T,
  propsOnValueChange?: (value: T) => void,
  defaultValue?: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(propsValue ?? (defaultValue as T));

  // notify parent of value change through props.onValueChange
  // (if value and props.value aren't the same already)
  useEffect(() => {
    if (!areEqual(propsValue, value)) {
      propsOnValueChange?.(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // update value from props.value
  // (if value and props.value aren't the same already)
  useEffect(() => {
    if (!areEqual(propsValue, value)) {
      setValue(propsValue ?? (defaultValue as T));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsValue]);

  return [value, setValue];
};
