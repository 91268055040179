import { StudyClient } from 'jf/api';
import { useClientQuery } from 'jf/utils/useClientQuery';

type UseStudyParticipationProps = {
  studyRef: string | undefined;
  teamRef?: string;
};

/**
 * Fetch study participation stats with optional `teamRef`.
 */
export const useStudyParticipation = (props: UseStudyParticipationProps) => {
  const studyParticipationQuery = useClientQuery(StudyClient.getStudyParticipation, {
    studyRef: !props.teamRef ? props.studyRef : undefined, // don't send query if not needed
  });
  const studyParticipationForTeamQuery = useClientQuery(StudyClient.getStudyParticipationForTeam, {
    studyRef: props.studyRef,
    teamRef: props.teamRef,
  });

  return props.teamRef ? studyParticipationForTeamQuery : studyParticipationQuery;
};
