import React, { CSSProperties } from 'react';
import { Flex } from 'antd';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from 'styled-components';

import { StudyScoreClient, TopicVerbatimWithTeamRef } from 'jf/api';
import { DevExCard } from 'jf/components/DevExCard';
import { useClientQuery } from 'jf/utils/useClientQuery';

const styles = {
  verbatim: css`
    width: 100%;
    margin-block: 3px; // compensate for absolutely-positioned quotes

    .verbatim__quote {
      position: absolute;
      color: ${(props) => props.theme.color.text.secondary};
      padding: 0 ${(props) => props.theme.variable.spacing.sm};
      background: ${(props) => props.theme.color.card.background};
      gap: ${(props) => props.theme.variable.spacing.sm};

      &:first-child {
        top: -${(props) => props.theme.variable.spacing.sm};
        left: ${(props) => props.theme.variable.spacing.xs};
      }

      &:last-child {
        bottom: -${(props) => props.theme.variable.spacing.sm};
        right: ${(props) => props.theme.variable.spacing.xs};
      }

      svg {
        font-size: ${(props) => props.theme.variable.fontSize.lg};
        color: ${(props) => props.theme.color.text.tertiary};
      }
    }

    .verbatim__text {
      line-height: ${(props) => props.theme.variable.lineHeight};
      word-break: break-word;
      color: ${(props) => props.theme.color.text.primary};
    }

    .verbatim__truncate {
      display: -webkit-box;
      line-height: ${(props) => props.theme.variable.lineHeight};
      color: ${(props) => props.theme.color.text.primary};
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      width: 400px;
      height: 75px;
      overflow: hidden;
      text-align: left;
    }
  `,
};

type PromptVerbatimCardProps = {
  studyRef: string | undefined;
  verbatim: TopicVerbatimWithTeamRef;
  style?: CSSProperties;
  truncate?: boolean;
};

export const PromptVerbatimCard: React.FC<PromptVerbatimCardProps> = (props) => {
  const { data: scoredTeams } = useClientQuery(StudyScoreClient.getStudyScoredTeams, {
    studyRef: props.studyRef,
  });

  const attributionLabel = scoredTeams?.find((team) => team.ref === props.verbatim.teamRef)?.value;

  return (
    <DevExCard css={styles.verbatim} style={props.style}>
      <div className="verbatim__quote">
        <FontAwesomeIcon icon={faQuoteLeft} />
      </div>

      <div className={props.truncate ? 'verbatim__truncate' : 'verbatim__text'}>
        {props.verbatim.responseText}
      </div>

      <Flex className="verbatim__quote" align="center">
        {attributionLabel ? `— ${attributionLabel}` : ''}
        <FontAwesomeIcon icon={faQuoteRight} />
      </Flex>
    </DevExCard>
  );
};
