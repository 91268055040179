/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';

// enable storing numbers in PageStorage
export const NumberStorageState = (storageState: StorageState<number>): StorageState<string> => {
  const [state, setState, disabled] = storageState;
  return [state.toString(), (n: string) => setState(parseInt(n) || state), disabled];
};

// enable storing number arrays in PageStorage
export const NumberArrayStorageState = (
  storageState: StorageState<number[]>
): StorageState<string[]> => {
  const [_state, setState, disabled] = storageState;
  const state = useMemo(() => _state.map((n) => n.toString()), [_state]);
  return [state, (arr: string[]) => setState(arr.map((n) => parseInt(n))), disabled];
};

export type StorageStateType = string | string[] | Record<string, unknown> | undefined;

/**
 * Defines a reactive state to be stored in *LocalStorage* and URL query params.
 */
export type StorageState<T = StorageStateType> = [
  T, // state
  Dispatch<SetStateAction<T>> | ((state: T) => void), // setState
  boolean?, // disabled for URl query params
];

export type StorageStates = {
  [stateKey: string]: StorageState;
};

const compileStorageStates = (storageStates: StorageStates) =>
  _.mapValues(storageStates, (storageState) => storageState[0]);

/**
 * Hook to bind a series of reactive states to *LocalStorage* and URL query params.
 */
export const usePageStorage = <T extends { [stateKey: string]: StorageStateType }>(
  storageStates: StorageStates,
  localStorageKey?: string,
  urlEnabled = true,
  hideInitialUrl = true,
  onSave?: () => void
) => {
  const [pageStateLoaded, setPageStateLoaded] = useState(false);
  const [initialStates] = useState(compileStorageStates(storageStates)); // capture initial state

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const updatePageState = (states: Partial<T>) => {
    for (const [stateKey, newState] of Object.entries(states)) {
      if (storageStates[stateKey]) {
        const [state, setState] = storageStates[stateKey];

        if (JSON.stringify(state) !== JSON.stringify(newState)) {
          setState(newState);
        }
      }
    }
  };

  const replacePageState = (states: Partial<T>) => updatePageState({ ...initialStates, ...states });

  const resetPageState = () => replacePageState({});

  // reduce storage states to an array of strings we can use as a dependency
  const states = Object.values(storageStates).map(
    (storageState) => `${storageState[0]}:${storageState[2] ? 'disabled' : 'enabled'}`
  );

  const pageState = useMemo(() => compileStorageStates(storageStates), [...states]) as T;

  // load state on first render
  useEffect(() => {
    const stateInUrl = Object.keys(storageStates).some((stateKey) => searchParams.get(stateKey));

    // URL takes loading precedence over LocalStorage
    if (stateInUrl && urlEnabled) {
      loadFromUrl(storageStates, initialStates);
    } else if (localStorageKey) {
      loadFromLocalStorage(storageStates, localStorageKey);
    }

    // delay pageStateLoaded by a render cycle to ensure setState fully propagates
    setTimeout(() => setPageStateLoaded(true), 0);
  }, []);

  // update URL and LocalStorage when any state changes
  useEffect(() => {
    if (pageStateLoaded) {
      if (urlEnabled) {
        addToUrl(storageStates, initialStates, navigate, hideInitialUrl);
      }
      if (localStorageKey) {
        addToLocalStorage(storageStates, localStorageKey);
        onSave?.();
      }
    }
  }, [...states, pageStateLoaded, window.location.pathname]);

  return { pageState, pageStateLoaded, updatePageState, replacePageState, resetPageState };
};

const loadFromUrl = (
  storageStates: StorageStates,
  initialStates: { [stateKey: string]: StorageStateType }
) => {
  const searchParams = new URLSearchParams(window.location.search);

  for (const stateKey of Object.keys(storageStates)) {
    const urlState = searchParams.get(stateKey);

    if (urlState) {
      const setState = storageStates[stateKey][1];

      if (urlState.includes(',') || Array.isArray(initialStates[stateKey])) {
        // parse array-like param
        setState(urlState.split(','));
      } else {
        // parse basic param
        setState(urlState);
      }
    }
  }
};

const updateSearchParam = (
  searchParams: URLSearchParams,
  key: string,
  value?: StorageStateType
) => {
  if (value) {
    if (Array.isArray(value)) {
      // stringify array values with a comma delimiter
      searchParams.set(key, value.join(','));
    } else {
      // stringify non-array values
      searchParams.set(key, `${value}`);
    }
  } else {
    searchParams.delete(key);
  }
};

const addToUrl = (
  storageStates: StorageStates,
  initialStates: { [stateKey: string]: StorageStateType },
  navigate: NavigateFunction,
  hideInitialUrl: boolean
) => {
  const { pathname } = window.location;

  const searchParams = new URLSearchParams(window.location.search);

  for (const [stateKey, storageState] of Object.entries(storageStates)) {
    const state = storageState[0];
    const disabled = !!storageState[2];

    const isInitial = JSON.stringify(state) === JSON.stringify(initialStates[stateKey]);

    if ((!hideInitialUrl || !isInitial) && !disabled) {
      // add the param
      updateSearchParam(searchParams, stateKey, state);
    } else {
      // remove the param (when disabled or when value is same as initial)
      updateSearchParam(searchParams, stateKey, undefined);
    }
  }

  navigate(`${pathname}?${searchParams}`, {
    replace: true,
  });
};

const loadFromLocalStorage = (storageStates: StorageStates, key: string) => {
  const localStorageItem = localStorage.getItem(key);
  const localStorageState = localStorageItem ? JSON.parse(localStorageItem) : undefined;

  if (localStorageState) {
    for (const stateKey of Object.keys(storageStates)) {
      if (localStorageState[stateKey]) {
        const setState = storageStates[stateKey][1];
        setState(localStorageState[stateKey]);
      }
    }
  }
};

const addToLocalStorage = (storageStates: StorageStates, key: string) => {
  localStorage.setItem(key, JSON.stringify(compileStorageStates(storageStates)));
};
