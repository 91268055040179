import React from 'react';
import { Flex, Grid } from 'antd';
import { css } from 'styled-components';

import { PublicStudyClient, Study } from 'jf/api';
import { DevExPageTitle } from 'jf/common/DevExPageTitle';
import { FlatTentacles } from 'jf/components/FlatTentacles';
import { SimpleTransition } from 'jf/components/SimpleTransition';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { StudyEnd } from './StudyEnd';
import { STUDY_PROGRESS_SIDEBAR_WIDTH, StudyProgressSidebar } from './StudyProgressSidebar';
import { StudyQuestions } from './StudyQuestions';
import { StudyStart } from './StudyStart';
import { useStudyState } from './StudyStateContext';

const { useBreakpoint } = Grid;

export const StudyPage: React.FC = () => {
  const studyState = useStudyState();
  const { data: study } = useClientQuery(PublicStudyClient.getPublicStudy, {
    studyRef: studyState.refs.studyRef,
  });

  const breakpoint = useBreakpoint();

  const stages = [
    <StudyStart key="start" />,
    <StudyQuestions key="questions" />,
    <StudyEnd key="end" />,
  ];

  return (
    <Flex
      vertical
      style={{
        flex: 1,
        // compensate for PreviewStudyHeader
        paddingTop: study?.status === Study.status.DRAFT ? 107 : undefined,
      }}
    >
      <DevExPageTitle>{`Survey ${study?.name ?? ''}`}</DevExPageTitle>
      <SimpleTransition
        name="tentacles-left"
        timeout={500}
        in={studyState.stageIndex !== 1}
        cssInit={css`
          transform: translateX(0);
        `}
        cssExit={css`
          transform: translateX(-400px);
        `}
      >
        <FlatTentacles placement="topLeft" />
      </SimpleTransition>

      <SimpleTransition
        name="tentacles-right"
        timeout={500}
        in={studyState.stageIndex !== 1}
        cssInit={css`
          transform: translateX(0);
        `}
        cssExit={css`
          transform: translateX(400px);
        `}
      >
        <FlatTentacles placement="bottomRight" />
      </SimpleTransition>

      <SimpleTransition
        name="progressSidebar"
        timeout={500}
        in={studyState.stageIndex === 1}
        cssInit={css`
          transform: translateX(0);
        `}
        cssExit={css`
          transform: ${breakpoint.xl
            ? `translateX(-${STUDY_PROGRESS_SIDEBAR_WIDTH}px)`
            : 'translateY(-160px)'};
        `}
      >
        <StudyProgressSidebar />
      </SimpleTransition>

      <Flex align="center" justify="center" style={{ flex: 1 }}>
        <div style={{ width: 'min(800px, 90vw)' }}>
          {stages.map((stage, i) => (
            <SimpleTransition
              key={i}
              name={`studyStage${i}`}
              timeout={500}
              in={studyState.stageIndex === i}
              inDelay={500 + 100} // wait for last stage to exit, then wait 100ms
              cssInit={css`
                opacity: 1;
                transform: translateY(0);
              `}
              cssExit={css`
                opacity: 0;
                transform: translateY(-100px);
              `}
              cssEnter={css`
                opacity: 0;
                transform: translateY(100px);
              `}
            >
              {stage}
            </SimpleTransition>
          ))}
        </div>
      </Flex>
    </Flex>
  );
};
