import React from 'react';
import { css } from 'styled-components';

const styles = {
  title: css`
    margin: 0;
    font-size: max(36px, min(${(props) => props.theme.variable.fontSize.xxl}, 5vw));
    text-align: center;
    line-height: 1.25;
    font-family: ${(props) => props.theme.variable.fontFamily.secondary};
    font-weight: 500;

    span {
      font-weight: 600;
    }
  `,
};

export const StudyTitle: React.FC = (props) => <h1 css={styles.title}>{props.children}</h1>;
