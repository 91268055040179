import React, { useState } from 'react';
import { merge } from 'lodash-es';

import { CreatedStudyRequest, CreatedStudySequenceRequest } from 'jf/api';

import { UnsavedTeam } from '../editStudy/teamsEditor/TeamsEditor';

import { CreateStudyPage } from './CreateStudyPage';

type CreateStudyState = {
  stepIndex: number;
  studyToCreate?: CreatedStudyRequest;
  studySequenceToCreate?: CreatedStudySequenceRequest;
  teamsToCreate?: UnsavedTeam[];
};

type _CreateStudyStateContext = CreateStudyState & {
  update: (partialState: Partial<CreateStudyState>) => void;
};

const DEFAULT_CREATE_STUDY_STATE = {
  stepIndex: 0,
};

const CreateStudyStateContext = React.createContext<_CreateStudyStateContext>({
  ...DEFAULT_CREATE_STUDY_STATE,
  update: () => {},
});

export const useCreateStudyState = () => React.useContext(CreateStudyStateContext);

export const CreateStudyController: React.FC = () => {
  const [state, setState] = useState<CreateStudyState>(DEFAULT_CREATE_STUDY_STATE);

  const updateState = (partialState: Partial<CreateStudyState>) => {
    setState((state) => merge({ ...state, ...partialState }));
  };

  return (
    <CreateStudyStateContext.Provider
      value={{
        ...state,
        update: updateState,
      }}
    >
      <CreateStudyPage />
    </CreateStudyStateContext.Provider>
  );
};
