/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatedCustomerPrompt } from '../models/CreatedCustomerPrompt';
import type { CreatedCustomerPromptRequest } from '../models/CreatedCustomerPromptRequest';
import type { CreatedStudy } from '../models/CreatedStudy';
import type { CreatedStudyRequest } from '../models/CreatedStudyRequest';
import type { ShareLink } from '../models/ShareLink';
import type { ShareStudyRequestRequest } from '../models/ShareStudyRequestRequest';
import type { Study } from '../models/Study';
import type { StudyAnalysisSegment } from '../models/StudyAnalysisSegment';
import type { StudyAnalysisSegmentRequest } from '../models/StudyAnalysisSegmentRequest';
import type { StudyParticipation } from '../models/StudyParticipation';
import type { UpdatedCustomerPromptRequest } from '../models/UpdatedCustomerPromptRequest';
import type { UpdatedStudyRequest } from '../models/UpdatedStudyRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StudyClient {

    /**
     * Create a customer prompt.
     * @returns CreatedCustomerPrompt
     * @throws ApiError
     */
    public static createCustomerPrompt({
        requestBody,
    }: {
        requestBody: CreatedCustomerPromptRequest,
    }): CancelablePromise<CreatedCustomerPrompt> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/customer/prompt',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update a customer prompt.
     * @returns void
     * @throws ApiError
     */
    public static updateCustomerPrompt({
        slug,
        requestBody,
    }: {
        slug: string,
        requestBody?: UpdatedCustomerPromptRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/customer/prompt/{slug}',
            path: {
                'slug': slug,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get private study metadata for administrative views.
     * @returns Study
     * @throws ApiError
     */
    public static getStudies(): CancelablePromise<Array<Study>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/studies',
        });
    }

    /**
     * Create a study
     * @returns CreatedStudy
     * @throws ApiError
     */
    public static createStudy({
        requestBody,
    }: {
        requestBody: CreatedStudyRequest,
    }): CancelablePromise<CreatedStudy> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/study',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a study
     * @returns Study
     * @throws ApiError
     */
    public static getStudy({
        studyRef,
    }: {
        studyRef: string,
    }): CancelablePromise<Study> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}',
            path: {
                'studyRef': studyRef,
            },
        });
    }

    /**
     * Update a study
     * @returns void
     * @throws ApiError
     */
    public static updateStudy({
        studyRef,
        requestBody,
    }: {
        studyRef: string,
        requestBody?: UpdatedStudyRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/study/{studyRef}',
            path: {
                'studyRef': studyRef,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a drafted study
     * @returns Study
     * @throws ApiError
     */
    public static deleteStudy({
        studyRef,
    }: {
        studyRef: string,
    }): CancelablePromise<Study> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/study/{studyRef}',
            path: {
                'studyRef': studyRef,
            },
        });
    }

    /**
     * Update values for study analysis segments.
     * @returns StudyAnalysisSegment
     * @throws ApiError
     */
    public static updateAnalysisSegments({
        studyRef,
        requestBody,
    }: {
        studyRef: string,
        requestBody: StudyAnalysisSegmentRequest,
    }): CancelablePromise<StudyAnalysisSegment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/study/{studyRef}/analysis_segments',
            path: {
                'studyRef': studyRef,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a study's participation stats.
     * @returns StudyParticipation
     * @throws ApiError
     */
    public static getStudyParticipation({
        studyRef,
    }: {
        studyRef: string,
    }): CancelablePromise<StudyParticipation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/participation',
            path: {
                'studyRef': studyRef,
            },
        });
    }

    /**
     * Create a link that can be used to share study results with others.
     * @returns ShareLink
     * @throws ApiError
     */
    public static shareStudy({
        studyRef,
        requestBody,
    }: {
        studyRef: string,
        requestBody?: ShareStudyRequestRequest,
    }): CancelablePromise<ShareLink> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/study/{studyRef}/share',
            path: {
                'studyRef': studyRef,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a study's participation stats for a specific team.
     * @returns StudyParticipation
     * @throws ApiError
     */
    public static getStudyParticipationForTeam({
        studyRef,
        teamRef,
    }: {
        studyRef: string,
        teamRef: string,
    }): CancelablePromise<StudyParticipation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/team/{teamRef}/participation',
            path: {
                'studyRef': studyRef,
                'teamRef': teamRef,
            },
        });
    }

}
