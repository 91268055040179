/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Flex, InputRef } from 'antd';
import { faCheck, faChevronRight, faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keyBy, mapValues } from 'lodash-es';
import { css } from 'styled-components';

import { Prompt, StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExEmpty } from 'jf/components/DevExEmpty';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExMenuItem, DevExMenuItemProps } from 'jf/components/DevExMenuItem';
import { DevExModal } from 'jf/components/DevExModal';
import { DevExTag } from 'jf/components/DevExTag';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useEditStudyState, useStudyEditorSteps } from './EditStudyStateContext';
import { PromptIcon } from './PromptIcon';

const styles = {
  prompts: css`
    padding: 0;

    .card__body {
      flex-direction: row;
      gap: 0;
    }

    .prompts__sections {
      border-right: 1px solid ${(props) => props.theme.color.card.border};
      width: 280px;
      height: 420px;

      > div {
        padding: ${(props) => props.theme.variable.spacing.sm};

        &:not(:last-child) {
          border-bottom: 1px solid ${(props) => props.theme.color.card.border};
        }
      }
    }

    .prompts__list {
      padding: ${(props) => props.theme.variable.spacing.sm};
      flex: 1;
      height: 420px;
      overflow-y: auto;
    }
    .checkMark {
      &.checkMark--hidden {
        visibility: hidden;
      }
    }
  `,
};

const SectionItem: React.FC<DevExMenuItemProps> = (props) => (
  <DevExMenuItem {...props}>
    <span>{props.children}</span>
    <FontAwesomeIcon icon={faChevronRight} />
  </DevExMenuItem>
);

export const PromptsModal: React.FC = () => {
  const modal = useJFOverlay(PromptsModal);
  const theme = useDevExTheme();
  const editStudyState = useEditStudyState();
  const searchInputRef = useRef<InputRef>(null);

  const [searchQuery, setSearchQuery] = useState('');

  const [section, setSection] = useState<Prompt.scope>(Prompt.scope.CANONICAL);
  const [topicSlug, setTopicSlug] = useState<string>();
  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);
  const { data: promptPoplarity } = useClientQuery(StudyLibraryClient.getPromptPopularity);
  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);

  const studySteps = useStudyEditorSteps();

  const numberedStepEndIndex =
    studySteps.length - [...studySteps].reverse().findIndex((step) => step.type === 'PROMPT') - 1;

  const popularityByPrompt = mapValues(keyBy(promptPoplarity, 'slug'), 'popularity');
  const topicsBySlug = keyBy(topics, 'slug');

  // sort prompts by popularity
  const canonicalPrompts = useMemo(() => {
    if (!prompts || !promptPoplarity) {
      return [];
    }
    return prompts
      .filter((prompt) => prompt.scope === Prompt.scope.CANONICAL)
      .sort((a, b) => (popularityByPrompt[b.slug] ?? 0) - (popularityByPrompt[a.slug] ?? 0));
  }, [prompts, promptPoplarity]);

  const customerPrompts =
    prompts?.filter(
      (prompt) =>
        prompt.scope === Prompt.scope.CUSTOMER &&
        // only show customer prompts that are frozen or included in this study
        (prompt.frozen || editStudyState.promptSlugs.includes(prompt.slug))
    ) ?? [];

  const renderPrompts = (prompts: Prompt[]) => {
    const filteredPrompts = prompts.filter((prompt) => {
      const topicMatch =
        section !== Prompt.scope.CANONICAL || !topicSlug || topicSlug === prompt.topicSlug;
      const searchQueryMatch = prompt.text.toLowerCase().includes(searchQuery.toLowerCase());
      return topicMatch && searchQueryMatch;
    });

    if (!filteredPrompts.length) {
      const topic = section === Prompt.scope.CANONICAL && topicSlug && topicsBySlug[topicSlug];
      const label = `No ${section === Prompt.scope.CANONICAL ? 'template' : 'custom'} questions${topic ? ` for ${topic.label}` : ''} match your search.`;
      return (
        <DevExEmpty
          iconName={'magnifying-glass'}
          label={label}
          style={{ paddingTop: theme.variable.spacing.xl }}
        />
      );
    }

    return (
      <Flex vertical gap={theme.variable.spacing.xs}>
        {filteredPrompts.map((prompt) => {
          const active = editStudyState.promptSlugs.includes(prompt.slug);
          return (
            <DevExMenuItem
              key={prompt.slug}
              active={active}
              onClick={() => {
                if (active) {
                  // remove prompt
                  editStudyState.update({
                    promptSlugs: editStudyState.promptSlugs.filter((slug) => slug !== prompt.slug),
                    stepIndex: Math.min(editStudyState.stepIndex, numberedStepEndIndex - 1),
                  });
                } else {
                  // add prompt
                  editStudyState.update({
                    promptSlugs: [...editStudyState.promptSlugs, prompt.slug],
                    stepIndex: numberedStepEndIndex + 1,
                  });
                }
              }}
            >
              <Flex gap={theme.variable.spacing.sm} align="center">
                <PromptIcon type={prompt.type} style={{ fontSize: 18 }} />
                <span>{prompt.text}</span>
              </Flex>
              <Flex gap={theme.variable.spacing.sm} align="center">
                {(!topicSlug || section === Prompt.scope.CUSTOMER) && (
                  <DevExTag>{topicsBySlug[prompt.topicSlug]?.label}</DevExTag>
                )}
                <FontAwesomeIcon
                  icon={faCheck}
                  className={`checkMark ${!active ? 'checkMark--hidden' : ''}`}
                />
              </Flex>
            </DevExMenuItem>
          );
        })}
      </Flex>
    );
  };

  // clear state on close
  useEffect(() => {
    setSection(Prompt.scope.CANONICAL);
    setTopicSlug(undefined);
    setSearchQuery('');

    if (modal.isOpened) {
      // without setTimeout, this only works on fresh load and I'm not sure why...
      setTimeout(() => searchInputRef.current?.focus(), 0);
    }
  }, [modal.isOpened]);

  return (
    <DevExModal
      open={modal.isOpened}
      onCancel={() => modal.close()}
      title="Question Library"
      footer={null}
      width={1000}
    >
      <Flex vertical gap={theme.variable.spacing.md}>
        <DevExInput
          ref={searchInputRef}
          addonBefore={<FontAwesomeIcon icon={faSearch} />}
          placeholder="Search questions"
          value={searchQuery}
          onChange={setSearchQuery}
        />
        <DevExCard css={styles.prompts}>
          <Flex vertical className={'prompts__sections'}>
            <div>
              <SectionItem
                active={section === Prompt.scope.CANONICAL && !topicSlug}
                onClick={() => {
                  setSection(Prompt.scope.CANONICAL);
                  setTopicSlug(undefined);
                }}
              >
                Template Questions
              </SectionItem>
            </div>

            <Flex vertical gap={theme.variable.spacing.sm} style={{ flex: 1, overflow: 'auto' }}>
              <div style={{ color: theme.color.text.tertiary }}>Template Questions by Topic</div>
              {topics?.map((topic) => (
                <SectionItem
                  key={topic.slug}
                  active={section === Prompt.scope.CANONICAL && topicSlug === topic.slug}
                  onClick={() => {
                    setSection(Prompt.scope.CANONICAL);
                    setTopicSlug(topic.slug);
                  }}
                >
                  {topic.label}
                </SectionItem>
              ))}
            </Flex>

            <div>
              <SectionItem
                active={section === Prompt.scope.CUSTOMER}
                onClick={() => setSection(Prompt.scope.CUSTOMER)}
              >
                Custom Questions
              </SectionItem>
            </div>
          </Flex>

          <Flex vertical className={'prompts__list'} gap={theme.variable.spacing.md}>
            {section === Prompt.scope.CANONICAL && renderPrompts(canonicalPrompts)}

            {section === Prompt.scope.CUSTOMER && (
              <>
                {customerPrompts.length ? (
                  renderPrompts(customerPrompts)
                ) : (
                  <DevExEmpty
                    iconName="message-question"
                    label="You have not created any custom questions."
                    style={{ paddingTop: theme.variable.spacing.xl }}
                  />
                )}
                <Flex justify="center">
                  <DevExButton
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    type="primary"
                    onClick={() => {
                      editStudyState.update({
                        unsavedCustomerPrompt: {
                          slug: '',
                          text: '',
                          type: Prompt.type.RATING_5L,
                        },
                      });
                      modal.close(true);
                    }}
                  >
                    Add custom question
                  </DevExButton>
                </Flex>
              </>
            )}
          </Flex>
        </DevExCard>
      </Flex>
    </DevExModal>
  );
};
