import React, { useEffect } from 'react';
import { motion, MotionValue, useAnimation, Variants } from 'framer-motion';
import { CSSProperties } from 'styled-components';

type RevealDivProps = {
  variants: Variants;
  scrollYProgress: MotionValue<number>;
  fadeIn: number;
  fadeOut?: number;
  style?: CSSProperties;
  className?: string;
};

export const MotionDiv: React.FC<RevealDivProps> = (props) => {
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = (value) => {
      const currentValue = Math.floor(value * 100);

      if (props.fadeOut) {
        if (currentValue >= props.fadeIn && currentValue < props.fadeOut) {
          controls.start('visible');
        } else if (currentValue >= props.fadeOut) {
          controls.start('remove');
        } else {
          controls.start('hidden');
        }
      } else {
        if (currentValue >= props.fadeIn) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      }
    };

    props.scrollYProgress.onChange(handleScroll);
  }, [props.scrollYProgress, props.fadeIn, props.fadeOut, controls]);
  return (
    <motion.div
      style={props.style}
      initial="hidden"
      animate={controls}
      variants={props.variants}
      className={props.className}
    >
      {props.children}
    </motion.div>
  );
};
