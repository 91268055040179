/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties, useEffect, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';

// replace lottie colors in order of layer shapes
const replaceColors = (animationData: any, colors: string[]) => {
  const definitions: any[] = [];

  // find all color definitions in layer shapes
  for (const layer of animationData.layers) {
    for (const shape of layer.shapes) {
      if (shape.it) {
        for (const it of shape.it) {
          // look for fill and stroke definitions
          if (it.ty === 'fl' || it.ty === 'st') {
            definitions.push(it);
          }
        }
      }
    }
  }

  // for each custom color, update the corresponding definition
  colors.forEach((color, i) => {
    const def = definitions[i];
    if (def) {
      // seperate out rgba values from hex
      const rgba = color.slice(1).match(/.{1,2}/g);

      if (rgba) {
        // convert from hexadecimal to intensity (FF => 255 => 1)
        const intensity = rgba.map((hex) => parseInt(hex, 16) / 255);

        def.c.k = [...intensity.slice(0, 3), 1]; // update rgb color
        def.o.k = intensity[3] * 100; // update alpha
      }
    }
  });
};

type DevExLottieProps = {
  data: any;
  loop?: boolean;
  style?: CSSProperties;
  colors?: string[]; // hex colors to replace lottie colors with (in order of layers)
};

export const DevExLottie: React.FC<DevExLottieProps> = (props) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let animation: AnimationItem | undefined = undefined;

    if (props.colors) {
      replaceColors(props.data, props.colors);
    }

    if (container.current) {
      animation = lottie.loadAnimation({
        container: container.current,
        renderer: 'svg',
        loop: props.loop ?? false,
        autoplay: true,
        animationData: props.data,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid',
        },
      });
    }

    return () => animation?.destroy();
  }, []);

  return <div ref={container} style={{ height: 40, ...props.style }} />;
};
