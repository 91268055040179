import React, { AnchorHTMLAttributes, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';

const styles = {
  link: css`
    color: ${(props) => props.theme.color.link.text};
    transition: none;

    &:hover {
      color: ${(props) => props.theme.color.link.active.text};
    }
  `,
};

type DevExLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  onClick?: () => void;
};

export const DevExLink: React.FC<DevExLinkProps> = (props) => {
  const navigate = useNavigate();

  const onClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (props.href) {
      if (props.target === '_blank') {
        window.open(props.href, props.target);
      } else if (props.href.startsWith('http')) {
        window.location.href = props.href;
      } else {
        navigate(props.href);
      }
    }

    props.onClick?.();
  };

  return (
    <a css={styles.link} {...props} onClick={onClick}>
      {props.children}
    </a>
  );
};
