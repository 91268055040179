import React from 'react';
import { Flex, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Study } from 'jf/api';
import { DevExTheme, useDevExTheme } from 'jf/common/DevExThemeContext';

import { STUDY_STATUS_META } from './StudyStatusTag';

const BUBBLE_SIZE = 32;

const isArray = <T,>(arg: T | T[]): arg is T[] => Array.isArray(arg);

type StudyStatusBubbleProps = {
  study: Study | Study[];
  tooltip?: boolean;
  style?: React.CSSProperties;
  className?: string;
};

export const StudyStatusBubble: React.FC<StudyStatusBubbleProps> = (props) => {
  const theme = useDevExTheme();

  let color: keyof DevExTheme['color']['tag'] = 'default';
  if (!isArray(props.study)) {
    color = STUDY_STATUS_META[props.study.status].color;
  }
  const colors = theme.color.tag[color];

  return (
    <Tooltip
      title={
        props.tooltip &&
        (isArray(props.study) ? (
          <div>
            {props.study.map((study, i) => (
              <div key={i}>{study.name}</div>
            ))}
          </div>
        ) : (
          props.study.name
        ))
      }
    >
      <Flex
        align="center"
        justify="center"
        style={{
          background: colors.background,
          color: colors.text,
          height: BUBBLE_SIZE,
          width: BUBBLE_SIZE,
          minWidth: BUBBLE_SIZE,
          borderRadius: BUBBLE_SIZE,
          fontSize: theme.variable.fontSize[isArray(props.study) ? 'sm' : 'md'],
          ...props.style,
        }}
        className={props.className}
      >
        {isArray(props.study) ? (
          `+${props.study.length}`
        ) : (
          <FontAwesomeIcon icon={STUDY_STATUS_META[props.study.status].icon} />
        )}
      </Flex>
    </Tooltip>
  );
};
