import React from 'react';
import { ConfigProvider as AntThemeProvider } from 'antd';
import { ThemeConfig as AntTheme } from 'antd/lib';

import { useDevExTheme } from './DevExThemeContext';

export const DevExAntConfigProvider: React.FC = (props) => {
  const theme = useDevExTheme();

  const antTheme: AntTheme = {
    token: {
      fontFamily: theme.variable.fontFamily.primary,
      colorText: theme.color.text.primary,
      colorTextSecondary: theme.color.text.primary,
      colorTextTertiary: theme.color.text.secondary,
      colorTextQuaternary: theme.color.text.tertiary,
      colorBgContainer: theme.color.card.background,
      colorBgElevated: theme.color.card.background,
      colorBorder: theme.color.card.border,
      colorBorderSecondary: theme.color.card.border,
      colorPrimary: theme.color.accent.primary,
      colorPrimaryHover: theme.color.accent.primary,
      controlOutline: 'transparent',
      controlItemBgHover: theme.color.accent.aura.primary,
      controlItemBgActive: theme.color.accent.aura.primary,
      boxShadow: `0 0 ${theme.variable.spacing.md} ${theme.color.card.shadow}`,
      boxShadowSecondary: `0 0 ${theme.variable.spacing.md} ${theme.color.card.shadow}`,
    },
  };

  return <AntThemeProvider theme={antTheme}>{props.children}</AntThemeProvider>;
};
