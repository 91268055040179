import React from 'react';
import { Collapse, CollapseProps } from 'antd';
import { css } from 'styled-components';

type DevExCollapseProps = {} & Omit<CollapseProps, 'bordered'>;

const styles = {
  collapse: css`
    .ant-collapse {
      background-color: ${(props) => props.theme.color.background.primary};
      .ant-collapse-item {
        background-color: ${(props) => props.theme.color.card.background};
        border-radius: ${(props) => props.theme.variable.borderRadius};
        margin-bottom: ${(props) => props.theme.variable.spacing.lg};
      }
    }
  `,
};

export const DevExCollapse: React.FC<DevExCollapseProps> = (props) => {
  return (
    <div css={styles.collapse}>
      <Collapse {...props} bordered={false} />
    </div>
  );
};
