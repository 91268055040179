import React from 'react';
import { Tag, TagProps } from 'antd';
import { css } from 'styled-components';

import { useDevExTheme } from '../common/DevExThemeContext';
import { invertDevExThemeColor } from '../utils/invertDevExThemeColor';

const styles = {
  tag: css`
    &.ant-tag {
      font-size: ${(props) => props.theme.variable.fontSize.xs};
      border-radius: 20px;
      margin: 0;
      transition: none;
      line-height: 1;
      padding: ${(props) => props.theme.variable.spacing.xs}
        ${(props) => props.theme.variable.spacing.sm};
    }
  `,
};

export const DevExTag: React.FC<TagProps> = (props) => {
  const theme = useDevExTheme();

  let { text, background } =
    (props.color && theme.color.tag[props.color]) || theme.color.tag.default;

  // a custom color should only by used by /admin/colors
  if (props.color?.startsWith('#')) {
    text = invertDevExThemeColor(props.color, theme.key);
    background = props.color;
  }

  return (
    <Tag css={styles.tag} {...props} style={{ color: text, ...props.style }} color={background} />
  );
};
