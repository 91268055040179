import React, { useMemo, useState } from 'react';
import { Flex } from 'antd';
import { keyBy } from 'lodash-es';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { Study, StudyLibraryClient, StudyScoreClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExRechartsTooltipContent } from 'jf/components/DevExRechartsTooltipContent';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { useClientQueries, useClientQuery } from 'jf/utils/useClientQuery';

import { ScoreBlock } from '../studyAnalyzer/ScoreBlock';

const CHART_HEIGHT = 500;

type TopicScoreTrendChartProps = {
  studies: Study[] | undefined;
};

export const TopicScoreTrendChart: React.FC<TopicScoreTrendChartProps> = (props) => {
  const theme = useDevExTheme();

  const [activeTopicIndex, setActiveTopicIndex] = useState<number>();

  const studies = props.studies?.filter((study) => study.status === Study.status.CLOSED);

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const scoreQueries = useClientQueries(
    StudyScoreClient.getStudyScores,
    studies?.map((study) => ({ studyRef: study.ref, type: ['TOPIC' as 'TOPIC'] })) ?? []
  );

  const data = useMemo(() => {
    if (!studies || !scoreQueries.every((query) => query.isSuccess)) {
      return [];
    }

    return studies.map((study, i) => {
      const studyScores = scoreQueries[i].data!;

      const datum = {
        name: study.name,
      };

      for (const score of studyScores) {
        datum[score.slug] = score.scaledAvg;
      }

      return datum;
    });
  }, [studies, scoreQueries]);

  if (!studies || !topics) {
    return <DevExSkeleton height={CHART_HEIGHT} />;
  }

  const topicsBySlug = keyBy(topics, 'slug');

  const activeTopic = activeTopicIndex !== undefined ? topics[activeTopicIndex] : undefined;

  const margin = parseInt(theme.variable.spacing.md);

  return (
    <ResponsiveContainer width={'100%'} height={CHART_HEIGHT}>
      <LineChart
        data={data}
        margin={{ left: margin, top: margin, right: margin, bottom: 0 }}
        onMouseLeave={() => setActiveTopicIndex(undefined)}
      >
        <CartesianGrid vertical={false} stroke={theme.color.visualization.grid} />
        <RechartsTooltip
          content={(tooltipProps) => (
            <DevExRechartsTooltipContent
              {...tooltipProps}
              // custom handling to only allow a single active dot
              payload={tooltipProps.payload?.filter((p) => p.name === activeTopic?.slug)}
              formatValue={(value) => (
                <Flex vertical gap={theme.variable.spacing.xs}>
                  {activeTopic?.label}
                  <ScoreBlock score={value} />
                </Flex>
              )}
            />
          )}
        />
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          formatter={(slug) => topicsBySlug[slug]?.label}
        />
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          tick={{ fill: theme.color.text.secondary, fontSize: theme.variable.fontSize.xs }}
          interval={0}
          padding={{
            left: parseInt(theme.variable.spacing.md),
            right: parseInt(theme.variable.spacing.md),
          }}
        />
        <YAxis
          domain={[0, 100]}
          tickLine={false}
          axisLine={false}
          tick={{ fill: theme.color.text.secondary, fontSize: theme.variable.fontSize.xs }}
          width={parseInt(theme.variable.spacing.lg)}
        />
        {topics?.map((topic, i) => (
          <Line
            key={topic.slug}
            type="monotone"
            dataKey={topic.slug}
            stroke={theme.color.topic[topic.slug] ?? theme.color.foundations.gray}
            strokeWidth={2}
            dot={{ r: 2 }}
            // custom handling to only allow a single active dot
            activeDot={{
              onMouseOver: () => setActiveTopicIndex(i),
              opacity: i === activeTopicIndex ? 1 : 0,
            }}
            opacity={!activeTopic || activeTopic.slug === topic.slug ? 1 : 0.2}
            animationDuration={1000}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
