import React, { useMemo, useState } from 'react';
import { Flex } from 'antd';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keyBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';

import { StudyClient, StudySequence } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExMenu } from 'jf/components/DevExMenu';
import { DevExPopover } from 'jf/components/DevExPopover';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { DeleteStudySequenceModal } from './DeleteStudySequenceModal';
import { RenameStudySequenceModal } from './RenameStudySequenceModal';
import { StudyStatusBubble } from './StudyStatusBubble';

export const STUDY_SEQUENCE_CARD_MIN_WIDTH = '240px';
const MAX_STUDY_DISPLAY_COUNT = 5;

type StudySequenceCardProps = {
  sequence: StudySequence;
};

export const StudySequenceCard: React.FC<StudySequenceCardProps> = (props) => {
  const navigate = useNavigate();
  const theme = useDevExTheme();
  const renameSequenceModal = useJFOverlay(RenameStudySequenceModal);
  const deleteSequenceModal = useJFOverlay(DeleteStudySequenceModal);

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const { data: studies } = useClientQuery(StudyClient.getStudies);

  const sequenceStudies = useMemo(() => {
    if (!studies) {
      return [];
    }

    const studiesByRef = keyBy(studies, 'ref');

    return props.sequence.studyRefs
      .map((studyRef) => studiesByRef[studyRef])
      .filter((sequence) => !!sequence);
  }, [studies, props.sequence]);

  const startDate = sequenceStudies.length
    ? DateTime.fromISO(sequenceStudies.at(-1)!.createdAt)
    : undefined;
  const endDate = sequenceStudies.length
    ? DateTime.fromISO(sequenceStudies[0].closeDate ?? sequenceStudies[0].createdAt)
    : undefined;
  const dateFormat = 'LLL yyyy';

  const bubbleStyles = (index: number) => css`
    box-shadow: 0 0 0 2px ${(props) => props.theme.color.card.background};
    z-index: ${props.sequence.studyRefs.length - index}; // stack earlier items on top

    &:not(:first-child) {
      margin-left: -${(props) => props.theme.variable.spacing.xs};
    }
  `;

  const hiddenSequenceStudies = sequenceStudies.slice(MAX_STUDY_DISPLAY_COUNT);

  return (
    <DevExCard onClick={() => navigate(`/series/${props.sequence.ref}`)}>
      <div
        style={{
          fontSize: theme.variable.fontSize.md,
          lineHeight: theme.variable.lineHeight,
          fontWeight: 600,
        }}
      >
        {props.sequence.name}
      </div>

      <Flex>
        {sequenceStudies.slice(0, MAX_STUDY_DISPLAY_COUNT).map((study, i) => (
          <StudyStatusBubble key={study.ref} study={study} tooltip css={bubbleStyles(i)} />
        ))}

        {!!hiddenSequenceStudies.length && (
          <StudyStatusBubble
            study={hiddenSequenceStudies}
            tooltip
            css={bubbleStyles(sequenceStudies.length)}
          />
        )}
      </Flex>

      <Flex justify="space-between" align="center">
        <div
          style={{
            color: theme.color.text.secondary,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {startDate?.toFormat(dateFormat)} to {endDate?.toFormat(dateFormat)}
        </div>

        <DevExPopover
          trigger={['click']}
          content={
            <DevExMenu
              items={[
                {
                  key: 'rename',
                  label: 'Rename',
                  onClick: () => {
                    renameSequenceModal.open({
                      sequence: props.sequence,
                    });
                    setIsMenuOpened(false);
                  },
                },
                {
                  type: 'divider',
                },
                {
                  key: 'delete',
                  label: 'Delete',
                  danger: true,
                  onClick: () => {
                    deleteSequenceModal.open({
                      sequence: props.sequence,
                    });
                    setIsMenuOpened(false);
                  },
                },
              ]}
              selectable={false}
            />
          }
          open={isMenuOpened}
          onOpenChange={setIsMenuOpened}
        >
          <DevExButton
            type="text"
            icon={<FontAwesomeIcon icon={faEllipsisV} />}
            onClick={(event) => event.stopPropagation()}
          />
        </DevExPopover>
      </Flex>
    </DevExCard>
  );
};
