import React from 'react';
import { Flex } from 'antd';
import SkeletonButton from 'antd/es/skeleton/Button';
import { css } from 'styled-components';

import { DevExTheme, useDevExTheme } from '../common/DevExThemeContext';

const styles = {
  skeleton: css`
    width: 100%;

    .ant-skeleton.ant-skeleton-element {
      display: flex;
      align-items: center;
      height: ${(props) => props.theme.variable.fontSize.md};

      .ant-skeleton-button {
        background-color: ${(props) => props.theme.color.skeleton.background};
        width: 100%;
        height: 100%;
        display: block;
        min-width: 0;
      }
    }
  `,
};

const parsePx = (px?: number | string) => (typeof px === 'number' ? `${px}px` : px);

export type DevExSkeletonProps = {
  width?: number | string | (number | string)[];
  height?: number | string;
  fontSize?: keyof DevExTheme['variable']['fontSize']; // sets height and innerHeight based on fontSize and lineHeight
  noLineHeight?: boolean; // when using fontSize, don't apply lineHeight
};

export const DevExSkeleton: React.FC<DevExSkeletonProps> = ({
  width,
  height,
  fontSize,
  noLineHeight,
}) => {
  const theme = useDevExTheme();

  const widths = Array.isArray(width) ? width : [width];
  let innerHeight: number | string | undefined;

  if (fontSize) {
    if (noLineHeight) {
      height = theme.variable.fontSize[fontSize];
    } else {
      innerHeight = theme.variable.fontSize[fontSize];

      if (!height) {
        height = parseInt(innerHeight) * theme.variable.lineHeight;
      }
    }
  }

  return (
    <Flex vertical css={styles.skeleton}>
      {widths.map((width, i) => (
        <SkeletonButton
          key={i}
          active
          css={css`
            &.ant-skeleton.ant-skeleton-element {
              height: ${parsePx(height)};

              .ant-skeleton-button {
                height: ${parsePx(innerHeight)};
                width: ${parsePx(width)};
              }
            }
          `}
        />
      ))}
    </Flex>
  );
};
