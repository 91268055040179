import React from 'react';
import { MotionValue } from 'framer-motion';
import { css, ThemeProvider } from 'styled-components';

import { RAW_THEMES, resolveTheme } from 'jf/common/DevExThemeContext';
import { DevExExpandableAlert, DevExExpandableAlertType } from 'jf/components/DevExExpandableAlert';

import { MotionDiv } from './MotionDiv';

const styles = {
  definition: css`
    position: absolute;
    width: 250px;
    top: 20%;
    font-size: ${(props) => props.theme.variable.fontSize.lg};
    padding-top: ${(props) => props.theme.variable.spacing.xs};
  `,
};

type RevealCardProps = {
  title: string;
  subtitle: string;
  scrollYProgress: MotionValue<number>;
  scrollIn: number;
  scrollOut: number;
};

export const DefinitionCard: React.FC<RevealCardProps> = (props) => {
  let overrideTheme = structuredClone(RAW_THEMES.light);
  overrideTheme = resolveTheme(overrideTheme);

  const cardVariants = {
    visible: { x: '170px', opacity: 1, transition: { duration: 0.05 } },
    hidden: { x: '400px', opacity: 0, transition: { duration: 0.05 } },
    remove: { x: '400px', opacity: 0, transition: { duration: 0.05 } },
  };

  return (
    <div id={'myButton'}>
      <MotionDiv
        variants={cardVariants}
        scrollYProgress={props.scrollYProgress}
        fadeIn={props.scrollIn * 100 - 7}
        fadeOut={props.scrollOut * 100 - 7}
      >
        <ThemeProvider theme={overrideTheme}>
          <DevExExpandableAlert
            type={DevExExpandableAlertType.REVEAL}
            title={props.title}
            css={styles.definition}
            hover
          >
            {props.subtitle}
          </DevExExpandableAlert>
        </ThemeProvider>
      </MotionDiv>
    </div>
  );
};
