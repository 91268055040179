/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatedStudySequence } from '../models/CreatedStudySequence';
import type { CreatedStudySequenceRequest } from '../models/CreatedStudySequenceRequest';
import type { StudySequence } from '../models/StudySequence';
import type { UpdatedStudySequenceRequest } from '../models/UpdatedStudySequenceRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StudySequenceClient {

    /**
     * Create a new study sequence.
     * @returns CreatedStudySequence
     * @throws ApiError
     */
    public static createStudySequence({
        requestBody,
    }: {
        requestBody: CreatedStudySequenceRequest,
    }): CancelablePromise<CreatedStudySequence> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/study-sequence',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a specific study sequence.
     * @returns StudySequence
     * @throws ApiError
     */
    public static getStudySequence({
        sequenceRef,
    }: {
        sequenceRef: string,
    }): CancelablePromise<StudySequence> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study-sequence/{sequenceRef}',
            path: {
                'sequenceRef': sequenceRef,
            },
        });
    }

    /**
     * Update a specific study sequence.
     * @returns void
     * @throws ApiError
     */
    public static updateStudySequence({
        sequenceRef,
        requestBody,
    }: {
        sequenceRef: string,
        requestBody?: UpdatedStudySequenceRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/study-sequence/{sequenceRef}',
            path: {
                'sequenceRef': sequenceRef,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete a specific study sequence.
     * @returns void
     * @throws ApiError
     */
    public static deleteStudySequence({
        sequenceRef,
    }: {
        sequenceRef: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/study-sequence/{sequenceRef}',
            path: {
                'sequenceRef': sequenceRef,
            },
        });
    }

    /**
     * Get all study sequences.
     * @returns StudySequence
     * @throws ApiError
     */
    public static getStudySequences(): CancelablePromise<Array<StudySequence>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study-sequences',
        });
    }

}
