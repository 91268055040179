/**
 * Confirms item is not null or undefined with type safety.
 *
 * ex. `items.filter(notNull)`
 */
export const notNull = <T>(item: T | null | undefined): item is T => item != null;

/**
 * Confirms item is not falsy with type safety.
 *
 * ex. `items.filter(notFalsy)`
 */
export const notFalsy = <T>(item: T | null | undefined): item is T => !!item;
