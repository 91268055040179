import React, { ReactNode } from 'react';
import { Flex } from 'antd';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/DevExThemeContext';

const styles = {
  hygiene: css`
    width: 360px;

    .hygiene__title {
      font-size: ${(props) => props.theme.variable.fontSize.sm};
      color: ${(props) => props.theme.color.text.secondary};
    }

    .hygiene__subtitle {
      font-size: ${(props) => props.theme.variable.fontSize.xl};
      color: ${(props) => props.theme.color.text.primary};
      padding-inline-end: ${(props) => props.theme.variable.spacing.xs};
    }
  `,
};

type HygieneCardProps = {
  title: string;
  subtitleNumber?: number | string;
  subtitleLabel?: string;
  icon?: ReactNode;
  children?: ReactNode;
  style?: React.CSSProperties;
};

export const HygieneCard: React.FC<HygieneCardProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <div css={styles.hygiene} style={props.style}>
      <Flex vertical align="flex-start" gap={theme.variable.spacing.sm}>
        <Flex align="center" className={'hygiene__title'}>
          <div style={{ paddingInlineEnd: theme.variable.spacing.sm }}>{props.title}</div>
          {props.icon}
        </Flex>
        <Flex vertical gap={theme.variable.spacing.sm}>
          <Flex align="baseline">
            <div className="hygiene__subtitle"> {props.subtitleNumber} </div>
            <div className="hygiene__title">{props.subtitleLabel}</div>
          </Flex>
          {props.children}
        </Flex>
      </Flex>
    </div>
  );
};
