import React from 'react';
import { Flex } from 'antd';
import {
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keyBy } from 'lodash-es';

import { NumericScore, StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExButton } from 'jf/components/DevExButton';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { ScoreBlock } from '../ScoreBlock';
import { useStudyScores } from '../useStudyScores';
import { useStudyScoresBySegment } from '../useStudyScoresBySegment';

import { TopicDrawer } from './TopicDrawer';

type NavigatorDirectionProps = {
  topicScores: NumericScore[] | undefined;
  direction: 'left' | 'right';
  topicSlug?: string;
};

const NavigatorDirection: React.FC<NavigatorDirectionProps> = (props) => {
  const left = props.direction === 'left';

  const theme = useDevExTheme();
  const topicDrawer = useJFOverlay(TopicDrawer);

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);

  const topicsBySlug = keyBy(topics, 'slug');
  const topicScores = props.topicScores?.toSorted((a, b) => a.scaledAvg - b.scaledAvg) ?? [];

  const index = topicScores.findIndex((score) => score.slug === props.topicSlug);

  const endIndex = left ? 0 : topicScores.length - 1;
  const nextIndex = left ? index - 1 : index + 1;

  const loading = index === -1;

  return (
    <Flex>
      <DevExButton
        type="text"
        icon={<FontAwesomeIcon icon={left ? faChevronsLeft : faChevronsRight} />}
        disabled={index === endIndex || loading}
        style={{ order: left ? 0 : 1 }}
        onClick={() => topicDrawer.update({ topicSlug: topicScores[endIndex].slug })}
      />

      {index !== endIndex && !loading && (
        <DevExButton
          type="text"
          icon={<FontAwesomeIcon icon={left ? faChevronLeft : faChevronRight} />}
          iconPosition={props.direction}
          style={{ padding: `0 ${theme.variable.spacing.sm}` }}
          onClick={() => topicDrawer.update({ topicSlug: topicScores[nextIndex].slug! })}
        >
          <Flex align="center" gap={theme.variable.spacing.xs}>
            {topicsBySlug[topicScores[nextIndex].slug].label}
            <ScoreBlock score={topicScores[nextIndex].scaledAvg} />
          </Flex>
        </DevExButton>
      )}
    </Flex>
  );
};

type TopicDrawerNavigatorProps = {
  studyRef: string | undefined;
  teamRef?: string;
  segmentRef?: string;
  topicSlug?: string;
};

export const TopicDrawerNavigator: React.FC<TopicDrawerNavigatorProps> = (props) => {
  const theme = useDevExTheme();
  const segmentFilter = !!props.segmentRef;

  const { data: topicScores } = useStudyScores({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
    type: ['TOPIC'],
  });
  const { data: topicScoresBySegment } = useStudyScoresBySegment({
    studyRef: segmentFilter ? props.studyRef : undefined, // don't fetch if we don't need it
    teamRef: props.teamRef,
    type: ['TOPIC'],
  });

  const scores = props.segmentRef
    ? topicScoresBySegment?.filter((score) => score.segment?.ref === props.segmentRef)
    : topicScores;

  return (
    <Flex
      justify="space-between"
      style={{
        fontFamily: theme.variable.fontFamily.primary,
        fontSize: theme.variable.fontSize.sm,
        fontWeight: 400,
        marginInline: `-${theme.variable.spacing.sm}`,
      }}
    >
      <NavigatorDirection topicScores={scores} direction="left" topicSlug={props.topicSlug} />
      <NavigatorDirection topicScores={scores} direction="right" topicSlug={props.topicSlug} />
    </Flex>
  );
};
