import React, { useEffect, useState } from 'react';
import { delay } from 'lodash-es';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { createGlobalStyle, FlattenSimpleInterpolation } from 'styled-components';

import { notNull } from 'jf/utils/notNull';

type TransitionStyleProps = {
  name: string;
  timeout: number;
  cssInit: FlattenSimpleInterpolation;
  cssExit: FlattenSimpleInterpolation;
  cssEnter?: FlattenSimpleInterpolation;
};

const TransitionStyle = createGlobalStyle<TransitionStyleProps>`
  .${(props) => props.name}-enter {
    ${(props) => props.cssEnter}
  }
  .${(props) => props.name}-enter-active {
    ${(props) => props.cssInit}
    transition: all ${(props) => props.timeout}ms ease;
  }
  .${(props) => props.name}-exit {
    ${(props) => props.cssInit}
  }
  .${(props) => props.name}-exit-active {
    ${(props) => props.cssExit}
    transition: all ${(props) => props.timeout}ms ease;
  }
`;

type SimpleTransitionProps = CSSTransitionProps &
  TransitionStyleProps & {
    inDelay?: number;
  };

/**
 * Simple wrapper around CSSTransition to make configuration easier.
 */
export const SimpleTransition: React.FC<SimpleTransitionProps> = (props) => {
  const [visible, setVisible] = useState<boolean>();

  // useEffect used to ensure transition plays on mount
  useEffect(() => {
    if (props.inDelay && props.in && notNull(visible)) {
      // delay visible = true if needed
      delay(() => setVisible(!!props.in), props.inDelay);
    } else {
      setVisible(!!props.in);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.in]);

  const { children, ...propsWithoutChildren } = props; // avoids console warning

  return (
    <>
      <TransitionStyle {...propsWithoutChildren} cssEnter={props.cssEnter ?? props.cssExit} />
      <CSSTransition in={visible} classNames={props.name} timeout={props.timeout} unmountOnExit>
        <div>{props.children}</div>
      </CSSTransition>
    </>
  );
};
