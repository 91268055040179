/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PublicPrompt = {
    sequence?: number;
    /**
     * * `RATING_5L` - 5-point Likert:Agree-Disagree
     * * `RATING_5N` - 5-point:1-5
     * * `SINGLE_SEL` - Single choice selection
     * * `MULTI_SEL` - Multiple choice selection
     * * `OPEN_END` - Open-Ended Text
     */
    type: PublicPrompt.type;
    slug: string;
    topicSlug: string;
    topicLabel: string;
    text: string;
    polarity: number;
    choiceLabels: Array<string>;
};

export namespace PublicPrompt {

    /**
     * * `RATING_5L` - 5-point Likert:Agree-Disagree
     * * `RATING_5N` - 5-point:1-5
     * * `SINGLE_SEL` - Single choice selection
     * * `MULTI_SEL` - Multiple choice selection
     * * `OPEN_END` - Open-Ended Text
     */
    export enum type {
        RATING_5L = 'RATING_5L',
        RATING_5N = 'RATING_5N',
        SINGLE_SEL = 'SINGLE_SEL',
        MULTI_SEL = 'MULTI_SEL',
        OPEN_END = 'OPEN_END',
    }


}

