import React from 'react';
import { Flex } from 'antd';
import { faBullseyeArrow, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/DevExThemeContext';

type BenchmarkCellProps = {
  benchmark?: number;
};

const styles = {
  cellStyles: css`
    .cell__value {
      color: ${({ theme }) => theme.color.text.primary};
    }
  `,
};

export const BenchmarkCell: React.FC<BenchmarkCellProps> = ({ benchmark }) => {
  const theme = useDevExTheme();
  if (benchmark === undefined) {
    return (
      <Flex justify="center" css={styles.cellStyles}>
        &mdash;
      </Flex>
    );
  }
  return (
    <Flex justify="center" css={styles.cellStyles} gap={theme.variable.spacing.xs}>
      <span className="cell__value">
        {benchmark === 0 || benchmark < 0 ? '' : '+'}
        {benchmark}
      </span>
      <div>
        {benchmark >= 0 && (
          <FontAwesomeIcon
            icon={faBullseyeArrow}
            color={theme.color.visualization.polarity.positive.high.text}
          />
        )}
        {benchmark < 0 && (
          <FontAwesomeIcon
            icon={faCircleXmark}
            color={theme.color.visualization.polarity.negative.high.text}
          />
        )}
      </div>
    </Flex>
  );
};
