import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Tooltip } from 'antd';
import {
  faArrowUpFromSquare,
  faClipboardCheck,
  faLineChart,
  faLink,
  faSparkles,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import capitalize from 'lodash/capitalize';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { Study, StudyClient, StudySequenceClient } from 'jf/api';
import { startFullstory } from 'jf/common/DevEx';
import { DevExPageHeader, DevExPageHeaderTab } from 'jf/common/DevExPageHeader';
import { DevExPageTitle } from 'jf/common/DevExPageTitle';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { CopyLinkPopover } from 'jf/components/CopyLinkPopover';
import { DevExButton } from 'jf/components/DevExButton';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import { ExportStudyModal } from './export/ExportStudyModal';
import { CloseStudyModal } from './CloseStudyModal';
import { useStudyParticipation } from './useStudyParticipation';

export const ANONYMITY_THRESHOLD = 3;

export const SHARE_LINK_EXPIRY_DAYS = 14;

const SHINY_REVEAL_TIMER = 6000;

export const makeStudyAnalyzerPath = (
  studyRef: string | undefined,
  teamRef: string | undefined,
  tabKey: string
) => `/study/${studyRef}/analyze/${teamRef ? `${teamRef}/` : ''}${tabKey}`;

export const StudyAnalyzerPage: React.FC = () => {
  const { bakunawaDevexSurveyRevealQ324: surveyRevealEnabled } = useFlags();

  useEffect(() => startFullstory('survey-analyzer'), []);
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef: string }>();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const exportStudyModal = useJFOverlay(ExportStudyModal);
  const closeStudyModal = useJFOverlay(CloseStudyModal);
  const theme = useDevExTheme();

  const [shareLink, setShareLink] = useState('');

  // reveal / highlights button shiny on page load
  const [isRevealButtonShiny, setIsRevealButtonShiny] = useState(true);

  // timer to un-shiny the highlights/reveal button after SHINY_REVEAL_TIMER ms
  setTimeout(() => {
    setIsRevealButtonShiny(false);
  }, SHINY_REVEAL_TIMER);

  const tabKey = useMemo(() => {
    const TAB_KEY_REGEX = /\/([^/]*?)$/g;
    return TAB_KEY_REGEX.exec(location.pathname)?.[1];
  }, [location.pathname]);

  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });
  const isOpen = study?.status === Study.status.OPEN;

  let tabKeys = ['insights', 'questions', 'participation'];
  if (isOpen) {
    tabKeys = ['questions', 'participation'];
  } else if (teamRef) {
    tabKeys = ['insights', 'questions'];
  }

  // persist scope_token when using tabs
  const scopeToken = searchParams.get('scope_token');
  const search = scopeToken ? `?scope_token=${scopeToken}` : '';

  const tabs: DevExPageHeaderTab[] = tabKeys.map((key) => ({
    key,
    label: capitalize(key),
    href: `${makeStudyAnalyzerPath(studyRef, teamRef, key)}${search}`,
  }));

  const { mutateAsync: shareStudy } = useClientMutation(StudyClient.shareStudy);

  const onClose = () => {
    trackEvent('survey-analyzer:close:start', { surveyRef: studyRef });
    closeStudyModal.open();
  };

  const onExport = () => {
    trackEvent('survey-analyzer:export:start', { surveyRef: studyRef });
    exportStudyModal.open();
  };

  const { data: participation } = useStudyParticipation({ studyRef, teamRef });
  const disableClose = participation?.uniqueResponses === 0;

  const { data: sequence } = useClientQuery(StudySequenceClient.getStudySequence, {
    sequenceRef: study?.sequenceRef,
  });

  return (
    <div>
      <DevExPageTitle>{`Survey ${study?.name ?? ''}`}</DevExPageTitle>
      <DevExPageHeader
        title={
          study ? (
            <Flex gap={theme.variable.spacing.sm}>
              <div>{study?.name}</div>

              {sequence && (
                <DevExButton
                  icon={<FontAwesomeIcon icon={faLineChart} />}
                  type="text"
                  style={{ paddingInline: theme.variable.spacing.sm }}
                  onClick={() => navigate(`/series/${sequence.ref}/`)}
                >
                  {sequence.name}
                </DevExButton>
              )}
            </Flex>
          ) : undefined
        }
        extra={
          study ? (
            study.status === Study.status.CLOSED ? (
              <>
                {surveyRevealEnabled && (
                  <DevExButton
                    size="large"
                    type="outline"
                    shiny={isRevealButtonShiny}
                    onClick={() => {
                      trackEvent('survey-reveal:open:click');
                      navigate(`/study/${studyRef}/reveal`);
                    }}
                    icon={<FontAwesomeIcon icon={faSparkles} />}
                  >
                    Highlights
                  </DevExButton>
                )}
                <DevExButton
                  size="large"
                  type="outline"
                  icon={<FontAwesomeIcon icon={faArrowUpFromSquare} />}
                  onClick={onExport}
                >
                  Export
                </DevExButton>
                {
                  // GBAC RESTRICTION
                  // only customer admins can create share links
                  window.dx.user?.isCustomerAdmin && (
                    <CopyLinkPopover
                      title="Share Results"
                      description={`The link above will grant public access to this ${teamRef ? 'team' : 'survey'}'s results to whomever you share it with. It will expire in ${SHARE_LINK_EXPIRY_DAYS} days.`}
                      link={shareLink}
                      placement="bottomRight"
                      onCopy={() =>
                        trackEvent('survey-analyzer:share-results:copy', { surveyRef: studyRef })
                      }
                    >
                      <DevExButton
                        size="large"
                        type="outline"
                        icon={<FontAwesomeIcon icon={faLink} />}
                        onClick={() => {
                          if (studyRef) {
                            trackEvent('survey-analyzer:share-results:start', {
                              surveyRef: studyRef,
                            });
                            shareStudy({
                              studyRef,
                              requestBody: {
                                teamRef,
                                expiryDays: SHARE_LINK_EXPIRY_DAYS,
                              },
                            }).then((response) => {
                              const queryParams = new URLSearchParams(window.location.search);
                              queryParams.append('scope_token', response.scopeToken);
                              setShareLink(
                                `${window.location.origin}${window.location.pathname}?${queryParams}`
                              );
                            });
                          }
                        }}
                      >
                        Share results
                      </DevExButton>
                    </CopyLinkPopover>
                  )
                }
              </>
            ) : (
              <>
                <Tooltip
                  title={disableClose ? 'Your survey has not received any responses yet.' : ''}
                >
                  <div>
                    <DevExButton
                      size="large"
                      type="outline"
                      icon={<FontAwesomeIcon icon={faClipboardCheck} />}
                      onClick={onClose}
                      disabled={disableClose}
                    >
                      Close
                    </DevExButton>
                  </div>
                </Tooltip>
                <CopyLinkPopover
                  title="Share Survey with Participants"
                  description="Copy the survey link above and share it with your target participants."
                  link={`${window.location.origin}/study/${studyRef}`}
                  placement="bottomRight"
                  onCopy={() =>
                    trackEvent('survey-analyzer:share-survey:copy', { surveyRef: studyRef })
                  }
                >
                  <DevExButton
                    size="large"
                    type="primary"
                    icon={<FontAwesomeIcon icon={faLink} />}
                    onClick={() =>
                      trackEvent('survey-analyzer:share-survey:start', { surveyRef: studyRef })
                    }
                  >
                    Share survey
                  </DevExButton>
                </CopyLinkPopover>
              </>
            )
          ) : undefined
        }
        tabs={tabs}
        tabKey={tabKey}
      />
      <Outlet />
    </div>
  );
};
