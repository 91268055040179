import React, { ReactNode } from 'react';
import { Table, TableProps } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import { ColumnType } from 'antd/es/table';
import { css } from 'styled-components';

import { arrayOf } from '../utils/arrayOf';

import { DevExEmpty } from './DevExEmpty';
import { DevExSkeleton } from './DevExSkeleton';

const styles = {
  table: css`
    &.ant-table-wrapper .ant-table {
      background: none;
      color: ${(props) => props.theme.color.text.primary};

      .ant-table-thead {
        th {
          background: none;
          color: ${(props) => props.theme.color.text.secondary};
          border: none;
          font-weight: 400;

          &::before {
            content: none !important; // remove lines between header cells
          }

          .ant-table-column-sorter-inner {
            .anticon {
              color: ${(props) => props.theme.color.text.tertiary};

              &.active {
                color: ${(props) => props.theme.color.accent.primary};
              }
            }
          }
        }
      }

      .ant-table-tbody {
        td {
          border: none;
          white-space: nowrap;

          &:not(.ant-table-column-sort) {
            &:first-child {
              border-top-left-radius: ${(props) => props.theme.variable.borderRadius};
              border-bottom-left-radius: ${(props) => props.theme.variable.borderRadius};
            }

            &:last-child {
              border-top-right-radius: ${(props) => props.theme.variable.borderRadius};
              border-bottom-right-radius: ${(props) => props.theme.variable.borderRadius};
            }
          }

          &.ant-table-cell-row-hover {
            background: ${(props) => props.theme.color.accent.aura.primary};
          }
        }
      }

      .ant-table-cell {
        padding: ${(props) => props.theme.variable.spacing.sm};
      }
    }
  `,
};

// improving the typing of Table.columns.render to automatically interpret the generic
export interface DevExTableColumn<T extends AnyObject> extends ColumnType<T> {
  render?: (record: T) => ReactNode;
}

interface DevExTableProps<T extends AnyObject> extends TableProps<T> {
  columns: DevExTableColumn<T>[];
  rowKey: keyof T;
}

export const DevExTable = <T extends AnyObject>(props: DevExTableProps<T>) => {
  // render skeleton rows while dateSource is loading
  if (!props.dataSource) {
    const skeletonColumns: DevExTableColumn<any>[] = props.columns.map((column) => ({
      ...column,
      dataIndex: undefined,
      render: () => <DevExSkeleton fontSize="md" />,
    }));
    const skeletonDataSource: any[] = arrayOf(10).map((i) => ({ key: i }));
    return (
      <Table
        pagination={false}
        css={styles.table}
        style={{ pointerEvents: 'none', ...props.style }}
        columns={skeletonColumns}
        dataSource={skeletonDataSource}
      />
    );
  }

  return (
    <Table
      pagination={false}
      css={styles.table}
      locale={{ emptyText: <DevExEmpty iconName="face-confused" label="No data." /> }}
      showSorterTooltip={false}
      {...props}
    />
  );
};
