import React, { useEffect } from 'react';
import { Flex } from 'antd';
import { notify } from 'bugsnag';
import { useRouteError } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

// NOTE: we can't use DevExTheme here because ErrorBoundary sits above everything (intentionally)

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Source Sans Pro", "Segoe UI", "Droid Sans", Tahoma, Arial, sans-serif;
    color: #0D062B;
  }

  @media (prefers-color-scheme: dark) {
    body {
      color: #F9F6FE;
    }
  }
`;

export const DevExErrorBoundary: React.FC = () => {
  const error = useRouteError();

  useEffect(() => {
    if (error instanceof Error) {
      console.error(error);
      notify(error as Error);
    }
  }, [error]);

  return (
    <>
      <GlobalStyle />
      <Flex vertical align="center" justify="center" style={{ minHeight: '100vh' }} gap={32}>
        <div>Sorry, an unexpected client error has occurred. Please try refreshing your page.</div>
      </Flex>
    </>
  );
};
