import { SortOrder } from 'antd/es/table/interface';

export const compareNullableNumbers = (
  a: number | undefined,
  b: number | undefined,
  sortOrder?: SortOrder
) => {
  // sort undefined numbers always to the end, no matter the sort order
  if (a === undefined && b === undefined) {
    return 0;
  } else if (a === undefined) {
    return sortOrder === 'ascend' ? 1 : -1;
  } else if (b === undefined) {
    return sortOrder === 'ascend' ? -1 : 1;
  }

  return a - b;
};
