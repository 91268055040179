import React from 'react';
import { Divider, DividerProps } from 'antd';
import { css } from 'styled-components';

const styles = {
  divider: css`
    &.ant-divider {
      border-color: ${(props) => props.theme.color.card.border};
      margin: ${(props) => props.theme.variable.spacing.md} 0;
      font-size: ${(props) => props.theme.variable.fontSize.xs};
      color: ${(props) => props.theme.color.text.secondary};
    }
  `,
};

export const DevExDivider: React.FC<DividerProps> = (props) => {
  return <Divider css={styles.divider} {...props} />;
};
