import React from 'react';
import { Flex } from 'antd';
import { faExclamationCircle, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { css } from 'styled-components';

import { DevExButton } from 'jf/components/DevExButton';
import { useLocalStorage } from 'jf/utils/useBrowserStorage';

import { useDevExTheme } from './DevExThemeContext';

const styles = {
  banner: css`
    background: ${(props) => props.theme.color.status.warning.background};
    border-bottom: 1px solid ${(props) => props.theme.color.status.warning.border};
    color: ${(props) => props.theme.color.status.warning.text};
    padding-block: ${(props) => props.theme.variable.spacing.md};

    svg {
      font-size: ${(props) => props.theme.variable.fontSize.md};
    }

    .banner__close {
      position: absolute;
      right: ${(props) => props.theme.variable.spacing.sm};
    }
  `,
};

export const DevExIncidentBanner: React.FC = () => {
  const theme = useDevExTheme();
  const { bakunawaDevexIncidentBanner: incidentMessage } = useFlags();
  const [ignoredIncidentMessage, setIgnoredIncidentMessage] = useLocalStorage(
    'devex_ignored_incident_message',
    ''
  );

  if (!incidentMessage || incidentMessage === ignoredIncidentMessage) {
    return null;
  }

  return (
    <Flex align="center" justify="center" gap={theme.variable.spacing.sm} css={styles.banner}>
      <FontAwesomeIcon icon={faExclamationCircle} />
      {incidentMessage}
      <DevExButton
        className="banner__close"
        type="text"
        icon={<FontAwesomeIcon icon={faXmark} />}
        onClick={() => setIgnoredIncidentMessage(incidentMessage)}
      />
    </Flex>
  );
};
