import React from 'react';
import { Flex } from 'antd';
import * as thinIcons from '@fortawesome/pro-thin-svg-icons';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { parseIconName } from 'jf/utils/parseIconName';

import { CircledFontAwesomeIcon } from './CircledFontAwesomeIcon';

const styles = {
  empty: css`
    .empty__label {
      color: ${(props) => props.theme.color.text.tertiary};
      text-align: center;
    }
  `,
};

type DevExEmptyProps = {
  iconName: thinIcons.IconName;
  label?: string;
  style?: React.CSSProperties;
  iconSize?: number;
};

export const DevExEmpty: React.FC<DevExEmptyProps> = (props) => {
  const theme = useDevExTheme();

  const icon = thinIcons[parseIconName(props.iconName)];

  return (
    <Flex vertical css={styles.empty} style={props.style} gap={theme.variable.spacing.md}>
      <Flex justify="center">
        <CircledFontAwesomeIcon
          icon={icon}
          style={{ color: theme.color.text.tertiary }}
          iconSize={props.iconSize}
        />
      </Flex>
      {!!props.label && <div className={'empty__label'}>{props.label}</div>}
      {props.children}
    </Flex>
  );
};
