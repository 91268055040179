import { useEffect } from 'react';

import { AnalyticsClient, SegmentEvent } from 'jf/api';
import { refreshOpenAPIHeaders } from 'jf/common/DevEx';

import { useDevExTheme } from '../common/DevExThemeContext';

declare const ENV: string, JF_VERSION: string;

/**
 * Debug levels for working with analytics, these only matter in dev
 * If using SEND_REQUEST locally, you'll also need to do appropriate uncommenting in jellyfish/settings/dev.py
 */
const enum ANALYTICS_LEVEL {
  NONE, // turns analytics calls into noops
  LOG, // Log all analytics calls *except* for page visibility events
  LOG_ALL, // Log everything
  SEND_REQUEST, // Send analytics requests to the server
}

// Update this line to change the debug level in development
const ANALYTICS_DEBUG = ANALYTICS_LEVEL.NONE;

const getAnalyticsLevel = (): ANALYTICS_LEVEL => {
  // you can set the analytics level at runtime by running
  // window.ANALYTICS_LEVEL = 1
  // in your browser's dev console.
  if ((window as any).ANALYTICS_LEVEL) {
    return (window as any).ANALYTICS_LEVEL;
  }

  // if window.ANALYTICS_LEVEL is unset, default based on the environment
  switch (ENV) {
    case 'development':
      return ANALYTICS_DEBUG;
    // log all the things in test
    case 'test':
      return ANALYTICS_LEVEL.LOG_ALL;
    case 'production':
      return ANALYTICS_LEVEL.SEND_REQUEST;
    // default to sending request, worst case, the backend will just ignore the event
    default:
      return ANALYTICS_LEVEL.SEND_REQUEST;
  }
};

/* Functions that pass user behavior to segment.io */

export const trackEvent = (eventName: string, context?: object): void =>
  segmentEvent('TRACK', eventName, context);
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
export const trackPage = (context?: object): void => segmentEvent('PAGE', null, context);

type SegmentTrackType = 'PAGE' | 'TRACK';

const segmentEvent = (trackType: SegmentTrackType, eventName?: string, context?: object): void => {
  const analyticsLevel = getAnalyticsLevel();
  if (analyticsLevel === ANALYTICS_LEVEL.NONE) {
    return;
  }

  // This helps with an edge case where we see 403 responses for an invalid csrf token
  // if a user signs out and back in again in a different tab.
  refreshOpenAPIHeaders();

  /*
        segmentEvent can be used to send events to segment (and ultimately the analytics database) and currently
        has two supported uses:
        1. Send segment an instance of a "PAGE" event that DOES NOT generate a corresponding django request
            because it's handled in react
        2. Send segment a "TRACK" event. Due to the way that analytics event handling code works, all "TRACK"
            events must include a "path" entry in the `parameters` argument that maps to the application url path
            on the page that generated the event.

        :param trackType: str, either "PAGE" or "TRACK"

        :param eventName: str, identifier for the event. can be null if it's a "PAGE".
            If this is a feature utilization event, the eventName should have the following format:
            `utilized_feature:optional_feature_category:feature_name`
            Note that feature_name's should remain static and if more context is desirable, then we
            may send over additional, more specific, information to our analytics db by passing
            a `context` dictionary (see param below)

        :param options: dictionary, should generally contain a "path" entry for path overrides or else defaultSegmentOptions

        :param context: dictionary, should be used to provide additional event information (e.g., for a feature flag with `feature_name`
        is there something more specific that might be helpful on the analytics like counts of items selected in a dropdown menu feature)
    */

  const event: SegmentEvent = {
    referrer: document.referrer,
    url: window.location.href,
    path: window.location.pathname,
    queryString: window.location.search,
    segmentType: trackType,
    eventName,
    context,
  };

  // We get a zillion of these and they're probably not what you're debugging anyway, so skip them at the LOG level
  if (
    eventName &&
    analyticsLevel === ANALYTICS_LEVEL.LOG &&
    ['Page Hidden', 'Page Visible'].includes(eventName)
  ) {
    return;
  }

  if (analyticsLevel === ANALYTICS_LEVEL.LOG || analyticsLevel === ANALYTICS_LEVEL.LOG_ALL) {
    console.groupCollapsed(eventName || 'TRACKED EVENT TRIGGERED');
    console.log(event);
    console.groupEnd();
    return;
  }

  AnalyticsClient.createSegmentEvent({
    requestBody: event,
  });
};

export const trackPageClose = (): void => trackEvent('Page Close');
export const usePageVisibilityTracker = (): void => {
  const theme = useDevExTheme();
  useEffect(() => {
    /* Send track event whenever user switches to or from the webapp (assumes authenticated) */
    let eventDescription: string;
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        eventDescription = 'Page Hidden';
      } else {
        eventDescription = 'Page Visible';
      }

      const prefersColorScheme = window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches
        ? 'dark'
        : 'light';

      trackEvent(eventDescription, {
        appVersion: JF_VERSION,
        prefersColorScheme: prefersColorScheme,
        devExTheme: theme.key,
        pixelDensity: parseFloat((window.devicePixelRatio || -1).toFixed(3)),
        viewportWidth: window.innerWidth,
        viewportHeight: window.innerHeight,
      });
    });

    /* Track event on page close (or page switch within app) */
    window.addEventListener('onunload', trackPageClose);
  }, []);
};
