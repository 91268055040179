export enum DevExEnv {
  PROD = 'PROD',
  DEMO = 'DEMO',
  DEV = 'DEV',
  STAGING = 'STAGING',
  ATLASSIAN = 'ATLASSIAN',
}

type DevExEnvMeta = {
  key: DevExEnv;
  name: string;
  origins: string[]; // origins of DevEx
  appOrigin: string; // origin of EMP
  baseUrl?: string; // URL of DevEx server if we're not using the same origin
  launchDarklyId: string; // LaunchDarkly client ID
};

const ENVS: { [key in DevExEnv]: DevExEnvMeta } = {
  PROD: {
    key: DevExEnv.PROD,
    name: 'production',
    origins: ['devex.jellyfish.co'],
    appOrigin: 'https://app.jellyfish.co',
    launchDarklyId: '5dae2330be831b08ad2deebe',
  },
  DEMO: {
    key: DevExEnv.DEMO,
    name: 'demo',
    origins: ['devex-demo.jellyfish.co'],
    appOrigin: 'https://demo.jellyfish.co',
    launchDarklyId: '5db9c2d402bfff08aa78c6d3',
  },
  DEV: {
    key: DevExEnv.DEV,
    name: 'development',
    origins: ['localhost', '127.0.0.1', 'ngrok.app', 'circle-app', 'chromatic.com'],
    appOrigin: 'http://localhost:8000',
    launchDarklyId: '5dae2330be831b08ad2deebd',
  },
  STAGING: {
    key: DevExEnv.STAGING,
    name: 'staging',
    origins: ['.thejellyfish.net'],
    // TODO(asm,2024-04-09): We have the environment name in `window.dx.jf_env` - not sure the best
    // way to add that to this type
    appOrigin: 'https://stg-sandbox-devex.thejellyfish.net',
    launchDarklyId: '5dae27e28b4e2208b543ea82',
  },
  // remember, if we're in Atlassian's ecosystem, we're in an iframe
  // and window.location.origin will be something like
  // https://gnjn11-1ciwjjpebg-24yqb102sg--htoav3-131q6oow57-1xj91f8imm.cdn.prod.atlassian-dev.net
  ATLASSIAN: {
    key: DevExEnv.ATLASSIAN,
    name: 'atlassian',
    origins: ['atlassian'],
    appOrigin: 'https://app.jellyfish.co',
    // TODO: figure out how to have separate staging/prod versions of this
    baseUrl: 'https://devex.jellyfish.co',
    launchDarklyId: '5dae2330be831b08ad2deebe',
  },
};

export const getDevExEnv = (): DevExEnvMeta => {
  for (const env of Object.values(ENVS)) {
    for (const origin of env.origins) {
      if (window.location.origin.includes(origin)) {
        return env;
      }
    }
  }

  throw new Error(`Not able to determine environment based on origin ${window.location.origin}.`);
};
