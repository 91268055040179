/**
 * Utility to declaratively determine the app environment
 *
 * @example
 * import {getEnv, ENV_NAMES} from '...'
 * const isProduction = ENV_NAMES[getEnv()] === ENV_NAMES.PRODUCTION
 *
 */

export const ENV_NAMES = {
  PRODUCTION: 'PRODUCTION',
  STAGING: 'STAGING',
  DEMO: 'DEMO',
  TEST: 'TEST',
  ATLASSIAN: 'ATLASSIAN', // our Atlassian Compass app
  UNKNOWN: 'UNKNOWN',
} as const;

export type EnvName = (typeof ENV_NAMES)[keyof typeof ENV_NAMES];

export const getEnv = (): EnvName => {
  const origin = window.location.origin;

  if (origin.includes('devex.jellyfish.co')) {
    return ENV_NAMES.PRODUCTION;
  } else if (origin.includes('stg')) {
    return ENV_NAMES.STAGING;
  } else if (origin.includes('demo') || origin.includes('web')) {
    return ENV_NAMES.DEMO;
  } else if (origin.includes('localhost')) {
    return ENV_NAMES.TEST;
  } else if (origin.includes('atlassian')) {
    return ENV_NAMES.ATLASSIAN;
  } else {
    return ENV_NAMES.UNKNOWN;
  }
};
