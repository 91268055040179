import React, { useEffect, useState } from 'react';
import { useScroll } from 'framer-motion';
import { useParams } from 'react-router-dom';

import { StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { useStudyParticipation } from '../useStudyParticipation';
import { useStudyScores } from '../useStudyScores';

import { AllTopicReveal } from './AllTopicReveal';
import { CommentsPage } from './CommentsPage';
import { ParticipationReveal } from './ParticipationReveal';
import { RevealEnd } from './RevealEnd';
import { RevealHeader } from './RevealHeader';
import { RevealLanding } from './revealLanding';
import { RevealTeamHeatMap } from './RevealTeamHeatMap';
import { ThreeReveal } from './ThreeReveal';
import { TransitionPage } from './TransitionPage';

type _StudyRevealStateContext = {
  pageSlug?: string;
  setPageSlug: (slug: string) => void;
};

const StudyRevealStateContext = React.createContext<_StudyRevealStateContext>({
  pageSlug: undefined,
  setPageSlug: () => {},
});

export const useStudyRevealState = () => React.useContext(StudyRevealStateContext);

export const usePageIndexTracker = (targetRef: React.MutableRefObject<null>, slug: string) => {
  const studyRevealState = useStudyRevealState();

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['start start', 'end start'],
  });

  useEffect(() => {
    scrollYProgress.onChange((value) => {
      if (value > 0 && value < 1) {
        studyRevealState.setPageSlug(slug);
      }
    });
  }, []);
};

export const StudyRevealController: React.FC = (props) => {
  const [pageSlug, setPageSlug] = useState<string>();

  return (
    <StudyRevealStateContext.Provider value={{ pageSlug, setPageSlug }}>
      <StudyReveal />
    </StudyRevealStateContext.Provider>
  );
};

type StudyRevealStep = {
  slug: string;
  headerColor: string;
  backgroundColor: string;
  Component: React.FC;
};

const ThreeArray = (type: string, displayCount: number) => {
  const { studyRef, teamRef } = useParams<{ studyRef: string; teamRef: string }>();
  const { data: topicScores } = useStudyScores({
    studyRef: studyRef,
    teamRef: teamRef,
    type: ['TOPIC'],
  });

  let filteredTopics;

  if (type === 'LOW') {
    filteredTopics = topicScores?.sort((a, b) => a.scaledAvg - b.scaledAvg).slice(0, displayCount);
  } else {
    filteredTopics = topicScores?.sort((a, b) => b.scaledAvg - a.scaledAvg).slice(0, displayCount);
  }
  return filteredTopics;
};

export const StudyReveal: React.FC = () => {
  const theme = useDevExTheme();
  const { studyRef } = useParams<{ studyRef: string }>();
  const { data: participation } = useStudyParticipation({ studyRef: studyRef });
  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  let displayCount: number;
  if (topics) {
    displayCount = Math.min(Math.floor(topics.length / 2), 3);
  }

  const studyRevealState = useStudyRevealState();

  const STUDY_REVEAL_STEPS: StudyRevealStep[] = [
    {
      slug: 'participation',
      headerColor: theme.color.reveal.purple.main.text,
      backgroundColor: theme.color.reveal.purple.main.background,
      Component: () => <>{participation?.uniqueResponses !== 0 && <ParticipationReveal />}</>,
    },
    {
      slug: 'transition-to-top-three',
      headerColor: theme.color.reveal.accent,
      backgroundColor: theme.color.reveal.dark.background,
      Component: () => (
        <>
          {displayCount !== 0 && (
            <TransitionPage
              title={
                <div>
                  Now, how do your teams feel about their <br />
                  <span style={{ color: theme.color.reveal.accent }}> Developer Experience</span>?
                </div>
              }
              slug={'transition-to-top-three'}
            />
          )}
        </>
      ),
    },
    {
      slug: 'high-three',
      headerColor: theme.color.reveal.scores.text,
      backgroundColor: theme.color.reveal.scores.background,
      Component: () => (
        <>
          {displayCount !== 0 && (
            <ThreeReveal
              title="Highest Scoring Topics"
              filteredTopics={ThreeArray('HIGH', displayCount)}
              slug={'high-three'}
            />
          )}
        </>
      ),
    },
    {
      slug: 'high-comments',
      headerColor: theme.color.reveal.purple.main.text,
      backgroundColor: theme.color.reveal.purple.main.background,
      Component: () => (
        <>
          {displayCount !== 0 && (
            <CommentsPage
              slug={'high-comments'}
              filteredTopics={ThreeArray('HIGH', displayCount)}
            />
          )}
        </>
      ),
    },
    {
      slug: 'transition-to-bottom-three',
      headerColor: theme.color.reveal.accent,
      backgroundColor: theme.color.reveal.dark.background,
      Component: () => (
        <>
          {displayCount !== 0 && (
            <TransitionPage
              title={
                <div>
                  Time to see where you can
                  <span style={{ color: theme.color.reveal.accent }}> grow</span>!
                </div>
              }
              slug={'transition-to-bottom-three'}
            />
          )}
        </>
      ),
    },
    {
      slug: 'low-three',
      headerColor: theme.color.reveal.scores.text,
      backgroundColor: theme.color.reveal.scores.background,
      Component: () => (
        <>
          {displayCount !== 0 && (
            <ThreeReveal
              title="Prospective Target Topics"
              filteredTopics={ThreeArray('LOW', displayCount)}
              slug={'low-three'}
            />
          )}
        </>
      ),
    },
    {
      slug: 'low-comments',
      headerColor: theme.color.reveal.purple.main.text,
      backgroundColor: theme.color.reveal.purple.main.background,
      Component: () => (
        <>
          {displayCount !== 0 && (
            <CommentsPage slug={'low-comments'} filteredTopics={ThreeArray('LOW', displayCount)} />
          )}
        </>
      ),
    },
    {
      slug: 'transition-to-all-topics',
      headerColor: theme.color.reveal.accent,
      backgroundColor: theme.color.reveal.dark.background,
      Component: () => (
        <TransitionPage
          title="Let's look at the whole picture..."
          slug={'transition-to-all-topics'}
        />
      ),
    },
    {
      slug: 'all-topics',
      headerColor: theme.color.reveal.scores.text,
      backgroundColor: theme.color.reveal.scores.background,
      Component: () => <AllTopicReveal />,
    },
    {
      slug: 'heat-map',
      headerColor: theme.color.reveal.scores.text,
      backgroundColor: theme.color.reveal.scores.background,
      Component: () => <RevealTeamHeatMap />,
    },
    {
      slug: 'reveal-end',
      headerColor: theme.color.reveal.accent,
      backgroundColor: theme.color.reveal.dark.background,
      Component: () => <RevealEnd />,
    },
  ];

  const currentStep = STUDY_REVEAL_STEPS.find((step) => step.slug === studyRevealState.pageSlug);
  return (
    <div id="survey-reveal">
      <RevealLanding />
      <RevealHeader
        color={currentStep?.headerColor ?? 'white'}
        background={currentStep?.backgroundColor ?? theme.color.reveal.purple.main.background}
      />
      {STUDY_REVEAL_STEPS.map(({ slug, Component }) => (
        <Component key={slug} />
      ))}
    </div>
  );
};
