/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdatedCustomerPromptRequest = {
    /**
     * * `RATING_5L` - RATING_5L
     * * `RATING_5N` - RATING_5N
     * * `SINGLE_SEL` - SINGLE_SEL
     * * `MULTI_SEL` - MULTI_SEL
     * * `OPEN_END` - OPEN_END
     */
    type?: UpdatedCustomerPromptRequest.type;
    text?: string;
    topicSlug?: string;
};

export namespace UpdatedCustomerPromptRequest {

    /**
     * * `RATING_5L` - RATING_5L
     * * `RATING_5N` - RATING_5N
     * * `SINGLE_SEL` - SINGLE_SEL
     * * `MULTI_SEL` - MULTI_SEL
     * * `OPEN_END` - OPEN_END
     */
    export enum type {
        RATING_5L = 'RATING_5L',
        RATING_5N = 'RATING_5N',
        SINGLE_SEL = 'SINGLE_SEL',
        MULTI_SEL = 'MULTI_SEL',
        OPEN_END = 'OPEN_END',
    }


}

