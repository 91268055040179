/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PatchedUpdateTopicVerbatimRequest } from '../models/PatchedUpdateTopicVerbatimRequest';
import type { TopicVerbatimWithTeamRef } from '../models/TopicVerbatimWithTeamRef';
import type { UpdateTopicVerbatim } from '../models/UpdateTopicVerbatim';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StudyVerbatimClient {

    /**
     * Get a study's topic verbatims.
     * @returns TopicVerbatimWithTeamRef
     * @throws ApiError
     */
    public static getTopicVerbatims({
        studyRef,
    }: {
        studyRef: string,
    }): CancelablePromise<Array<TopicVerbatimWithTeamRef>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/verbatims',
            path: {
                'studyRef': studyRef,
            },
        });
    }

    /**
     * Set values for a topic verbatim.
     * @returns UpdateTopicVerbatim
     * @throws ApiError
     */
    public static updateTopicVerbatim({
        studyRef,
        verbatimRef,
        requestBody,
    }: {
        studyRef: string,
        verbatimRef: string,
        requestBody?: PatchedUpdateTopicVerbatimRequest,
    }): CancelablePromise<UpdateTopicVerbatim> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/study/{studyRef}/verbatims/{verbatimRef}',
            path: {
                'studyRef': studyRef,
                'verbatimRef': verbatimRef,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get a study's topic verbatims for a specific team.
     * @returns TopicVerbatimWithTeamRef
     * @throws ApiError
     */
    public static getTopicVerbatimsForTeam({
        studyRef,
        teamRef,
    }: {
        studyRef: string,
        teamRef: string,
    }): CancelablePromise<Array<TopicVerbatimWithTeamRef>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/study/{studyRef}/verbatims/team/{teamRef}',
            path: {
                'studyRef': studyRef,
                'teamRef': teamRef,
            },
        });
    }

}
