import React from 'react';
import { faShieldHalved } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DevExExpandableAlert } from 'jf/components/DevExExpandableAlert';

import { ANONYMITY_THRESHOLD } from './StudyAnalyzerPage';

export const AnonymityThresholdAlert: React.FC = () => {
  return (
    <DevExExpandableAlert
      title={`Anonymity Threshold (${ANONYMITY_THRESHOLD})`}
      icon={<FontAwesomeIcon icon={faShieldHalved} />}
    >
      To preserve anonymity, we only show detailed results for segment groups of{' '}
      {ANONYMITY_THRESHOLD} participants or more.
    </DevExExpandableAlert>
  );
};
