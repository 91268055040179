import React from 'react';
import classNames from 'classnames';
import { css } from 'styled-components';

/**
 * To make new SVGs work,
 * - remove `height` and `width` props from <svg /> to allow resizing
 * - replace every color with "currentColor" to allow dynamic colorization
 * - add reduced opacity to unfilled tentacles
 */
import FlatTentaclesLeft from 'jf/images/flat-tentacles-left.svg';

const styles = {
  tentacles: css`
    position: fixed;
    top: 0;
    height: 100vh;
    width: 300px;

    svg {
      position: absolute;
      color: ${(props) => props.theme.color.accent.secondary};
      width: 100%;
    }

    &.tentacles--topLeft {
      left: min(0px, calc(100vw - 1350px));

      svg {
        top: -50px;
        left: -75px;
      }
    }

    &.tentacles--bottomRight {
      right: min(0px, calc(100vw - 1350px));

      svg {
        bottom: -100px;
        right: 0;
      }
    }

    &.tentacles--absolute {
      position: absolute;
      height: 100%;
    }
  `,
};

type FlatTentaclesProps = {
  style?: React.CSSProperties;
  placement: 'topLeft' | 'bottomRight';
  absolute?: boolean;
  className?: string;
};

export const FlatTentacles: React.FC<FlatTentaclesProps> = (props) => {
  return (
    <div
      css={styles.tentacles}
      style={props.style}
      className={classNames(props.className, `tentacles--${props.placement}`, {
        'tentacles--absolute': props.absolute,
      })}
    >
      <FlatTentaclesLeft />
    </div>
  );
};
