import React, { ReactNode } from 'react';
import { Flex } from 'antd';
import { css } from 'styled-components';

import { DevExCard } from 'jf/components/DevExCard';

type ParticipationCardProps = {
  title: ReactNode;
  subtitle: ReactNode;
};

const styles = {
  card: css`
    height: 130px;
    width: 315px;
    background: ${(props) => props.theme.color.reveal.purple.card.background};
    border: none;

    .card__header {
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
      font-size: ${(props) => props.theme.variable.fontSize.lg};
      font-weight: bold;
      padding-bottom: ${(props) => props.theme.variable.spacing.md};
      color: ${(props) => props.theme.color.reveal.purple.card.secondary};
    }

    .card__content {
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};
      font-size: ${(props) => props.theme.variable.fontSize.xxl};
      color: ${(props) => props.theme.color.reveal.purple.card.text};
    }
  `,
};

export const ParticipationCard: React.FC<ParticipationCardProps> = (props) => {
  return (
    <div style={{ display: 'flex' }}>
      <DevExCard css={styles.card}>
        <Flex vertical align="center">
          {!!props.title && <div className={'card__header'}>{props.title}</div>}
          {!!props.subtitle && <div className={'card__content'}> {props.subtitle} </div>}
        </Flex>
      </DevExCard>
    </div>
  );
};
