import React, { useEffect, useState } from 'react';
import { Flex } from 'antd';
import { faUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { StudyClient, StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { useJFOverlay } from 'jf/common/useJFOverlay';
import { DevExDrawer } from 'jf/components/DevExDrawer';
import { DevExExpandableAlert } from 'jf/components/DevExExpandableAlert';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';
import { DevExTabs } from 'jf/components/DevExTabs';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { ScoreBlock } from '../ScoreBlock';
import { SegmentSelect } from '../SegmentSelect';
import { TeamCascader } from '../TeamCascader';

import { PromptScoreCard } from './PromptScoreCard';
import { PromptVerbatimCard } from './PromptVerbatimCard';
import { TopicDrawerNavigator } from './TopicDrawerNavigator';
import { MetricTopicSlug, TopicMetrics } from './TopicMetrics';
import { useTopicResults } from './useTopicResults';

type TopicDrawerProps = {
  studyRef: string | undefined;
  topicSlug?: string;
  segmentKey?: string;
  teamRef?: string;
  segmentRef?: string;
};

export const TopicDrawer: React.FC<TopicDrawerProps> = (props) => {
  const theme = useDevExTheme();
  const drawer = useJFOverlay(TopicDrawer);

  const [tabKey, setTabKey] = useState<'prompts' | 'verbatims'>('prompts');

  // track when we should fetch data to
  //   - prevent fetching data while drawer is closed
  //   - prevent team-specific page fetching global data after ctx reset
  //   - prevent showing skeleton while drawer is closing
  const [fetch, setFetch] = useState(false);
  useEffect(() => {
    if (drawer.isOpened) {
      setFetch(true);
    }
  }, [drawer.isOpened]);

  const studyRef = fetch ? props.studyRef : undefined;

  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);
  const { bakunawaDevexMetricsCardsQ324: metricsCardsEnabled } = useFlags();

  const { score, promptScores, votes, verbatims } = useTopicResults({
    studyRef,
    topicSlug: props.topicSlug,
    teamRef: props.teamRef,
    segmentRef: props.segmentRef,
  });

  // ensure the tabKey is not stuck on "verbatims" when there are none
  useEffect(() => {
    if (tabKey === 'verbatims' && verbatims && !verbatims.length) {
      setTabKey('prompts');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verbatims]);

  const topic = topics?.find((topic) => topic.slug === props.topicSlug);
  const hasGbacLimitedAccess = window.dx.user?.gbac.companyAccess === false;
  const { data: study } = useClientQuery(StudyClient.getStudy, { studyRef });
  const canShowMetrics = study && study.options.showMetrics;

  const showMetricCards =
    metricsCardsEnabled &&
    !!studyRef &&
    props?.topicSlug &&
    !hasGbacLimitedAccess &&
    canShowMetrics;
  return (
    <DevExDrawer
      open={drawer.isOpened}
      onClose={() => drawer.close()}
      onCloseComplete={() => {
        setFetch(false);
      }}
      title={
        <Flex vertical gap={theme.variable.spacing.md}>
          <TopicDrawerNavigator
            studyRef={studyRef}
            teamRef={props.teamRef}
            segmentRef={props.segmentRef}
            topicSlug={props.topicSlug}
          />
          <Flex vertical gap={theme.variable.spacing.sm}>
            {topic ? topic.label : <DevExSkeleton fontSize="xl" noLineHeight width={120} />}
            <Flex gap={theme.variable.spacing.sm}>
              {!!props.teamRef && (
                <TeamCascader
                  studyRef={studyRef}
                  value={props.teamRef}
                  onChange={(teamRef) => drawer.update({ teamRef })}
                  allowClear={false}
                  // don't allow changing team when also showing a segment, this could break things
                  disabled={!!props.segmentKey && !!props.segmentRef}
                />
              )}
              {!!props.segmentKey && !!props.segmentRef && (
                <SegmentSelect
                  studyRef={studyRef}
                  teamRef={props.teamRef}
                  segmentKey={props.segmentKey}
                  value={props.segmentRef}
                  onChange={(segmentRef) => drawer.update({ segmentRef })}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
      }
      width={550}
    >
      <Flex key={props.topicSlug} vertical gap={theme.variable.spacing.md}>
        <Flex gap={theme.variable.spacing.lg}>
          <Flex vertical gap={theme.variable.spacing.sm} style={{ flex: 1 }}>
            <div style={{ color: theme.color.text.secondary }}>Description</div>
            {topic && score ? (
              <div style={{ lineHeight: theme.variable.lineHeight }}>{topic.longDescription}</div>
            ) : (
              <DevExSkeleton width={['100%', '100%', '75%']} fontSize="sm" />
            )}
          </Flex>

          <Flex vertical gap={theme.variable.spacing.md}>
            <Flex vertical gap={theme.variable.spacing.sm}>
              <div style={{ color: theme.color.text.secondary }}>Score</div>
              {score ? (
                <ScoreBlock score={score.scaledAvg} size={32} />
              ) : (
                <DevExSkeleton width={32} height={32} />
              )}
            </Flex>

            {votes && (
              <Flex vertical gap={theme.variable.spacing.sm}>
                <div style={{ color: theme.color.text.secondary }}>Votes</div>
                <Flex
                  gap={theme.variable.spacing.xs}
                  style={{ fontSize: theme.variable.fontSize.md }}
                >
                  {votes.length} <FontAwesomeIcon icon={faUp} />
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
        {showMetricCards && (
          <TopicMetrics
            studyRef={studyRef}
            teamRef={props.teamRef}
            topicSlug={props.topicSlug as MetricTopicSlug}
          />
        )}
        {topic && score && (
          <DevExExpandableAlert title="What does a negative sentiment mean?">
            Participants negatively perceive {topic.shortDescription}.
            <br />
            <br />
            Potential actions to improve this score:
            <ul style={{ marginBlock: `${theme.variable.spacing.sm} 0` }}>
              {topic.actions.map((action, i) => (
                <li key={i}>{action}</li>
              ))}
            </ul>
          </DevExExpandableAlert>
        )}

        <DevExTabs
          items={[
            {
              key: 'prompts',
              label: 'Questions',
              count: promptScores?.length,
              children: (
                <Flex vertical gap={theme.variable.spacing.md}>
                  {promptScores?.map((score) => (
                    <PromptScoreCard key={score.slug} score={score} />
                  )) ?? <DevExSkeleton height={268} />}
                </Flex>
              ),
            },
            {
              key: 'verbatims',
              label: 'Comments',
              count: verbatims?.length,
              children: (
                <Flex vertical gap={theme.variable.spacing.md}>
                  {verbatims?.map((verbatim) => (
                    <PromptVerbatimCard
                      key={verbatim.ref}
                      studyRef={studyRef}
                      verbatim={verbatim}
                    />
                  ))}
                </Flex>
              ),
              disabled: !verbatims || verbatims.length === 0,
            },
          ]}
          activeKey={tabKey}
          onChange={(key: 'prompts' | 'verbatims') => setTabKey(key)}
        />
      </Flex>
    </DevExDrawer>
  );
};
