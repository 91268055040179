import React, { useState } from 'react';
import { Flex } from 'antd';

import { Prompt, StudyClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExTextArea } from 'jf/components/DevExTextArea';
import { DevExToggleButton } from 'jf/components/DevExToggleButton';
import { queryClient } from 'jf/utils/queryClient';
import { useClientMutation } from 'jf/utils/useClientQuery';

import { LikertScale } from '../study/question/LikertScale';
import { QuestionCard } from '../study/question/QuestionCard';

import { useEditStudyState, useStudyEditorSteps } from './EditStudyStateContext';
import { PromptIcon } from './PromptIcon';
import { TopicSelect } from './TopicSelect';

export const QuestionCardEditablePrompt = () => {
  const theme = useDevExTheme();
  const editStudyState = useEditStudyState();

  const studySteps = useStudyEditorSteps();

  const numberedStepEndIndex =
    studySteps.length - [...studySteps].reverse().findIndex((step) => step.type === 'PROMPT') - 1;

  const [customerPromptState, setCustomerPromptState] = useState<'saving' | 'savingAsNew'>();

  const unsavedCustomerPrompt = editStudyState.unsavedCustomerPrompt;

  const { mutateAsync: createCustomerPrompt } = useClientMutation(StudyClient.createCustomerPrompt);
  const { mutateAsync: updateCustomerPrompt } = useClientMutation(StudyClient.updateCustomerPrompt);

  const onSaveCustomerPrompt = (asNew = false) => {
    if (unsavedCustomerPrompt) {
      setCustomerPromptState(asNew ? 'savingAsNew' : 'saving');

      if (!unsavedCustomerPrompt.slug || asNew) {
        // create new customer prompt
        createCustomerPrompt({ requestBody: unsavedCustomerPrompt })
          .then((createdCustomerPrompt) => {
            queryClient.invalidateQueries('GET_PROMPTS');
            editStudyState.update({
              stepIndex: numberedStepEndIndex + 1,
              promptSlugs: [...editStudyState.promptSlugs, createdCustomerPrompt.slug],
              unsavedCustomerPrompt: null,
            });
            setCustomerPromptState(undefined);
          })
          .catch(() => setCustomerPromptState(undefined));
      } else {
        // update existing customer prompt
        updateCustomerPrompt({
          slug: unsavedCustomerPrompt.slug,
          requestBody: {
            text: unsavedCustomerPrompt.text,
            type: unsavedCustomerPrompt.type,
            topicSlug: unsavedCustomerPrompt.topicSlug,
          },
        })
          .then(() => {
            queryClient.invalidateQueries('GET_PROMPTS');
            editStudyState.update({
              unsavedCustomerPrompt: null,
            });
            setCustomerPromptState(undefined);
          })
          .catch(() => setCustomerPromptState(undefined));
      }
    }
  };
  if (!unsavedCustomerPrompt) {
    return <QuestionCard />;
  }
  return (
    <QuestionCard
      key={unsavedCustomerPrompt.slug}
      title={
        <DevExTextArea
          value={unsavedCustomerPrompt.text}
          onChange={(value) => {
            editStudyState.update({
              unsavedCustomerPrompt: { ...unsavedCustomerPrompt, text: value },
            });
          }}
          style={{ fontSize: theme.variable.fontSize.lg }}
          placeholder="Ask your developers a custom question..."
          autoSize={{ minRows: 1, maxRows: 2 }}
        />
      }
      validated
      actions={
        <>
          <DevExButton onClick={() => editStudyState.update({ unsavedCustomerPrompt: null })}>
            Cancel
          </DevExButton>
          {!!unsavedCustomerPrompt.slug && (
            <DevExButton
              type="outline"
              disabled={
                !unsavedCustomerPrompt.text ||
                !unsavedCustomerPrompt.topicSlug ||
                !!customerPromptState
              }
              loading={customerPromptState === 'savingAsNew'}
              onClick={() => onSaveCustomerPrompt(true)}
            >
              Save as new
            </DevExButton>
          )}
          <DevExButton
            type="primary"
            disabled={
              !unsavedCustomerPrompt.text ||
              !unsavedCustomerPrompt.topicSlug ||
              !!customerPromptState
            }
            loading={customerPromptState === 'saving'}
            onClick={() => onSaveCustomerPrompt()}
          >
            Save
          </DevExButton>
        </>
      }
      tag={
        <TopicSelect
          value={unsavedCustomerPrompt.topicSlug}
          onChange={(value) =>
            editStudyState.update({
              unsavedCustomerPrompt: { ...unsavedCustomerPrompt, topicSlug: value },
            })
          }
        />
      }
    >
      <Flex vertical gap={theme.variable.spacing.sm}>
        <div style={{ color: theme.color.text.secondary }}>Question type</div>
        <Flex gap={theme.variable.spacing.md} style={{ height: 80 }}>
          {[Prompt.type.RATING_5L, Prompt.type.RATING_5N].map((type) => (
            <DevExToggleButton
              key={type}
              toggled={unsavedCustomerPrompt.type === type}
              onClick={() => {
                editStudyState.update({
                  unsavedCustomerPrompt: { ...unsavedCustomerPrompt, type },
                });
              }}
            >
              <PromptIcon type={type} />
              {type === Prompt.type.RATING_5L && <span>Sentiment</span>}
              {type === Prompt.type.RATING_5N && <span>Rating</span>}
            </DevExToggleButton>
          ))}
        </Flex>
        <div
          style={{
            paddingTop: theme.variable.spacing.md,
          }}
        >
          <LikertScale
            polarity={0}
            disabled
            numeric={unsavedCustomerPrompt.type === Prompt.type.RATING_5N}
          />
        </div>
      </Flex>
    </QuestionCard>
  );
};
