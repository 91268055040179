/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Prompt = {
    slug: string;
    /**
     * The Prompt text itself
     */
    text: string;
    /**
     * * `RATING_5L` - RATING_5L
     * * `RATING_5N` - RATING_5N
     * * `SINGLE_SEL` - SINGLE_SEL
     * * `MULTI_SEL` - MULTI_SEL
     * * `OPEN_END` - OPEN_END
     */
    type: Prompt.type;
    /**
     * * `VISIBLE` - VISIBLE
     * * `LIMITED` - LIMITED
     * * `ADMIN` - ADMIN
     * * `HIDDEN` - HIDDEN
     */
    visibility: Prompt.visibility;
    choices: Array<string>;
    readonly topicSlug: string;
    polarity: number;
    flags?: Array<string>;
    /**
     * * `CANONICAL` - CANONICAL
     * * `CUSTOMER` - CUSTOMER
     */
    scope: Prompt.scope;
    frozen?: boolean;
};

export namespace Prompt {

    /**
     * * `RATING_5L` - RATING_5L
     * * `RATING_5N` - RATING_5N
     * * `SINGLE_SEL` - SINGLE_SEL
     * * `MULTI_SEL` - MULTI_SEL
     * * `OPEN_END` - OPEN_END
     */
    export enum type {
        RATING_5L = 'RATING_5L',
        RATING_5N = 'RATING_5N',
        SINGLE_SEL = 'SINGLE_SEL',
        MULTI_SEL = 'MULTI_SEL',
        OPEN_END = 'OPEN_END',
    }

    /**
     * * `VISIBLE` - VISIBLE
     * * `LIMITED` - LIMITED
     * * `ADMIN` - ADMIN
     * * `HIDDEN` - HIDDEN
     */
    export enum visibility {
        VISIBLE = 'VISIBLE',
        LIMITED = 'LIMITED',
        ADMIN = 'ADMIN',
        HIDDEN = 'HIDDEN',
    }

    /**
     * * `CANONICAL` - CANONICAL
     * * `CUSTOMER` - CUSTOMER
     */
    export enum scope {
        CANONICAL = 'CANONICAL',
        CUSTOMER = 'CUSTOMER',
    }


}

