import React from 'react';
import { Flex } from 'antd';

import { useDevExTheme } from 'jf/common/DevExThemeContext';

import { DevExButton } from './DevExButton';

type DevExRadioButtonsProps = {
  items: { key: string; label: string }[];
  onChange: (key?: string) => void;
  value: string | undefined;
};

export const DevExRadioButtons: React.FC<DevExRadioButtonsProps> = (props) => {
  const theme = useDevExTheme();
  return (
    <Flex gap={theme.variable.spacing.md} wrap="wrap">
      {props.items.map((item) => (
        <DevExButton
          key={item.key}
          size="default"
          type="outline"
          onClick={() => props.onChange(item.key)}
          active={item.key === props.value}
        >
          {item.label}
        </DevExButton>
      ))}
    </Flex>
  );
};
