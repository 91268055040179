import React from 'react';
import { Flex } from 'antd';
import classNames from 'classnames';
import { css } from 'styled-components';

import { useDevExTheme } from 'jf/common/DevExThemeContext';

import { DevExCheckbox } from './DevExCheckbox';

const styles = {
  steps: css`
    .steps__step {
      display: flex;
      align-items: center;
      gap: ${(props) => props.theme.variable.spacing.sm};
      font-family: ${(props) => props.theme.variable.fontFamily.secondary};

      &:not(.step--active) {
        opacity: 0.6;
      }

      .step__number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        border-radius: 4px;
        background-color: ${(props) => props.theme.color.tag.default.background};
        font-weight: 600;
      }
    }
  `,
};

type DevExStepsProps = {
  labels: string[];
  activeIndex: number;
};

export const DevExSteps: React.FC<DevExStepsProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <Flex align="center" gap={theme.variable.spacing.lg} css={styles.steps}>
      {props.labels.map((label, i) => (
        <div
          key={i}
          className={classNames('steps__step', { 'step--active': props.activeIndex === i })}
        >
          <div className="step__number">
            {props.activeIndex > i ? (
              <DevExCheckbox checked style={{ pointerEvents: 'none' }} />
            ) : (
              i + 1
            )}
          </div>
          {label}
        </div>
      ))}
    </Flex>
  );
};
