import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import snakeCase from 'lodash/snakeCase';

const casings = {
  camelCase,
  kebabCase,
  snakeCase,
};

/**
 * TODO: Remove casing option when we fix the one instance using snakeCase in entry/config.js
 * For pulling component props/attributes from elements in the DOM tree
 * @param {string, node} element - The element or target selector for an element to pull attributes from
 * @param {string} casing - The desired output casing for the keys in the output data object
 * @returns {object} - Returns an object of keys (cased to spec) and their values pulled from the element
 */
export const getDataAttributes = (element: string | HTMLElement, casing = 'snakeCase'): any => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'dataset' does not exist on type 'HTMLEle... Remove this comment to see the full error message
  const { dataset } =
    typeof element === 'string' ? document.querySelector<HTMLElement>(element) : element;
  const data = Object.assign({}, dataset); // Converts DOMStringMap to Object
  return fixCasing(data, casing);
};

// Helper function to convert data-*-json attributes to JS
export const parseDataAttr = <T = any>(json: string, ignoreCasingKeys: string[] = []): T => {
  try {
    return fixCasing(JSON.parse(json as string), 'camelCase', ignoreCasingKeys);
  } catch (e) {
    console.error('Invalid JSON provided');
    console.log(json);
    throw e;
  }
};

export const fixCasing = (
  data: any,
  casing: string = 'snakeCase',
  ignoreCasingKeys: string[] = []
): any => {
  if (Array.isArray(data)) {
    return data.map((n) => {
      return ignoreCasingKeys.includes(n) ? n : fixCasing(n, casing, ignoreCasingKeys);
    });
  }
  if (data && typeof data === 'object' && data.constructor === Object) {
    return Object.keys(data).reduce((accumulator, key) => {
      accumulator[casings[casing](key)] = ignoreCasingKeys.includes(key)
        ? data[key]
        : fixCasing(data[key], casing, ignoreCasingKeys);
      return accumulator;
    }, {});
  }

  return data;
};

/**
 * This method is intended to clean strings for better searchability
 * @param {string} inputString - The string to lowercase
 * @returns {string} - Cleansed string ready for search functionality
 */
export const searchCleaning = (inputString: string) => inputString.toLowerCase();

/**
 * This method is intended to present a pretty stringified version of the selected options
 * @param {array} options - The array of options
 * @param {array} selected - The array of selected values
 * @returns {string} - The prettified string of the user's selections
 */
export const getStringifiedSelections = (options, selected) => {
  const selectedOptions = options.filter((option) => selected.includes(option.value));

  // Returns comma-separated label selected values for UI render
  return selectedOptions.reduce((accumulator, value, index) => {
    if (index < 1) {
      return value.label;
    } else {
      return `${accumulator}, ${value.label}`;
    }
  }, '');
};

/**
 * This method rounds a number to the specified precision
 * @param {number} number - The number to round
 * @returns {number} - Rounded number
 */
export const roundNumber = (number, precision = 1) => {
  const multi = Math.pow(10, precision);
  return parseFloat(
    (Math.round(+(number * multi).toFixed(precision + 1)) / multi).toFixed(precision)
  );
};

/**
 * This method converts a fraction to a percentage (assuming it's a decimal representation of a percentage)
 * @param {string, number} number - The number to convert
 * @returns {number} - Percentage number
 */
export const toPercentage = (number: string | number, precision = 0) => {
  const definitelyNumber = typeof number === 'string' ? parseFloat(number) : number;
  return (definitelyNumber * 100).toFixed(precision);
};

/**
 * Takes a string, converts it do a boolean, and returns it
 * @param {string} bool_str - the string to convert to a boolean
 */
export const toBoolean = (pythonBool: string | null) => {
  if (pythonBool?.toLowerCase() === 'true') {
    return true;
  }
  return false;
};
