/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Company } from './Company';
import type { DevExUserGBAC } from './DevExUserGBAC';

export type DevExUser = {
    readonly id: number;
    ref: string;
    email?: string | null;
    name?: string;
    readonly isCustomerAdmin: boolean;
    /**
     * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
     */
    isActive?: boolean;
    readonly isSetup: boolean;
    /**
     * Return a boolean value corresponding to if the user has been onboarded.
     */
    readonly isOnboarded: boolean;
    company: Company;
    /**
     * The job level of this user.
     *
     * * `0` - Founder
     * * `1` - Executive
     * * `2` - Vice President
     * * `3` - Senior Director
     * * `4` - Director
     * * `5` - Senior Manager
     * * `6` - Manager
     * * `7` - Individual Contributor
     * * `8` - Other
     */
    jobLevel?: DevExUser.jobLevel | null;
    readonly isEmp: boolean;
    readonly gbac: DevExUserGBAC;
};

export namespace DevExUser {

    /**
     * The job level of this user.
     *
     * * `0` - Founder
     * * `1` - Executive
     * * `2` - Vice President
     * * `3` - Senior Director
     * * `4` - Director
     * * `5` - Senior Manager
     * * `6` - Manager
     * * `7` - Individual Contributor
     * * `8` - Other
     */
    export enum jobLevel {
        '_0' = 0,
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
        '_5' = 5,
        '_6' = 6,
        '_7' = 7,
        '_8' = 8,
    }


}

