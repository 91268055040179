/**
 * This module serves as a link between shared and App entry points for
 * react-query. The goal is to create a single config point that is shared.
 *
 * makeQueryClient is a factory that creates a singleton client to be injected
 * into QueryClientProvider at each entry point.
 */
import { notify } from 'bugsnag';
import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error: any) => {
        // don't retry timeouts
        if (error?.status === 504) {
          return false;
        }
        // Otherwise, retry 1 more time, then notify Bugsnag
        if (failureCount >= 1) {
          return false;
        }
        return true;
      },
      onError: (e: Error) => {
        notify(e);
      },
    },
  },
});
