import React, { ReactNode } from 'react';
import { Flex, Tooltip } from 'antd';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { faMessage } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Duration } from 'luxon';

import { trackEvent } from 'jf/analytics/Analytics';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { CircledFontAwesomeIcon } from 'jf/components/CircledFontAwesomeIcon';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExCard } from 'jf/components/DevExCard';
import { DevExInput } from 'jf/components/DevExInput';
import { DevExSkeleton } from 'jf/components/DevExSkeleton';

import { useStudyParticipation } from '../useStudyParticipation';

import { ParticipationChart } from './ParticipationChart';

const formatAvgTimeSec = (avgTimeSec: number) => {
  if (avgTimeSec === 0) {
    return '—';
  }
  return Duration.fromMillis(avgTimeSec * 1000).toFormat(avgTimeSec >= 3600 ? 'hh:mm:ss' : 'mm:ss');
};

type ParticipationChartProps = {
  studyRef: string | undefined;
};

type ParticipationStatProps = {
  label: ReactNode;
  stat: number | string | undefined;
};

const ParticipationStat: React.FC<ParticipationStatProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <Flex vertical gap={theme.variable.spacing.sm}>
      <div style={{ color: theme.color.text.secondary }}>{props.label}</div>
      <div
        style={{
          fontSize: theme.variable.fontSize.xl,
          fontFamily: theme.variable.fontFamily.secondary,
        }}
      >
        {props.stat ?? <DevExSkeleton fontSize="xl" noLineHeight width={40} />}
      </div>
    </Flex>
  );
};

export const ParticipationCard: React.FC<ParticipationChartProps> = (props) => {
  const theme = useDevExTheme();

  const { data: participation } = useStudyParticipation({ studyRef: props.studyRef });

  if (participation?.responseTimes.length === 0) {
    const shareLink = `${window.location.origin}/study/${props.studyRef}`;
    return (
      <DevExCard
        bodyStyle={{
          alignItems: 'center',
          padding: theme.variable.spacing.lg,
          gap: theme.variable.spacing.xl,
        }}
      >
        <Flex vertical align="center" gap={theme.variable.spacing.md}>
          <CircledFontAwesomeIcon icon={faMessage} />
          <div style={{ fontSize: theme.variable.fontSize.xl }}>No responses... yet!</div>
        </Flex>

        <Flex vertical align="center" gap={theme.variable.spacing.md}>
          <div>Copy the survey link below and share it with your target participants.</div>
          <Flex gap={theme.variable.spacing.sm}>
            <DevExInput value={shareLink} style={{ width: 500 }} />
            <DevExButton
              type="primary"
              copyValue={shareLink}
              onClick={() =>
                trackEvent('survey-analyzer:share-survey:copy', { surveyRef: props.studyRef })
              }
            >
              Copy
            </DevExButton>
          </Flex>
        </Flex>
      </DevExCard>
    );
  }

  return (
    <DevExCard title="Participation">
      <Flex gap={theme.variable.spacing.lg}>
        <ParticipationStat label="Responses" stat={participation?.uniqueResponses} />
        <ParticipationStat
          label={
            <Tooltip title="Average response time includes numeric rating questions only.">
              <Flex gap={theme.variable.spacing.xs}>
                Average Response Time
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Flex>
            </Tooltip>
          }
          stat={participation && formatAvgTimeSec(participation.avgTimeSec)}
        />
      </Flex>
      <ParticipationChart studyRef={props.studyRef} />
    </DevExCard>
  );
};
