import React, { ReactNode } from 'react';
import { Flex, Tooltip } from 'antd';
import { faSquare as faSquareLight } from '@fortawesome/pro-light-svg-icons';
import { faArrowToLeft, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { faSquare as faSquareSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { keyBy, mapValues } from 'lodash-es';
import { opacify } from 'polished';
import { css } from 'styled-components';

import { Prompt, StudyLibraryClient } from 'jf/api';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { arrayOf } from 'jf/utils/arrayOf';
import { useLocalStorage } from 'jf/utils/useBrowserStorage';
import { useClientQuery } from 'jf/utils/useClientQuery';

import { HygieneCard } from './HygieneCard';

const MINUTES_PER_QUESTION = 0.5;
const TOPIC_HYGIENE_PANEL_WIDTH = 100;
export const TOPIC_HYGIENE_PANEL_EXPANDED_WIDTH = 320;

const styles = {
  topicHygiene: css`
    width: ${TOPIC_HYGIENE_PANEL_WIDTH}px;
    display: flex;
    background: ${(props) => props.theme.color.background.primary};
    box-shadow: 0 0 0 1px ${(props) => props.theme.color.card.border};
    transition: width 150ms ease;

    .topicHygiene__header {
      font-size: ${(props) => props.theme.variable.fontSize.lg};
      align-items: center;
      gap: ${(props) => props.theme.variable.spacing.sm};
      padding-top: ${(props) => props.theme.variable.spacing.lg};
      padding-bottom: ${(props) => props.theme.variable.spacing.md};
    }

    .topicHygiene__topics {
      display: flex;
      padding-inline-end: ${(props) => props.theme.variable.spacing.xs};
      width: 150px;
      justify-content: flex-end;
    }

    &.topicHygiene--expanded {
      width: ${TOPIC_HYGIENE_PANEL_EXPANDED_WIDTH}px;

      .topicHygiene__expand {
        svg {
          transform: rotateY(180deg);
        }
      }
    }

    .topicHygiene__expand {
      border-top: 1px solid ${(props) => props.theme.color.card.border};
      display: flex;
      padding-block: ${(props) => props.theme.variable.spacing.sm};

      &:hover,
      &:focus {
        background-color: ${(props) => props.theme.color.button.primary.aura};
        cursor: pointer;
      }

      svg {
        font-size: 16px;
        transition: transform 150ms ease;
      }
    }
  `,
};

type TopicHygieneProps = {
  icon: ReactNode;
  title: ReactNode;
  promptSlugs: string[];
  className?: string;
  collapsible?: boolean;
};

// TODO: rename to TopicHygienePanel
export const TopicHygiene: React.FC<TopicHygieneProps> = (props) => {
  const theme = useDevExTheme();

  const [expanded, setExpanded] = useLocalStorage('devex_topicHygiene_expanded', true);

  const { data: prompts } = useClientQuery(StudyLibraryClient.getPrompts);
  const { data: topics } = useClientQuery(StudyLibraryClient.getTopics);

  const topicsBySlug = keyBy(topics, 'slug');
  const promptsBySlug = keyBy(prompts, 'slug');

  // collect given promptSlugs into topics
  // (include all topics so we know which ones are empty as well)
  const promptsByTopicSlug: { [topicSlug: string]: Prompt[] } = mapValues(topicsBySlug, () => []);

  if (topics) {
    for (const promptSlug of props.promptSlugs) {
      const prompt = promptsBySlug[promptSlug];
      if (prompt) {
        promptsByTopicSlug[prompt.topicSlug].push(prompt);
      }
    }
  }

  const sortedPromptsByTopicSlug = Object.entries(promptsByTopicSlug).sort(
    ([_A, promptsA], [_B, promptsB]) => promptsB.length - promptsA.length
  );

  const topicSquares = (topicSlug: string) => {
    const prompts = promptsByTopicSlug[topicSlug];

    if (!prompts.length) {
      return arrayOf(1).map((i) => <FontAwesomeIcon key={i} icon={faSquareLight} />);
    }

    return prompts.map((prompt, i) => (
      <Tooltip key={i} title={prompt.text}>
        <div className="fa-layers">
          <FontAwesomeIcon
            icon={faSquareSolid}
            style={{
              color:
                prompt.scope === Prompt.scope.CUSTOMER
                  ? opacify(-0.7, theme.color.accent.secondary)
                  : theme.color.accent.secondary,
            }}
          />
          <FontAwesomeIcon
            icon={faSquareLight}
            style={{
              color: theme.color.accent.secondary,
            }}
          />
        </div>
      </Tooltip>
    ));
  };

  const promptCount = props.promptSlugs.length;
  const topicCount = Object.values(promptsByTopicSlug).filter((prompts) => prompts.length).length;

  return (
    <Flex
      vertical
      css={styles.topicHygiene}
      className={classNames(props.className, {
        'topicHygiene--expanded': expanded,
      })}
    >
      <Flex
        vertical
        style={{
          height: '100%',
          pointerEvents: expanded ? 'all' : 'none',
          paddingInline: expanded ? theme.variable.spacing.lg : theme.variable.spacing.md,
          transition: 'padding-inline 150ms ease',
        }}
        gap={theme.variable.spacing.lg}
      >
        <Flex className={'topicHygiene__header'}>
          {props.icon}
          <div style={{ transition: 'opacity 150ms ease', opacity: expanded ? 1 : 0 }}>
            {props.title}
          </div>
        </Flex>

        <HygieneCard
          title={expanded ? 'Estimated Time to Complete ' : 'Time'}
          subtitleNumber={Math.ceil((promptCount + 2) * MINUTES_PER_QUESTION)}
          subtitleLabel={` min`}
          icon={
            expanded ? (
              <Tooltip
                title={
                  'The estimated time to complete includes all survey questions except demographics and voting questions.'
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip>
            ) : undefined
          }
        />
        <HygieneCard title={'Questions'} subtitleNumber={promptCount} />
        <HygieneCard
          title={'Topics'}
          subtitleNumber={topicCount}
          children={
            topicCount !== 0 && (
              <Flex
                gap={theme.variable.spacing.xs}
                style={{ transition: 'opacity 150ms ease', opacity: expanded ? 1 : 0 }}
              >
                {arrayOf(topicCount).map((i) => (
                  <Tooltip key={i} title={topicsBySlug[sortedPromptsByTopicSlug[i][0]].label}>
                    <FontAwesomeIcon
                      icon={faSquareSolid}
                      style={{
                        color: theme.color.accent.secondary,
                      }}
                    />
                  </Tooltip>
                ))}
              </Flex>
            )
          }
        />

        <HygieneCard
          style={{ transition: 'opacity 150ms ease', opacity: expanded ? 1 : 0 }}
          title={'Questions by Topic'}
          children={
            <Flex vertical gap={theme.variable.spacing.sm}>
              {sortedPromptsByTopicSlug.map(([topicSlug, prompts]) => (
                <Flex
                  vertical
                  key={topicSlug}
                  gap={theme.variable.spacing.lg}
                  style={{
                    color:
                      prompts.length === 0 ? theme.color.text.tertiary : theme.color.text.primary,
                    width: '300px',
                  }}
                >
                  <Flex
                    style={{
                      fontSize: theme.variable.fontSize.xs,
                    }}
                    gap={theme.variable.spacing.sm}
                  >
                    <Flex justify="flex-end">
                      <Tooltip title={topicsBySlug[topicSlug].longDescription}>
                        <div className="topicHygiene__topics">{topicsBySlug[topicSlug].label}</div>
                      </Tooltip>
                    </Flex>
                    <Flex wrap="wrap" gap={2}>
                      {topicSquares(topicSlug)}
                    </Flex>
                    {prompts.length}
                  </Flex>
                </Flex>
              ))}
            </Flex>
          }
        />
      </Flex>

      {props.collapsible && (
        <Flex
          align="center"
          justify="flex-start"
          className={'topicHygiene__expand'}
          onClick={() => setExpanded(!expanded)}
          style={{
            paddingInline: expanded ? theme.variable.spacing.lg : theme.variable.spacing.md,
            transition: 'padding-inline 150ms ease',
          }}
        >
          <FontAwesomeIcon icon={faArrowToLeft} />
        </Flex>
      )}
    </Flex>
  );
};
