import React from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { CircledFontAwesomeIcon } from 'jf/components/CircledFontAwesomeIcon';
import { DevExCard } from 'jf/components/DevExCard';

type CreateStudyCardProps = {
  icon: IconDefinition;
  title: string;
  subtitle?: string;
  description: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const CreateStudyCard: React.FC<CreateStudyCardProps> = (props) => {
  const theme = useDevExTheme();

  return (
    <DevExCard
      bodyStyle={{
        alignItems: 'center',
        gap: theme.variable.spacing.lg,
      }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <CircledFontAwesomeIcon icon={props.icon} />
      <div style={{ fontSize: theme.variable.fontSize.md }}>{props.title}</div>
      {!!props.subtitle && (
        <div style={{ color: theme.color.text.secondary }}>{props.subtitle}</div>
      )}
      <div
        style={{
          color: theme.color.text.secondary,
          fontSize: theme.variable.fontSize.xs,
          lineHeight: theme.variable.lineHeight,
        }}
      >
        {props.description}
      </div>
    </DevExCard>
  );
};
