import React, { useEffect } from 'react';
import { Flex } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { trackEvent } from 'jf/analytics/Analytics';
import { RosterClient, StudyClient, StudySequenceClient } from 'jf/api';
import { startFullstory } from 'jf/common/DevEx';
import { DevExHeader } from 'jf/common/DevExHeader';
import { DevExPageLayout } from 'jf/common/DevExPageLayout';
import { DevExPageTitle } from 'jf/common/DevExPageTitle';
import { useDevExTheme } from 'jf/common/DevExThemeContext';
import { DevExButton } from 'jf/components/DevExButton';
import { DevExLoader } from 'jf/components/DevExLoader';
import { DevExSteps } from 'jf/components/DevExSteps';
import { useClientMutation, useClientQuery } from 'jf/utils/useClientQuery';

import { CATCH_ALL_TEAM_REF } from '../study/question/QuestionCardDemographic';

import { ChooseStudyStep } from './ChooseStudyStep';
import { ConfigureTeamsStep } from './ConfigureTeamsStep';
import { useCreateStudyState } from './CreateStudyController';

type CreateStudyStep = {
  key: string;
  label: string;
  Component: React.FC;
};

const CREATE_STUDY_STEPS: CreateStudyStep[] = [
  {
    key: 'choose-survey',
    label: 'Choose base survey',
    Component: ChooseStudyStep,
  },
  {
    key: 'configure-teams',
    label: 'Set up your teams',
    Component: ConfigureTeamsStep,
  },
  {
    key: 'edit-survey',
    label: 'Customize your survey',
    Component: () => {
      const navigate = useNavigate();
      const theme = useDevExTheme();
      const queryClient = useQueryClient();
      const createStudyState = useCreateStudyState();
      const { bakunawaDevexSeriesMvpQ324: seriesEnabled } = useFlags();

      const { mutateAsync: createStudy } = useClientMutation(StudyClient.createStudy);
      const { mutateAsync: createStudySequence } = useClientMutation(
        StudySequenceClient.createStudySequence
      );
      const { mutateAsync: createTeam } = useClientMutation(RosterClient.createTeam);

      useEffect(() => {
        if (createStudyState.studyToCreate) {
          const queries: Promise<void>[] = [];

          // create teams
          for (const unsavedTeam of createStudyState.teamsToCreate ?? []) {
            queries.push(createTeam({ requestBody: { name: unsavedTeam.value } }));
          }

          if (seriesEnabled && createStudyState.studySequenceToCreate) {
            queries.push(
              createStudySequence({ requestBody: createStudyState.studySequenceToCreate }).then(
                (sequence) => {
                  createStudyState.studyToCreate!.sequenceRef = sequence.ref;
                }
              )
            );
          }

          Promise.allSettled(queries).then(() => {
            queryClient.removeQueries(['GET_TEAMS']);

            // create study after teams have been created
            createStudy({
              requestBody: createStudyState.studyToCreate!,
            })
              .then(({ ref }) => {
                queryClient.invalidateQueries('GET_STUDIES');
                queryClient.invalidateQueries('GET_STUDY_SEQUENCES');
                navigate(`/study/${ref}/edit`);
              })
              .catch(
                () => createStudyState.update({ studyToCreate: undefined, stepIndex: 0 }) // TODO: display error message
              );
          });
        }
      }, []);

      // just a spinner until all API requests resolve and we can forward to survey editor
      return (
        <Flex
          vertical
          align="center"
          gap={theme.variable.spacing.lg}
          style={{ paddingBlock: theme.variable.spacing.xxl }}
        >
          <DevExLoader />
          <div
            style={{
              fontSize: theme.variable.fontSize.md,
              color: theme.color.text.secondary,
            }}
          >
            Creating survey...
          </div>
        </Flex>
      );
    },
  },
];

export const CreateStudyPage: React.FC = () => {
  useEffect(() => startFullstory('survey-creator'), []);
  const navigate = useNavigate();
  const createStudyState = useCreateStudyState();
  const theme = useDevExTheme();

  const { data: teams } = useClientQuery(RosterClient.getTeams);

  let createStudySteps = [...CREATE_STUDY_STEPS];

  // hide team configuration if isEmp company or leaf teams already exist
  if (
    window.dx.user?.company.isEmp ||
    teams?.some((team) => team.isLeaf && team.ref !== CATCH_ALL_TEAM_REF)
  ) {
    createStudySteps = createStudySteps.filter((step) => step.key !== 'configure-teams');
  }

  const onExit = () => {
    trackEvent('survey-creator:create:exit');
    navigate('/studies');
  };

  if (!teams) {
    return <DevExLoader />;
  }

  return (
    <Flex vertical style={{ flex: 1 }}>
      <DevExPageTitle>Create DevEx Survey</DevExPageTitle>
      <DevExHeader
        left={
          <DevExButton
            size="large"
            type="outline"
            onClick={onExit}
            disabled={createStudyState.stepIndex === CREATE_STUDY_STEPS.length - 1}
          >
            Cancel
          </DevExButton>
        }
      >
        <Flex vertical align="center" gap={theme.variable.spacing.lg}>
          Create DevEx Survey
          <div style={{ fontSize: 'initial', fontWeight: 'initial' }}>
            <DevExSteps
              labels={createStudySteps.map((step) => step.label)}
              activeIndex={createStudyState.stepIndex}
            />
          </div>
        </Flex>
      </DevExHeader>

      <DevExPageLayout maxWidth={650}>
        {createStudySteps.map(
          ({ key, Component }, i) => i === createStudyState.stepIndex && <Component key={key} />
        )}
      </DevExPageLayout>
    </Flex>
  );
};
