import React from 'react';
import { Flex } from 'antd';
import { css } from 'styled-components';

const styles = {
  csvTable: css`
    .csvTable__row {
      &:first-child {
        color: ${(props) => props.theme.color.text.secondary};

        .row__cell {
          display: flex;
          justify-content: center;
          padding: 0;
        }
      }

      &:nth-child(2) {
        .row__cell {
          &:not(:first-child) {
            font-weight: 600;
          }
        }
      }

      &:not(:first-child) {
        .row__cell {
          border-top: none;
        }
      }

      .row__cell {
        flex: 1;
        border: 1px solid ${(props) => props.theme.color.card.border};
        padding-inline: ${(props) => props.theme.variable.spacing.sm};
        padding-block: ${(props) => props.theme.variable.spacing.xs};

        &:first-child {
          flex: none;
          width: ${(props) => props.theme.variable.spacing.lg};
          color: ${(props) => props.theme.color.text.secondary};
        }

        &:not(:first-child) {
          border-left: none;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  `,
};

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

interface CSVTableProps {
  data: string[][];
}

export const CSVTable: React.FC<CSVTableProps> = (props) => {
  return (
    <Flex vertical css={styles.csvTable}>
      <Flex className="csvTable__row">
        <div className="row__cell" />
        {(props.data[0] ?? []).map((_, x) => (
          <div key={x} className="row__cell">
            {ALPHABET[x]}
          </div>
        ))}
      </Flex>
      {props.data?.map((row, y) => (
        <Flex key={y} className="csvTable__row">
          <div className="row__cell">{y + 1}</div>
          {row.map((cell, x) => (
            <div key={x} className="row__cell">
              {cell}
            </div>
          ))}
        </Flex>
      ))}
    </Flex>
  );
};
