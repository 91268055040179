import React from 'react';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DevExSelect } from 'jf/components/DevExSelect';

import { useStudySegments } from './useStudySegments';

type SegmentSelectProps = {
  studyRef: string | undefined;
  teamRef?: string;
  segmentKey: string;
  value: string;
  onChange?: (value?: string) => void;
};

export const SegmentSelect: React.FC<SegmentSelectProps> = (props) => {
  const studySegments = useStudySegments({
    studyRef: props.studyRef,
    teamRef: props.teamRef,
  });

  const segment = studySegments?.find((segment) => segment.key === props.segmentKey);

  if (!segment) {
    return null;
  }

  return (
    <DevExSelect
      placeholder={
        <>
          <FontAwesomeIcon icon={faFilter} />
          {segment.name}
        </>
      }
      keepPlaceholder
      options={segment.options}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
